import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { CollectionPageChildProps } from "../CollectionPage";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { patchCollection } from "@/services/collection";

export default function HotelPrice({
  collection,
  onRefresh,
}: CollectionPageChildProps) {
  const { setBackBtn, setProgress, setTitleCollectionPage } =
    useOutletContext<any>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setBackBtn(true);
    setProgress(60);
  }, []);

  const formSchema = z.object({
    bookingMarkup: z
      .string()
      .min(1, t("form.required"))
      .refine((val) => {
        const num = Number(val);
        return !isNaN(num) && num >= 0 && num <= 100 && Number.isInteger(num);
      }, t("form.integer_percentage_range")),
    expediaMarkup: z
      .string()
      .min(1, t("form.required"))
      .refine((val) => {
        const num = Number(val);
        return !isNaN(num) && num >= 0 && num <= 100 && Number.isInteger(num);
      }, t("form.integer_percentage_range")),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      bookingMarkup:
        collection?.preData?.deltaPriceBookingCom !== undefined
          ? String(Math.round(collection.preData.deltaPriceBookingCom * 100))
          : "",
      expediaMarkup:
        collection?.preData?.deltaPriceHotelsCom !== undefined
          ? String(Math.round(collection.preData.deltaPriceHotelsCom * 100))
          : "",
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setIsLoading(true);
    try {
      await patchCollection({
        ...collection,
        collectionId: collection?._id,
        preData: {
          ...collection?.preData,
          deltaPriceBookingCom: Number(data.bookingMarkup) / 100,
          deltaPriceHotelsCom: Number(data.expediaMarkup) / 100,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      await onRefresh();
      navigate(`/collection/${collection?._id}/infos/hotelbenefits`);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="mt-5 mb-10">
        <p className="text-base font-medium">{t("onboarding.step")} 3</p>
        <p className="text-2xl font-medium">{t("onboarding.price_title")}</p>
        <p className="text-base font-medium text-gray-600">
          {t("onboarding.price_subtitle")}
        </p>
      </div>
      <div className="flex-1 overflow-y-auto">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FormField
                control={form.control}
                name="bookingMarkup"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      {t("onboarding.booking_markup")} {t("form.required")}
                    </FormLabel>
                    <FormControl>
                      <Input
                        min={0}
                        max={100}
                        type="number"
                        step="1"
                        placeholder={t("onboarding.booking_markup_placeholder")}
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value;
                          const numValue = Number(value);

                          // Only allow integer values
                          if (value.includes(".")) {
                            field.onChange(Math.floor(numValue).toString());
                          } else if (numValue < 0 || numValue > 100) {
                            field.onChange("");
                          } else {
                            field.onChange(value);
                          }
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="expediaMarkup"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      {t("onboarding.expedia_markup")} {t("form.required")}
                    </FormLabel>
                    <FormControl>
                      <Input
                        min={0}
                        max={100}
                        type="number"
                        step="1"
                        placeholder={t("onboarding.booking_markup_placeholder")}
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value;
                          const numValue = Number(value);

                          // Only allow integer values
                          if (value.includes(".")) {
                            field.onChange(Math.floor(numValue).toString());
                          } else if (numValue < 0 || numValue > 100) {
                            field.onChange("");
                          } else {
                            field.onChange(value);
                          }
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
      </div>
      <div className="flex flex-row justify-between mt-6">
        <Button
          className=""
          type="button"
          onClick={() =>
            navigate(`/collection/${collection?._id}/infos/hoteldata`)
          }
        >
          {t("auth.back")}
        </Button>
        {!isLoading ? (
          <Button
            variant="takyon"
            type="submit"
            onClick={(e: any) => form.handleSubmit(onSubmit)(e)}
            disabled={!form.formState.isValid}
          >
            {t("buttons.save_and_continue")}
          </Button>
        ) : (
          <Button disabled className="min-w-40">
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            {t("buttons.loading")}
          </Button>
        )}
      </div>
    </div>
  );
}
