import {
  getLastUpdateBilling,
  iLastUpdateInvoice,
} from "../../../services/payment";
import { datetimeToString } from "../../../utils/generic";
import { Loader2 } from "lucide-react";

const datePickerStyle = {
  backgroundColor: "transparent",
  border: "1px solid #171717",
  borderRadius: "5px",
  height: "40px",
  padding: "5px 20px",
  fontSize: "14px",
  transitionDuration: "0.2s",
};

type InvoiceStatus =
  | "RC"
  | "GENERATED"
  | "SEND_PROVIDER"
  | "PRESA IN CARICO"
  | "NS"
  | "NON INVIATA"
  | "INVIATA"
  | "MC"
  | "SEND_ANOTHER"
  | "NON TROVATA"
  | "ERROR";

const statusMessages: Record<InvoiceStatus, string> = {
  RC: "Fattura consegnata al destinatario.",
  GENERATED: "Xml fattura generato.",
  SEND_PROVIDER: "Fattura inviata al SDI.",
  "PRESA IN CARICO": "Fattura accettata dal fornitore, da inviare a SDI.",
  NS: "Fattura rifiutata dal SdI causa controlli non superati.",
  "NON INVIATA": "Fattura al fornitore, ma si Ã¨ verificato un errore.",
  INVIATA: "La fattura Ã¨ stata inviata al Sistema di Interscambio (SDI).",
  MC: "La fattura Ã¨ emessa e presente sul portale Fatture e Pagamenti del cliente, tuttavia non Ã¨ stata consegnata tramite il metodo scelto.",
  SEND_ANOTHER: "Fattura spedita con un altro servizio.",
  "NON TROVATA": "La fattura non Ã¨ stata trovata nel provider.",
  ERROR:
    "Il file della fattura Ã¨ stato inviato al fornitore, che ha restituito un errore specifico.",
};

// const lightSignalMap: Record<InvoiceStatus, string> = {
//   RC: "🟢",
//   GENERATED: "🟡",
//   SEND_PROVIDER: "🟡",
//   "PRESA IN CARICO": "🟡",
//   NS: "🔴",
//   "NON INVIATA": "🔴",
//   INVIATA: "🟢",
//   MC: "🟢",
//   SEND_ANOTHER: "🟡",
//   "NON TROVATA": "🔴",
//   ERROR: "🔴",
// };

// function getSignalStatus(status: string): string {
//   if (status in lightSignalMap) {
//     return lightSignalMap[status as InvoiceStatus];
//   }
//   return ""; // valore predefinito nel caso in cui lo stato non sia presente
// }

// function ViewXMLModal({ xmlData }: { xmlData: string }) {
//   const [xmlContent, setXmlContent] = useState(xmlData);
//   const { hideModal } = useModal();
//   return (
//     <div className="p-3">
//       <textarea
//         style={{
//           minHeight: `500px`,
//         }}
//         className="w-100"
//         value={xmlContent}
//         onChange={(e) => setXmlContent(e.target.value)}
//       ></textarea>
//       <div className="flex align-items-end w-100">
//         <Button
//           // text="Invia"
//           onClick={() => {
//             hideModal();
//           }}
//         >
//           Invia
//         </Button>
//       </div>
//     </div>
//   );
// }

{
  /*}
export default function Invoices() {
  const [invoices, setInvoices] = useState<iInvoice[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKeys, setApiKeys] = useState<Array<iApiKey>>([]);
  const [mode, setMode] = useState<
    "archived" | "unarchived" | "status" | "statusInternal"
  >("unarchived");
  const [lastUpdate, setLastUpdate] = useState<string>();
  const { showModal } = useModal();
  const navigate = useNavigate();

  const loadInvoices = async () => {
    setIsLoading(true);
    try {
      let archived: any;

      if (mode === "archived") archived = true;
      if (mode === "unarchived") archived = false;

      // const data = await getInvoicesPaginated(0, 0, archived);
      const data = await getInvoicesPaginated(archived);
      setInvoices(data.data);

      const lastUpdateBilling = await getLastUpdateBilling();
      setLastUpdate(lastUpdateBilling);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const loadApiKeys = async () => {
    setIsLoading(true);
    try {
      const data = await getMyApiKeys();
      setApiKeys(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  async function refreshStatus(isProvider: boolean) {
    setIsLoading(true);
    const data = await refreshStatusInvoice(isProvider);
    setIsLoading(false);
    alert(data);
  }

  const handleClick = (mode: string, status: boolean) => {
    switch (mode) {
      case "archived":
        setMode("archived");
        break;
      case "unarchived":
        setMode("unarchived");
        break;
      case "status":
        setMode("status");
        break;
      case "statusInternal":
        setMode("statusInternal");
        break;
    }
    refreshStatus(status);
  };

  // const explanatoryStatus = (status: string, test: number): string => {
  //   if (!status) return "Fattura NON generata";

  //   return statusMessages[status as InvoiceStatus];
  // };

  useEffect(() => {
    loadInvoices();
    loadApiKeys();
  }, [mode]);

  //if (isLoading) return <Loader2 />;

  return (
    <main>
      {isLoading && (
        <div className="loaderFull">
          <div className="flex h-100 justify-content-center align-items-center">
            <Loader2 />
          </div>
        </div>
      )}
      <div className="container">
        <div className="content">
          <div className="flex gap-4">
            <Button
              // text="Unarchived"
              // small
              onClick={() => setMode("unarchived")}
              disabled={mode === "unarchived"}
              // light={
              //   mode === "archived" ||
              //   mode === "status" ||
              //   mode === "statusInternal"
              // }
            >
              Unarchived
            </Button>
            <Button
              // text="Archived"
              // small
              onClick={() => setMode("archived")}
              disabled={mode === "archived"}
              // light={
              //   mode === "unarchived" ||
              //   mode === "status" ||
              //   mode === "statusInternal"
              // }
            >
              Archived
            </Button>
            <Button
              // text="Change Status"
              // small
              onClick={() => handleClick("status", true)}
              disabled={mode === "status"}
              // light={
              //   mode === "archived" ||
              //   mode === "unarchived" ||
              //   mode === "statusInternal"
              // }
            >
              Change Status
            </Button>
            <Button
              // text="Change Status internal"
              // small
              onClick={() => handleClick("statusInternal", false)}
              disabled={mode === "statusInternal"}
              // light={
              //   mode === "archived" ||
              //   mode === "unarchived" ||
              //   mode === "status"
              // }
            >
              Change Status internal
            </Button>
            <span className="ms-auto">last update: {lastUpdate}</span>
          </div>
          <InvoicesDatatable
            invoices={invoices}
            loadInvoices={loadInvoices}
            apiKeys={apiKeys}
          />
          // <table>
          //   <thead>
          //     <tr>
          //       <th>#</th>
          //       <th></th>
          //       <th>Payment Method Name</th>
          //       <th>Date Reciveced</th>
          //       <th>Date Paid</th>
          //       <th>Collection IDs</th>
          //       <th>Amount</th>
          //       <th># Billing</th>
          //       <th> </th>
          //       <th>Status</th>
          //       <th>Billing</th>
          //     </tr>
          //   </thead>
          //   <tbody>
          //     {invoices.map((invoice, key) => {
          //       const total = Number(invoice.payload?.total) / 100;
          //       const totalMinusIva = total / 1.22;
          //       const signalStaus = getSignalStatus(
          //         invoice.invoiceServiceStatus
          //       );
          //       const backgroundColor = key % 2 === 0 ? "#f2f2f2" : "#ffffff";

          //       return (
          //         <tr key={"invoice_" + key} style={{ backgroundColor }}>
          //           <td>{key + 1}</td>
          //           <td>{invoice.invoceInternalId}</td>
          //           <td>{invoice.paymentMethodName}</td>
          //           <td>{datetimeToString(invoice._createdAt)}</td>
          //           <td>
          //             {datetimeToString(invoice.payload?.effective_at * 1000)}
          //           </td>
          //           <td>
          //             {!invoice.collectionNames?.length ? "🔴" : null}
          //             {invoice.collectionNames?.length
          //               ? invoice.collectionNames.map((c, key) => (
          //                   <p
          //                     className="m-0"
          //                     key={"collection_" + invoice.collectionIds?.[key]}
          //                     onClick={() =>
          //                       navigate(
          //                         `/collection/${invoice.collectionIds?.[key]}/payment`
          //                       )
          //                     }
          //                   >
          //                     <u className="cursor-pointer">{c}</u>,{" "}
          //                   </p>
          //                 ))
          //               : null}
          //           </td>
          //           <td>
          //             <p>
          //               <u>{total.toFixed(2)}€</u> ({totalMinusIva.toFixed(2)}
          //               €)
          //             </p>
          //           </td>
          //           <td>{invoice.invoiceServiceStatus}</td>
          //           <td
          //             className="pt-0"
          //             style={{
          //               maxWidth: "50px",
          //               wordWrap: "break-word",
          //             }}
          //           >
          //             {signalStaus}
          //           </td>
          //           <td>
          //             <div className="flex flex-col gap-1">
          //               {explanatoryStatus(invoice.invoiceServiceStatus, key)}
          //             </div>
          //           </td>
          //           <td>
          //             <div className="flex flex-col gap-1">
          //               <Button
          //                 // text="View Billing Data"
          //                 // small
          //                 onClick={() =>
          //                   showModal(
          //                     <form className="p-3">
          //                       <BillingForm
          //                         billing={invoice.billingDetails}
          //                         disabled
          //                       />
          //                     </form>
          //                   )
          //                 }
          //               >
          //                 View Billing Data
          //               </Button>
          //               <Button
          //                 // text="View Charges"
          //                 // small
          //                 onClick={() =>
          //                   showModal(
          //                     <ChargesLogModal
          //                       payment={
          //                         {
          //                           _id: invoice.paymentMethodId,
          //                           subscriptionCurrentEndDate:
          //                             invoice.payload?.period_end * 1000,
          //                           subscriptionCurrentStartDate:
          //                             invoice.payload?.period_start * 1000,
          //                         } as any
          //                       }
          //                     />
          //                   )
          //                 }
          //               >
          //                 View Charges
          //               </Button>
          //               <Button
          //                 // text={`${invoice.archived ? "Unarchive" : "Archive"}`}
          //                 // small
          //                 onClick={() =>
          //                   showModal(
          //                     <SetInvoiceArchive
          //                       invoice={invoice}
          //                       onSuccess={loadInvoices}
          //                     />
          //                   )
          //                 }
          //               >
          //                 {`${invoice.archived ? "Unarchive" : "Archive"}`}
          //               </Button>
          //               <Button
          //                 // text={`${
          //                 //   invoice.invoiceServiceStatus
          //                 //     ? "Resend Invoice"
          //                 //     : "Send Invoice"
          //                 // }`}
          //                 disabled={
          //                   invoice.invoiceServiceStatus ===
          //                     "PRESA IN CARICO" ||
          //                   invoice.invoiceServiceStatus === "SEND_PROVIDER" ||
          //                   invoice.invoiceServiceStatus === "RC" ||
          //                   invoice.invoiceServiceStatus === "SEND_ANOTHER"
          //                 }
          //                 // small
          //                 onClick={() =>
          //                   showModal(
          //                     <SendInvoiceModal
          //                       invoice={invoice}
          //                       apikey={apiKeys[0].token}
          //                       onSuccess={loadInvoices}
          //                     />
          //                   )
          //                 }
          //               >
          //                 {`${
          //                   invoice.invoiceServiceStatus
          //                     ? "Resend Invoice"
          //                     : "Send Invoice"
          //                 }`}
          //               </Button>
          //               <Button
          //                 // text={`${
          //                 //   invoice.emailSent ? "Email Sent" : "Send Email"
          //                 // }`}
          //                 // small
          //                 // disabled={invoice.emailSent}
          //                 // light={invoice.emailSent}
          //                 onClick={() =>
          //                   showModal(
          //                     <SendEmailInvoiceModal
          //                       invoice={invoice}
          //                       onSuccess={loadInvoices}
          //                     />
          //                   )
          //                 }
          //               >
          //                 {`${invoice.emailSent ? "Email Sent" : "Send Email"}`}
          //               </Button>
          //             </div>
          //           </td>
          //         </tr>
          //       );
          //     })}
          //   </tbody>
          // </table>
        </div>
      </div>
    </main>
  );
}
*/
}

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InvoicesDatatable from "./InvoicesDatatable";
import { useSectionTitle } from "@/context/SectionTitleContext";

export default function Invoices() {
  const { t } = useTranslation();

  // set page title
  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle(`${t("invoices.page_title")}`);
  }, [setTitle]);

  const [lastUpdate, setLastUpdate] = useState<iLastUpdateInvoice>();

  const loadLastUpdate = async () => {
    try {
      const lastUpdateBilling = await getLastUpdateBilling();
      console.log(lastUpdateBilling);
      setLastUpdate(lastUpdateBilling?.data.lastUpdate);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadLastUpdate();
  }, []);

  if (!lastUpdate) return <Loader2 />;

  return (
    <div>
      <InvoicesDatatable />
      <div className="mt-20 text-right">
        <span className="text-xs text-gray-400">
          {t("invoices.lastUpdate")} {datetimeToString(lastUpdate)}
        </span>
      </div>
    </div>
  );
}
