import { COLLECTION_PERMISSIONS, iCollection } from "@/services/collection";
import PaymentMethods from "../onboarding/PaymentMethods";
import { iPaymentMethod } from "@/services/payment";
import { PaymentMethod } from "@/components/payment-method/PaymentMethod";
import { t } from "i18next";
import { useEffect } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import { Info } from "lucide-react";
import { useRbac } from "@/hooks/rbac";

export default function PaymentMethodTab({
  onRefresh,
  collection,
  pm,
}: {
  onRefresh(): void;
  collection: iCollection;
  pm?: iPaymentMethod;
}) {
  const { can } = useRbac();

  return (
    <div>
      {/* <div className="flex items-center justify-start gap-1 w-full pb-5 ">
        <p className="text-xl font-semibold">{t("collection.tab_payments")}</p>
        <Popover>
          <PopoverTrigger asChild>
            <Info className="cursor-pointer" size={16} />
          </PopoverTrigger>
          <PopoverContent className="w-80 ml-5">
            <p>{t("collection.tab_payments_description")}</p>
          </PopoverContent>
        </Popover>
      </div> */}
      <PaymentMethods onRefresh={onRefresh} collection={collection!} />
      {pm ? (
        <PaymentMethod
          payment={pm}
          collectionId={collection?._id}
          canEditPayments={can(
            COLLECTION_PERMISSIONS.set_payment_method,
            collection
          )}
        />
      ) : null}
    </div>
  );
}
