import axios from "axios";
import { Pagination } from "../utils/Pagination";
import { LANG } from "./i18next";
import { member } from "./member";

export interface iSubscriptionChargeLog {
  paymentmethodId: string;
  nftId: string;
  collectionId: string;
  nftReference?: string;
  scope: "create" | "buyback";
  amount: number;

  _createdAt: any;
}

export interface iPendingPayment {
  _id: string;

  // peding payments x user
  userId: string;
  reverseTransferId: string;

  // pending payments x group (payment method)
  paymentmethodId: string;
  groupId: string;
  collectionId: string;

  // both
  chargeId: string;
  amount: number;
  nftId: string;

  fulfilled: boolean;
}

export enum PAYMENT_METHODS_PERMISSIONS {
  admin = "admin",
  view_data = "view_data",
  delete = "delete",
  update = "update",
  methods = "methods",
  give_permission = "give_permission",
}

export const paymentMethodsRoles: Array<{
  name: string;
  permissions: Array<PAYMENT_METHODS_PERMISSIONS>;
}> = [
  {
    name: "administrator",
    permissions: [
      PAYMENT_METHODS_PERMISSIONS.update,
      PAYMENT_METHODS_PERMISSIONS.give_permission,
      PAYMENT_METHODS_PERMISSIONS.view_data,
      PAYMENT_METHODS_PERMISSIONS.delete,
      PAYMENT_METHODS_PERMISSIONS.methods,
    ],
  },
  // {
  //   name: "member",
  //   permissions: [
  //     PAYMENT_METHODS_PERMISSIONS.view_data,
  //     PAYMENT_METHODS_PERMISSIONS.use,
  //   ],
  // },
];

export interface iLastUpdateInvoice {
  lastUpdate: string;
}

export interface iInvoice {
  _id: string;

  paymentMethodId?: string;
  paymentMethodName?: string;
  collectionIds?: string[];
  collectionNames?: string[];
  billingDetails?: any;

  archived: boolean;
  emailSent: boolean;
  invoceInternalId: string;
  invoiceSeviceId: string;
  invoiceServiceStatus: string;

  payload?: {
    effective_at: any;
    period_end: any;
    period_start: any;
    total: number;
    customer_email: string;
    hosted_invoice_url: string;
    status: string;
  };

  _createdAt: any;
}

export type BillingDetails = {
  vat?: string;
  fiscalCode?: string;
  name?: string;
  zip?: string;
  city?: string;
  address?: string;
  province?: string;
  contactName?: string;
  contactPhone?: string;
  recipientCode?: string;
  pec?: string;
};

export interface iPaymentMethod {
  _id: string;

  name: string;

  // payout
  stripeId: string;
  stripeChargesEnabled: boolean;
  stripeDetailsSubmitted: boolean;
  stripePayoutsEnabled: boolean;

  // subscription
  customerId: string;
  subscriptionId: string;
  subscriptionStatus: string;

  subscriptionCurrentStartDate: Date;
  subscriptionCurrentEndDate: Date;
  subscriptionLastUpdate: Date;

  subscriptionOldDates?: Array<{
    subscriptionCurrentStartDate: Date;
    subscriptionCurrentEndDate: Date;
  }>;

  subscriptionCredit: number;

  // billing details
  billingDetails: BillingDetails;

  members: Array<member<PAYMENT_METHODS_PERMISSIONS>>;
}

export interface iInvoiceOutput {
  billingDetails: BillingDetails;
  invoiceDocumentType: string; //TD01
  InvoiceCurrency: string; //payload.currency
  InvoiceDate: string; //_createdAt
  InvoiceNum: string;
  totalPrice: string; //total
  tax: string;
  lineNumber: string; //1
  amount: string; //1
  description: string; //Takyon - Fee di creazione  + View Charges
  paymentCondition: string; //TP02
  paymentMode: string; //MP19
  paymentDate: string; //effective_at
  invoiceServiceId: string;
}

export interface iInvoiceSend {
  billingDetails: {
    vat?: string;
    fiscalCode?: string;
    name: string;
    zip: string;
    city: string;
    province: string;
    address: string;
    recipientCode: string;
    contactName?: string;
    contactPhone?: string;
    pec?: string;
  };
  invoiceDocumentType: string;
  InvoiceCurrency: string;
  InvoiceDate: string;
  InvoiceNum: string;
  totalPrice: number;
  tax: string;
  lineNumber: number;
  amount: number;
  description: string;
  paymentCondition: string;
  paymentMode: string;
  paymentDate: string;
  invoiceId?: string;
}

export interface iStatusInvoice {
  status: string;
  message: string;
}

export async function createInvoice(
  payload: iInvoiceSend,
  idCollection: string // collectionId or invoiceId
): Promise<iInvoiceOutput> {
  const response = await axios.post(`/invoices/create-invoice`, {
    payload,
    idCollection,
  });

  return response.data;
}

export async function sendInvoiceMethod(
  payload: any,
  idCollection: string // collectionId or invoiceId
): Promise<iInvoiceOutput> {
  const response = await axios.post(`/invoices/send-invoice`, {
    payload,
    idCollection,
  });

  return response.data;
}

export async function readInvoiceStatus(
  payload: string,
  apikey: string
): Promise<iStatusInvoice> {
  const response = await axios.post(
    import.meta.env.VITE_BILLING_URI + "/readStatus",
    { idInvoice: payload },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: apikey,
      },
    }
  );
  return response.data;
}

export async function changeStatusInvoiceMethod(
  payload: any,
  invoiceInternalId?: string
) {
  const response = await axios.post(`/invoices/change-status`, {
    invoicesService: [
      {
        invoiceInternalId:
          invoiceInternalId !== undefined ? invoiceInternalId : null,
        invoiceServiceId: payload.idCollection,
        newStatusService: payload.status,
      },
    ],
  });
  return response.data;
}

export async function getPaymentMethod(id: string): Promise<iPaymentMethod> {
  const response = await axios.get("/paymentmethod/" + id);
  return response.data;
}

export async function createPaymentMethod(
  payload: any
): Promise<iPaymentMethod> {
  const response = await axios.post("/paymentmethod", payload);
  return response.data;
}

export async function updatePaymentMethod(
  payload: any
): Promise<iPaymentMethod> {
  const response = await axios.patch("/paymentmethod", payload);
  return response.data;
}

export async function deletePaymentMethod(payload: any): Promise<any> {
  const response = await axios.delete("/paymentmethod", { data: payload });
  return response.data;
}

export async function cancelSubscription(payload: any): Promise<any> {
  const response = await axios.delete("/paymentmethod/subscription", {
    data: payload,
  });
  return response.data;
}

export async function getAllPaymentMethods(): Promise<Array<iPaymentMethod>> {
  const response = await axios.get("/paymentmethod");
  return response.data;
}

export async function getPendingPaymentStats(
  id: string,
  collectionId?: string
): Promise<{
  totalAmount: number;
  amountToFulfill: number;
  subscriptionAmount: {
    subscriptionStripeAmount: number;
    subscriptionTakyonAmount: number;
  };
}> {
  let q = `/paymentmethod/${id}/stats`;
  if (collectionId) q += `?collectionId=${collectionId}`;
  const response = await axios.get(q);
  return response.data;
}

export async function getSubscriptionChargeLogs(
  id: string,
  start: Date,
  end: Date,
  collectionId?: string
): Promise<iSubscriptionChargeLog[]> {
  let q = `/paymentmethod/${id}/charges?start=${start.toISOString()}&end=${end.toISOString()}`;
  if (collectionId) q += `&collectionId=${collectionId}`;
  const response = await axios.get(q);
  return response.data;
}

export async function getPendingPayments(
  id: string,
  page = 1,
  collectionId?: string
): Promise<iPendingPayment[]> {
  let q = `/paymentmethod/${id}/pending?page=${page}`;
  if (collectionId) q += `&collectionId=${collectionId}`;
  const response = await axios.get(q);
  return response.data;
}

export async function fulfillPayments(payload: any) {
  const response = await axios.post("/paymentmethod/fulfill-payments", payload);
  return response.data;
}

export async function getSubscriptionLink(
  paymentmethodId: string,
  redirect?: string
) {
  const response = await axios.get(
    `/subscription/${paymentmethodId}/checkout`,
    { params: { redirect } }
  );
  return response.data;
}

export async function getSubscriptionPortal(paymentmethodId: string) {
  const response = await axios.get(`/subscription/${paymentmethodId}/portal`);
  return response.data;
}

// todo paginate
export async function getInvoicesPaginated({
  page = 1,
  size = 25,
  query = {},
}): Promise<Pagination<iInvoice>> {
  let url = `/v2/invoices/paginate?page=${page}&size=${size}&cache=false`;
  if (query) url += `&query=${JSON.stringify(query)}`;

  const response = await axios.get(url);
  return response.data;
}

export async function toggleInvoiceArchived(
  invoiceId: string,
  archived: boolean,
  invoceInternalId: string
) {
  const response = await axios.post(`/invoices/toggle-archived`, {
    invoiceId,
    archived,
    invoceInternalId,
  });
  return response.data;
}

export async function sendInvoiceEmail(
  invoiceId: string,
  email?: string,
  lang?: LANG
) {
  const response = await axios.post("/invoices/send-email", {
    invoiceId,
    email,
    lang,
  });

  return response.data;
}

export async function refreshStatusInvoice(isProvider: boolean) {
  const response = await axios.post(`/invoices/refresh-status-invoice`, {
    isProvider,
  });

  return response.data;
}

export async function configureStripeAccountCompany(props: {
  paymentmethodId: string;
  country: string;
}): Promise<string> {
  const response = await axios.post(
    "/paymentmethod/configure-stripe-account-company",
    props
  );

  return response.data.url;
}

export async function getStripeAccountCompany(props: {
  paymentmethodId: string;
}): Promise<any> {
  const response = await axios.post("/paymentmethod/stripe", props);

  return response.data;
}

export async function getLastUpdateBilling() {
  const response = await axios.get(`/v2/invoices/getLastUpdateBilling`);

  return response.data;
}

export async function getLastInvoiceId() {
  const response = await axios.get(`/v2/invoices/getLastInternalId`);

  return response.data;
}

export async function validateInvoiceNumber(number: string) {
  const response = await axios.get(
    `/v2/invoices/validateInvoiceNumber?number=${number}`
  );
  return response.data;
}
