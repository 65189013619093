import { iCollection } from "@/services/collection";
import { useRbac } from "@/hooks/rbac";
import { iGroup } from "@/services/groups";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useTranslation } from "react-i18next";
import FormSettingsStatus from "./FormSettingsStatus";
import FormSettingsFees from "./FormSettingsFees";
import FormSettingsAnalysisMask from "./FormSettingsAnalysisMask";
import FormSettingsTrialPeriod from "./FormSettingsTrialPeriod";

interface iProps {
  collection: iCollection;
  group: iGroup;
  onRefresh(): void;
}

export default function CollectionSettingsModal(props: iProps) {
  const { can } = useRbac();

  const { t } = useTranslation();

  // TODO ADD DATA MASK ID

  return (
    <Tabs defaultValue="fees" className="">
      <TabsList className="grid grid-cols-4">
        <TabsTrigger value="fees">{t("collection.fees")}</TabsTrigger>
        <TabsTrigger value="status">{t("collection.tab_status")}</TabsTrigger>
        <TabsTrigger value="trial">{t("collection.trialEndDate")}</TabsTrigger>
        <TabsTrigger value="analysis">
          {t("analytics.settings.tabTitle")}
        </TabsTrigger>
      </TabsList>
      <div className="py-3">
        <TabsContent value="fees">
          <FormSettingsFees {...props} />
        </TabsContent>
        <TabsContent value="status">
          <FormSettingsStatus {...props} />
        </TabsContent>
        <TabsContent value="trial">
          <FormSettingsTrialPeriod {...props} />
        </TabsContent>
        <TabsContent value="analysis">
          <FormSettingsAnalysisMask {...props} />
        </TabsContent>
      </div>
    </Tabs>
  );
}
