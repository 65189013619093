export enum BookingEngine {
  AMBERGRIS = "AmberGris",
  AMENITIZ = "Amenitiz",
  AVVIO = "Avvio",
  BED_AND_BREAKFAST = "Bed-And-Breakfast",
  BEDDY = "Beddy",
  BEDZZLE = "Bedzzle",
  BEMY_GUEST = "BeMyGuest",
  BEST_WESTERN_IT = "Best Western Italia",
  BLASTNESS = "Blastness",
  BNEXT = "Bnext",
  BOOKASSIST = "BookAssist",
  BOOKCORE = "BookCore",
  BOOKING_DESIGNER = "Booking Designer",
  BOOKINGEXPERT = "BookingExpert",
  CEDYN = "Cedyn",
  CLOUDBEDS = "Cloudbeds",
  CULTBOOKING = "CultBooking",
  DIRECT_BOOK = "Direct Book",
  D_EDGE = "D-edge",
  DHYNET = "Dhynet",
  EASYCONSULTING = "EasyConsulting",
  ERICSOFT = "Ericsoft",
  ERMES_HOTELS = "Ermes Hotels",
  GUESTCENTRIC = "Guestcentric",
  HOTEL_BB = "Hotel BB",
  HOTEL_CINQUE_STELLE = "Hotel Cinque Stelle",
  HOTEL_IN_CLOUD = "Hotel in Cloud",
  HOTEL_MANAGEMENT = "Hotelmanagement",
  HOTELRUNNER = "Hotelrunner",
  HOTETEC = "Hotetec",
  HOTUSA = "Hotusa",
  HYPER_GUEST = "HyperGuest",
  IHOTELIER = "iHotelier",
  INRECEPTION = "InReception",
  IPERBOOKING = "Iperbooking",
  KROSS_TRAVEL = "Kross Travel",
  MEWS = "Mews",
  MIRAI = "Mirai",
  MYFORECAST = "MyForecast",
  MYGUESTCARE = "MyGuestCare",
  NEOBOOKINGS = "Neobookings",
  NET_AFFINITY = "Net Affinity",
  NOZIO = "Nozio",
  OCTORATE = "Octorate",
  PARATY_TECH = "Paraty Tech",
  PARITY_RATE = "Parity Rate",
  PASSPARTOUT = "Passpartout",
  PROFITROOM = "Profitroom",
  QUOVAI = "Quovai",
  RATEGAIN = "RateGain",
  RATETIGER = "RateTiger",
  RESERVATION_IN_CLOUD = "Reservation in Cloud",
  ROIBACK = "Roiback",
  ROOMCLOUD = "RoomCloud",
  SCIDOO = "Scidoo",
  SECURE_HOTEL_BOOKING = "Secure Hotel Booking",
  SIMPLEBOOKING = "Simplebooking",
  SIMPLOTEL = "Simplotel",
  SITEMINDER = "SiteMinder",
  SLOPE = "Slope",
  SYNXIS = "Synxis",
  SYSDAT = "Sysdat",
  TRAVELCLICK = "TravelClick",
  VERTICAL_BOOKING = "Vertical Booking",
  WEBHOTELIER = "WebHotelier",
  WITBOOKING = "Witbooking",
  WUBOOK = "WuBook",
  ZAK_WODOO = "Zak/ WoDoo",
}

export enum InternalParam {
  HOTEL_ID = "hotelId",
  CHANNEL_ID = "channelId",
  OTHER_ID = "otherId",
}

export type BookingEngineParam = {
  param: string;
  internal: InternalParam;
  regex: RegExp;
  groupIndex: number[];
  match?: string;
};

export const BookingEngineUrlSpecs: Partial<
  Record<BookingEngine, { urlRegex: RegExp; params: BookingEngineParam[] }>
> = {
  [BookingEngine.SIMPLEBOOKING]: {
    urlRegex: /simplebooking\.it/,
    params: [
      {
        param: "hid",
        internal: InternalParam.HOTEL_ID,
        regex: /(hid=(\d+))|(hotel\/(\d+))/,
        groupIndex: [2, 4],
      },
    ],
  },
  [BookingEngine.VERTICAL_BOOKING]: {
    urlRegex: /verticalbooking\.com/,
    params: [
      {
        param: "id_albergo",
        internal: InternalParam.HOTEL_ID,
        regex: /id_albergo=(\d+)/,
        groupIndex: [1],
      },
      {
        param: "dc",
        internal: InternalParam.CHANNEL_ID,
        regex: /dc=(\d+)/,
        groupIndex: [1],
      },
      {
        param: "id_stile",
        internal: InternalParam.OTHER_ID,
        regex: /id_stile=(\d+)/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.HOTEL_MANAGEMENT]: {
    urlRegex: /hotelmanagement\.biz/,
    params: [
      {
        param: "hotel",
        internal: InternalParam.HOTEL_ID,
        regex: /hotel\/(\d+)\//,
        groupIndex: [1],
      },
      {
        param: "channel",
        internal: InternalParam.CHANNEL_ID,
        regex: /channel\/(\d+)\//,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.ERMES_HOTELS]: {
    urlRegex: /ermeshotels\.com/,
    params: [
      {
        param: "hotel",
        internal: InternalParam.HOTEL_ID,
        regex: /hotel\/(\d+)\//,
        groupIndex: [1],
      },
      {
        param: "channel",
        internal: InternalParam.CHANNEL_ID,
        regex: /channel\/(\d+)\//,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.MYGUESTCARE]: {
    urlRegex: /booking\.([0-9A-Za-z]+)\.(it|com)\/\.eshop/,
    params: [
      {
        param: "idcliente",
        internal: InternalParam.OTHER_ID,
        regex: /booking\.(([0-9A-Za-z]+)\.(it|com))\/\.eshop/,
        groupIndex: [1],
      },
      {
        param: "idSito",
        internal: InternalParam.CHANNEL_ID,
        regex: /idSito=(\d+)/,
        groupIndex: [1],
      },
      {
        param: "idvendor",
        internal: InternalParam.HOTEL_ID,
        regex: /idvendor=(\d+)/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.BLASTNESS]: {
    urlRegex: /(blastnessbooking|clubdelsole|voihotels)\.com/,
    params: [
      {
        param: "id_albergo",
        internal: InternalParam.HOTEL_ID,
        regex: /id_albergo=(\d+)/,
        groupIndex: [1],
      },
      {
        param: "dc",
        internal: InternalParam.CHANNEL_ID,
        regex: /dc=(\d+)/,
        groupIndex: [1],
      },
      {
        param: "id_stile",
        internal: InternalParam.OTHER_ID,
        regex: /id_stile=(\d+)/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.BOOKINGEXPERT]: {
    urlRegex: /\/book\/(simple|home)\/step/,
    params: [
      {
        param: "nsid",
        internal: InternalParam.HOTEL_ID,
        regex:
          /nsid=([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.BOOKING_DESIGNER]: {
    urlRegex: /praenoto\.it/,
    params: [
      {
        param: "subdomain",
        internal: InternalParam.HOTEL_ID,
        regex: /\/([0-9A-Za-z]+).praenoto.it/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.BEMY_GUEST]: {
    urlRegex: /secure\.visioni\.info/,
    params: [
      {
        param: "domain",
        internal: InternalParam.HOTEL_ID,
        regex: /secure\.visioni\.info\/(.+)\/book/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.EASYCONSULTING]: {
    urlRegex: /hoteleasyreservations\.it/,
    params: [
      {
        param: "hotel",
        internal: InternalParam.HOTEL_ID,
        regex: /hotel=(\d+)/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.BEDZZLE]: {
    urlRegex: /booking\.bedzzle\.com/,
    params: [
      {
        param: "apikey",
        internal: InternalParam.HOTEL_ID,
        regex: /apikey=([0-9A-Za-z]+)/,
        groupIndex: [1],
      },
      {
        param: "cpn",
        internal: InternalParam.OTHER_ID,
        regex: /cpn=([0-9A-Za-z]+)/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.WEBHOTELIER]: {
    urlRegex: /reserve-online\.net/,
    params: [
      {
        param: "domain",
        internal: InternalParam.HOTEL_ID,
        regex: /([0-9A-Za-z]+)\.reserve-online\.net/,
        groupIndex: [1],
      },
      {
        param: "voucher",
        internal: InternalParam.OTHER_ID,
        regex: /voucher=([0-9A-Za-z]+)/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.PASSPARTOUT]: {
    urlRegex: /booking\.passepartout\.cloud/,
    params: [
      {
        param: "oidPortale",
        internal: InternalParam.HOTEL_ID,
        regex: /oidPortale=(\d+)/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.BOOKASSIST]: {
    urlRegex: /\/prenotazioni\/\?/,
    params: [
      {
        param: "domain",
        internal: InternalParam.HOTEL_ID,
        regex: /:\/\/(.+)\/..\//,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.SYNXIS]: {
    urlRegex: /be\.synxis\.com/,
    params: [
      {
        param: "chain",
        internal: InternalParam.CHANNEL_ID,
        regex: /chain=(\d+)/,
        groupIndex: [1],
      },
      {
        param: "hotel",
        internal: InternalParam.HOTEL_ID,
        regex: /hotel=(\d+)/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.OCTORATE]: {
    urlRegex: /book\.octorate\.com/,
    params: [
      {
        param: "siteKey",
        internal: InternalParam.HOTEL_ID,
        regex: /(codice=(\d+))|(sitekey=octosite(\d+))/,
        groupIndex: [2, 4],
      },
    ],
  },
  [BookingEngine.BOOKCORE]: {
    urlRegex: /\/bookcore\//,
    params: [
      {
        param: "domain",
        internal: InternalParam.OTHER_ID,
        regex: /:\/\/(.+)\/..\/bookcore/,
        groupIndex: [1],
      },
      {
        param: "hotel",
        internal: InternalParam.HOTEL_ID,
        regex: /\/([0-9A-Za-z]+)\?/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.MIRAI]: {
    urlRegex: /\/bookingstep1\/\?/,
    params: [
      {
        param: "domain",
        internal: InternalParam.HOTEL_ID,
        regex: /:\/\/(.+)\/bookingstep1/,
        groupIndex: [1],
      },
      {
        param: "idtokenprovider",
        internal: InternalParam.CHANNEL_ID,
        regex: /idtokenprovider=(\d+)/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.WITBOOKING]: {
    urlRegex: /\/hotel\/.+\?.*datein=.+dateout=.+adultos=.+/,
    params: [
      {
        param: "hotel",
        internal: InternalParam.HOTEL_ID,
        regex: /hotel\/(.+)\?/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.HOTEL_IN_CLOUD]: {
    urlRegex: /:\/\/booking\.hotelincloud\.com\//,
    params: [
      {
        param: "hotelId",
        internal: InternalParam.HOTEL_ID,
        regex: /\/show\/(\d+)/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.ERICSOFT]: {
    urlRegex: /\.ericsoft\./,
    params: [
      {
        param: "idh",
        internal: InternalParam.HOTEL_ID,
        regex: /idh=([0-9A-Za-z]+)/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.KROSS_TRAVEL]: {
    urlRegex: /\.kross\.travel\//,
    params: [
      {
        param: "hotel",
        internal: InternalParam.HOTEL_ID,
        regex: /:\/\/([0-9A-Za-z]+)\.kross\.travel\//,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.NOZIO]: {
    urlRegex: /:\/\/book2?\.(.+)\/accommodation\?/,
    params: [
      {
        param: "domain",
        internal: InternalParam.HOTEL_ID,
        regex: /:\/\/book\.(.+)\/accommodation/,
        groupIndex: [1],
      },
      {
        param: "channelId",
        internal: InternalParam.CHANNEL_ID,
        regex:
          /channelId=([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})&/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.WUBOOK]: {
    urlRegex: /wubook\.net\/nneb\/bk/,
    params: [
      {
        param: "ep",
        internal: InternalParam.HOTEL_ID,
        regex: /ep=([0-9a-f]+)/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.HOTEL_BB]: {
    urlRegex: /hotel-bb\.com/,
    params: [
      {
        param: "hotel",
        internal: InternalParam.HOTEL_ID,
        regex: /\/hotel\/(.+?)\?/,
        groupIndex: [1],
      },
      {
        param: "destination",
        internal: InternalParam.OTHER_ID,
        regex: /destination=(.+?)&/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.BEST_WESTERN_IT]: {
    urlRegex: /bestwestern\.it/,
    params: [
      {
        param: "hotelId",
        internal: InternalParam.HOTEL_ID,
        regex: /\/IT\/(.+)\.aspx\?/,
        groupIndex: [1],
      },
      {
        param: "SOB",
        internal: InternalParam.CHANNEL_ID,
        regex: /SOB=([A-Z0-9]+)/,
        groupIndex: [1],
      },
    ],
  },
  [BookingEngine.SECURE_HOTEL_BOOKING]: {
    urlRegex: /secure-hotel-booking\.com/,
    params: [
      {
        param: "hotelId",
        internal: InternalParam.HOTEL_ID,
        regex: /\/(d-edge|smart)\/(.+?)\//,
        groupIndex: [2],
      },
      {
        param: "channelId",
        internal: InternalParam.CHANNEL_ID,
        regex: /\/(d-edge|smart)\/(.+?)\/(.+?)\/it(-IT)?\//,
        groupIndex: [3],
      },
    ],
  },
};

export function bookingUrlParser(url: string): {
  engine: string;
  params: BookingEngineParam[];
} {
  for (const [engine, { urlRegex, params }] of Object.entries(
    BookingEngineUrlSpecs
  )) {
    const isEngine = urlRegex.test(url);
    if (!isEngine) continue;

    const parsedParams = params.map((p) => {
      const matches = url.match(p.regex);
      if (matches)
        for (const index of p.groupIndex) {
          const match = matches[index];
          if (match) return { ...p, match };
        }

      return p;
    });

    return { engine, params: parsedParams };
  }

  return { engine: "", params: [] };
}
