import { BID_STATUS } from "@/services/nft";
import { Badge } from "@/components/ui/badge";
import { t } from "i18next";

export default function GetBidStatus(status: BID_STATUS) {
  switch (status) {
    case BID_STATUS.PENDING:
      return <Badge variant="outline">{t("bid.pending")}</Badge>;
    case BID_STATUS.DECLINED:
      return <Badge variant="destructive">{t("bid.declined")}</Badge>;
    case BID_STATUS.ACCEPTED:
      return <Badge variant="takyon">{t("bid.accepted")}</Badge>;
    case BID_STATUS.FULFILLED:
      return <Badge variant="secondary">{t("bid.fulfilled")}</Badge>;
    case BID_STATUS.EXPIRED:
      return <Badge variant="default">{t("bid.expired")}</Badge>;
    case BID_STATUS.DELETED:
      return <Badge variant="secondary">{t("bid.deleted")}</Badge>;
    default:
      return <Badge variant="default">{status}</Badge>;
  }
}
