import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { CollectionPageChildProps } from "../CollectionPage";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { patchCollection, sendOnboardingEmail } from "@/services/collection";
import { fireTagManagerEvent } from "@/services/tagmanager";
import { fireJuneEvent } from "@/components/analytics/JuneAnalytics";
import { patchGroup } from "@/services/groups";

export default function HotelChain({
  collection,
  onRefresh,
}: CollectionPageChildProps) {
  const { setBackBtn, setProgress, setTitleCollectionPage } =
    useOutletContext<any>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isIndependent, setIsIndependent] = useState<string>(
    collection?.census?.groupName !== undefined
      ? collection.census.groupName === ""
        ? "true"
        : "false"
      : ""
  );
  const [chainName, setChainName] = useState("");

  useEffect(() => {
    setBackBtn(true);
    setProgress(100);
  }, []);

  const onSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    // console.log("isIndependent: ", isIndependent);
    // console.log("chainName: ", chainName);
    // console.log("groupName: ", collection?.census?.name);

    try {
      if (isIndependent === "true") {
        await patchCollection({
          collectionId: collection?._id,
          census: {
            ...collection?.census,
            groupName: collection?.census?.name,
          },
        });
      } else {
        await patchGroup({
          groupId: collection?.groupId,
          name: chainName,
        });
        await patchCollection({
          collectionId: collection?._id,
          census: {
            ...collection?.census,
            groupName: chainName,
          },
        });
      }

      await sendOnboardingEmail(collection?._id || "");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      await onRefresh();
      fireTagManagerEvent("create_collection", {
        name_collection: collection?.name,
      });
      fireJuneEvent("create_collection", {
        name_collection: collection?.name,
      });

      navigate(`/collection/${collection?._id}/analytics`, {
        state: { openModal: true },
      });
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="mt-5 mb-10">
        <p className="text-base font-medium">{t("onboarding.step")} 5</p>
        <p className="text-2xl font-medium">
          {t("onboarding.hotel_chain_title")}
        </p>
      </div>
      <div className="flex-1 overflow-y-auto">
        <RadioGroup
          value={isIndependent}
          onValueChange={(value) => {
            setIsIndependent(value);
            /*if (value === "true") {
              setChainName("");
            }*/
          }}
          className="flex flex-col gap-4"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="false" id="independent" />
            <label
              htmlFor="independent"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {t("onboarding.yes")}
            </label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="true" id="not-independent" />
            <label
              htmlFor="not-independent"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {t("onboarding.no")}
            </label>
          </div>
        </RadioGroup>

        {isIndependent === "false" && (
          <div className="flex flex-col space-y-2 mb-6 mt-11">
            <Label htmlFor="chainName">{t("onboarding.chain_name")}*</Label>
            <Input
              id="chainName"
              placeholder={t("onboarding.enter_chain_name")}
              value={chainName}
              onChange={(e) => setChainName(e.target.value)}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <Button
            className=""
            type="button"
            onClick={() =>
              navigate(`/collection/${collection?._id}/infos/hotelbenefits`)
            }
          >
            {t("auth.back")}
          </Button>
          {!isLoading ? (
            <Button
              variant="takyon"
              type="submit"
              onClick={onSubmit}
              disabled={
                !isIndependent || (isIndependent === "false" && !chainName)
              }
            >
              {t("buttons.save_and_continue")}
            </Button>
          ) : (
            <Button variant="takyon" disabled>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t("auth.loading")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
