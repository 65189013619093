import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"; // Assuming you have a Select component
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Separator } from "@/components/ui/separator";
import { LANG } from "@/services/i18next";
import { patchUser } from "@/services/auth";
import { selectUser } from "@/store/userSlice";
import { useSelector } from "react-redux";

const formSchema = z.object({
  firstName: z.string().nonempty({ message: "Required" }),
  secondName: z.string().nonempty({ message: "Required" }),
  email: z.string().email({ message: "Invalid email address" }),
  marketingEmails: z.boolean(),
  lang: z.string().nonempty({ message: "Required" }),
});

export default function Account() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      secondName: "",
      email: "",
      marketingEmails: false,
      lang: "", // Set default to a valid option
    },
  });

  useEffect(() => {
    if (user) {
      form.reset({
        firstName: user.firstName,
        secondName: user.secondName,
        email: user.email,
        marketingEmails: user.marketingEmails,
        lang: user.lang, // this not working
      });
    }

    console.log(user);
  }, [user, form]);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setIsLoading(true);

    try {
      const payload = {
        ...values,
        marketingEmails: values.marketingEmails ? "on" : "off",
      };

      await patchUser(payload);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  return (
    <main className="w-full p-4 md:gap-8 md:px-14 md:py-8">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="grid gap-4 mt-6"
        >
          {/* First Name */}
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("account.first_name")}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("account.first_name_placeholder")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Last Name */}
          <FormField
            control={form.control}
            name="secondName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("account.last_name")}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("account.last_name_placeholder")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Email */}
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("account.email")}</FormLabel>
                <FormControl>
                  <Input
                    readOnly
                    disabled
                    placeholder={t("account.email_placeholder")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Separator className="my-4" />

          {/* Marketing Preferences */}
          <FormField
            control={form.control}
            name="marketingEmails"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center gap-3">
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>{t("account.marketing_preferences")}</FormLabel>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <Separator className="my-4" />

          <FormField
            control={form.control}
            name="lang"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("account.language")}</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  value={field.value} // Use value instead of defaultValue
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t("form.select")} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.values(LANG).map((lang, key) => (
                      <SelectItem key={"lang:" + key} value={lang}>
                        {lang.toUpperCase()}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <Separator className="my-4" />

          {/* Save Button */}
          <div>
            <Button variant={"takyon"} disabled={isLoading} type="submit">
              {t("account.save")}
            </Button>
          </div>
        </form>
      </Form>
    </main>
  );
}
