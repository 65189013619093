import axios from "axios";

export interface iApiKey {
  _id: string;
  name: string;
  token: string;
  userId: string;
  expireDate: Date;
}

export async function getMyApiKeys(): Promise<Array<iApiKey>> {
  const response = await axios.get("/apikey");
  return response.data;
}

export async function createApiKey(payload: any): Promise<iApiKey> {
  const response = await axios.post("/apikey", payload);
  return response.data;
}

export async function deleteApiKey(payload: any): Promise<any> {
  const response = await axios.delete("/apikey", { data: payload });
  return response.data;
}
