import axios from "axios";

import { Pagination } from "../utils/Pagination";

export interface iPartner {
  _id?: string;

  name?: string;

  location: {
    label: string;
    value: object;
    address_components: object;
    coordinates: object;
  };
  stars?: string;

  logo?: string;
  image?: string;

  liveDate?: Date;
  website?: string;

  group?: string;

  signDate?: Date;
  paymentStartDate?: Date;
  groupId?: string;
  collectionId?: string;
  accommodationType?: "hotel" | "extra-hotel";
  revenueStrategy?: "only-not-ref" | "additional-not-ref";
  destinationType?: "city" | "lake" | "mountain" | "sea" | "wellness";
  roomsAmount?: number;
  apiIntegration?: boolean;
  isOpen?: boolean;
  bookingEngine?: string;
}

export async function getPartnersPaginated({
  mode = "all" as "all" | "live" | "offline",
  page = 1,
  size = 25,
  query = {},
  sort = {},
}: {
  mode?: "all" | "live" | "offline";
  page?: number;
  size?: number;
  query?: any;
  sort?: any;
}): Promise<Pagination<iPartner>> {
  const url = "/partner/paginate";

  const params: any = {
    page,
    size,
    query: JSON.stringify(query),
    sort: JSON.stringify(sort),
  };

  let onlyLive;

  if (mode === "live") onlyLive = true;
  if (mode === "offline") onlyLive = false;
  if (typeof onlyLive === "boolean") params.onlyLive = onlyLive;

  const response = await axios.get(url, {
    params,
  });
  return response.data;
}

export async function savePartner(payload: any) {
  const response = await axios.post("/partner", payload);
  return response.data;
}

export async function deletePartner(payload: any) {
  const response = await axios.delete("/partner", { data: payload });
  return response.data;
}
