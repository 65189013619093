import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Form,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useModal } from "@/context/ModalContext";
import { LoadingSubmitButton } from "@/components/form_fields/LoadingSubmitButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface CreateEmailModalProps {
  onCreate: Function;
}

export function CreateEmail({ onCreate }: CreateEmailModalProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { hideModal } = useModal();
  const formSchema = z.object({
    tag: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  // const submit = (e: FormEvent<HTMLFormElement>) => {
  const onSubmit = (data: z.infer<typeof formSchema>) => {
    setIsLoading(true);

    try {
      onCreate(data);
      hideModal();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        // onSubmit={submit}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="tag"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Mail Tag</FormLabel>
              <FormControl>
                <Input placeholder="Inserisci il tag dell'email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div>
          <LoadingSubmitButton variant="takyon" isLoading={isLoading}>
            {t("emails.single_send.button")}
          </LoadingSubmitButton>
        </div>
      </form>
    </Form>
  );
}
