import axios from "axios";
import { t } from "i18next";
import { hotelCensusLocationType } from "../services/collection";
import * as XLSX from "xlsx";

export const PAGE_DIM = 50;

export const starsOptions = [
  { text: "", value: "" },
  { text: "1", value: "1" },
  { text: "2", value: "2" },
  { text: "3", value: "3" },
  { text: "4", value: "4" },
  { text: "5", value: "5" },
  { text: "5L", value: "5L" },
];

export const hotelCensusLocationTypeOptions: {
  text: string;
  value?: hotelCensusLocationType;
}[] = [
  { text: t("collection.city"), value: "city" },
  { text: t("collection.lake"), value: "lake" },
  { text: t("collection.mountain"), value: "mountain" },
  { text: t("collection.sea"), value: "sea" },
  { text: t("collection.wellness"), value: "wellness" },
];

// Exp - start
export const expCategoriesOptions = [
  { text: "", value: "" },
  { text: "Sport", value: "sport" },
  { text: "Adrenaline", value: "adrenaline" },
  { text: "City", value: "city" },
  { text: "Wellness", value: "wellness" },
];
// Exp - end

export function scrollToTop() {
  window.scrollTo({ top: 0 });
}

export function formToObject(form: any, includeVoid = true) {
  const myFormData = new FormData(form);

  const formDataObj: any = {};
  myFormData.forEach((value, key) => {
    if (!includeVoid && value === "") return;

    if (key.includes("[]")) {
      key = String(key).replace("[]", "");
      if (!formDataObj[key]) formDataObj[key] = [];
      formDataObj[key].push(value);
      return;
    }

    if (!key.includes("[]") && key.includes("[") && key.includes("]")) {
      const data = key.replace("]", "").split("[");
      if (!formDataObj[data[0]]) formDataObj[data[0]] = {};
      formDataObj[data[0]][data[1]] = value;
      return;
    }

    return (formDataObj[key] = value);
  });

  return formDataObj;
}

export const autoGrowTextArea = (e: any) => {
  e.target.style.height = "5px";
  e.target.style.height = e.target.scrollHeight + "px";
};

export const datetimeToString = (
  isodate: any,
  gmt: number = -new Date().getTimezoneOffset(),
  short = false,
  time = false
) => {
  if (!isodate) return undefined;

  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ].map((e) => t("date." + e));

  const genDate = new Date(isodate);

  const localOffset = -new Date().getTimezoneOffset();
  const delta = gmt - localOffset; // minutes

  const date = new Date(genDate.setMinutes(genDate.getMinutes() + delta));

  let result = `${date.getDate()} ${
    months[date.getMonth()]
  } ${date.getFullYear()}`;

  if (time) {
    result += ` ${date.getHours()}:${date.getMinutes()} GMT${
      date.getTimezoneOffset() / 60
    }`;
  }

  if (short) {
    result = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    if (time) {
      result += ` ${date.getHours()}:${date.getMinutes()}`;
    }
  }

  return result;
};

export function compressImage(dataUrl: string, newWidth: number = 1024) {
  return new Promise((resolve) => {
    let image: any, oldWidth, oldHeight, newHeight, canvas;

    // Create a temporary image so that we can compute the height of the downscaled image.
    image = new Image();
    image.onload = function () {
      oldWidth = image.width;
      oldHeight = image.height;
      newHeight = Math.floor((oldHeight / oldWidth) * newWidth);

      // Create a temporary canvas to draw the downscaled image on.
      canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw the downscaled image on the canvas and resolve the promise with the new data URL.
      canvas.getContext("2d")!.drawImage(image, 0, 0, newWidth, newHeight);

      // Compress the image until it is less than 500k
      let quality = 0.9; // Initial quality value
      let dataUrlCompressed = canvas.toDataURL("image/png", quality);
      while (dataUrlCompressed.length > 500000 && quality >= 0.1) {
        quality -= 0.05;
        dataUrlCompressed = canvas.toDataURL("image/png", quality);
      }

      resolve(dataUrlCompressed);
    };
    image.src = dataUrl;
  });
}

export const isEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function toggleScroll(bool: boolean) {
  // console.log("TODO scroll lock");
}

export async function getAddressComponents(placeId: string) {
  const response = await axios.get("/google/places/" + placeId);
  return response.data;
}

export async function getPlaceCoordinates(placeId: string) {
  const response = await axios.get("/google/coordinates/" + placeId);
  return response.data;
}

export async function getGmt(placeId: string) {
  const response = await axios.get("/google/gmt/" + placeId);
  return response.data;
}

export function csvToObj(csv: any, separator = ",") {
  const lines = csv.split("\n");
  const headers = lines[0]
    .split(separator)
    .map((h: any) => String(h).replace(/\r/g, ""));

  const data = [];

  for (let i = 1; i < lines.length; i++) {
    const obj: any = {};
    const currentLine = lines[i].split(separator);

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentLine[j];
    }

    data.push(obj);
  }

  return data.filter((e) => Object.keys(e).length > 0);
}

export function dateToGMT(date: any, hotelTz: number) {
  date = new Date(date);

  const localTz = -new Date().getTimezoneOffset();
  const delta = hotelTz - localTz; // minutes

  date = new Date(date.setMinutes(date.getMinutes() - delta));

  return date;
}

export function makeid(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const scrollToTopList = (refElement: any) => {
  if (refElement.current) {
    refElement.current.scrollIntoView({ behavior: "smooth" });
  }
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d as any);
}

export function getUrlParameter(key: string): string | null {
  const params = new URL(window.location.toString()).searchParams;
  return params.get(key);
}

export const downloadExcel = (data: any, name: string) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, `${name}.xlsx`);
};

export function formatNumberEuropean(
  num: number,
  decimalPlaces: number = 2
): string {
  if (isNaN(num)) {
    throw new Error("Invalid number input");
  }

  // Format the number with fixed decimal places
  const formattedNumber = num.toFixed(decimalPlaces);

  // Replace thousands separators with periods
  const parts = formattedNumber.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Replace the decimal point with a comma
  return parts.join(",");
}

export function getAmountOfNights(startDate: any, endDate: any) {
  try {
    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

    const startDateTime = new Date(startDate).getTime();
    const endDateTime = new Date(endDate).getTime();

    const timeDifference = endDateTime - startDateTime;
    const numberOfNights = Math.round(timeDifference / oneDay);

    return Number(numberOfNights);
  } catch (error) {
    console.log(error);
    return 0;
  }
}

export function isEmptyObject(obj: object) {
  return Object.keys(obj).length === 0;
}
