import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GROUP_PERMISSIONS, iGroup, patchGroup } from "@/services/groups";
import { useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";
import { TFunction } from "i18next";
import { useRbac } from "@/hooks/rbac";

const createFormSchema = (t: TFunction) => {
  return z.object({
    name: z.string(),
  });
};

export function GroupSettingsForm({
  group,
  onSuccess,
}: {
  group: iGroup;
  onSuccess(): void;
}) {
  const { t } = useTranslation();

  const { can } = useRbac();

  // Crea lo schema dinamico
  const formSchema = createFormSchema(t);

  // Initialize the form with appropriate schema and default values
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { name: group.name },
  });

  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);

    try {
      // Create the payload based on user role
      const payloadBase = {
        groupId: group._id,
        name: values.name,
      };

      // Submit the form with the correct payload
      await patchGroup({ ...payloadBase });

      onSuccess();
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <fieldset disabled={!can(GROUP_PERMISSIONS.update, group)}>
          <div className="grid gap-4">
            {/* Name field (shown to all users) */}
            <div className="grid gap-2">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("group.name")}</FormLabel>
                    <FormControl>
                      <Input placeholder={t("group.name")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {can(GROUP_PERMISSIONS.update, group) && (
              <div>
                <Button variant={"takyon"} disabled={isLoading} type="submit">
                  {t("buttons.save")}
                </Button>
              </div>
            )}
          </div>
        </fieldset>
      </form>
    </Form>
  );
}
