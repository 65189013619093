import React from "react";
import { Outlet } from "react-router-dom";
import Header from "@/components/layout/Header";
import img from "../assets/img/auth_layout.png";

const AuthLayout: React.FC = () => {
  return (
    <>
      <Header />
      <div className="w-full h-full lg:grid lg:grid-cols-2">
        <div className="flex items-center justify-center py-12 px-3 ">
          <Outlet />
        </div>
        <div className="hidden h-full bg-muted lg:block">
          <div
            style={{
              background: `url(${img})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
            className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
          ></div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
