import { useTranslation } from "react-i18next";
import DeveloperSettingsForm from "./DeveloperSettingsForm";
import { useModal } from "@/context/ModalContext";
import { Button } from "@/components/ui/button";
import DeveloperWebhookLogsDatatable from "./DeveloperWebhookLogsDatatable";
import { useEffect, useState } from "react";
import { getWebhook, Webhook } from "@/services/webhook";

export default function DeveloperWebhookConfig() {
  const { t } = useTranslation();
  const { showModal } = useModal();

  const [webhook, setWebhook] = useState<Webhook>();
  const [isLoading, setIsLoading] = useState(false);

  const loadWebhook = async () => {
    setIsLoading(true);
    try {
      const data = await getWebhook();
      setWebhook(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadWebhook();
  }, []);

  return (
    <>
      <div className="flex justify-between items-center">
        <p className="text-xl font-bold title-section">{t("dev.webhook")}</p>
        <Button
          className="text-white bg-black hover:bg-gray-600 hover:text-white"
          // text={String(t("dev.view_logs"))}
          // small
          // onClick={() => setIsWebhookLogs(true)}
          onClick={() =>
            showModal(<DeveloperWebhookLogsDatatable />, {
              title: t("dev.view_logs"),
              description: "",
            })
          }
        >
          {t("dev.view_logs")}
        </Button>
      </div>
      <DeveloperSettingsForm webhook={webhook} setWebhook={setWebhook} />
    </>
  );
}
