import { baseResponse } from "@/services/dataanalysismask";
import { useRbac } from "@/hooks/rbac";
import { useTranslation } from "react-i18next";
import { formatNumberEuropean } from "@/utils/generic";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  CirclePlus,
  EllipsisVertical,
  Info,
  Settings,
  Users,
} from "lucide-react";
import { GROUP_PERMISSIONS, iGroup } from "@/services/groups";
import { useModal } from "@/context/ModalContext";
import GroupTeam from "@/components/group/GroupTeam";
import GroupSettings from "@/components/group/GroupSettings";
import { Button } from "@/components/ui/button";
import { CreateCollectionForm } from "@/components/form/CreateCollectionForm";
import { Skeleton } from "@/components/ui/skeleton";

export default function OverviewGroup({
  data,
  group,
  onRefresh,
  isLoading,
}: {
  data: baseResponse;
  group: iGroup;
  onRefresh(): void;
  isLoading: boolean;
}) {
  const { t } = useTranslation();
  const textOpacity = 0.6;
  const { can } = useRbac();

  const groupTeam = can(GROUP_PERMISSIONS.view_members, group);
  const groupSettings = can(GROUP_PERMISSIONS.update, group);
  const canGroupActions = groupTeam || groupSettings;
  const { isSuperAdmin } = useRbac();

  const { showModal } = useModal();

  if (isLoading) return <Skeleton className="w-full h-[286px]" />;

  if (data && data.nftAmount && data.nftAmount > 0) {
    return (
      <div
        className="rounded-xl p-5 flex gap-2 justify-between"
        style={{
          background: "#000",
          color: "#fff",
        }}
      >
        <div className="w-[calc(50%-0.25rem)] flex flex-col justify-between">
          <div className="flex items-center justify-start gap-1 w-full">
            <p className="text-2xl font-medium">{group.name}</p>
            <Popover>
              <PopoverTrigger asChild>
                <Info className="cursor-pointer" size={16} />
              </PopoverTrigger>
              <PopoverContent className="w-80">
                <p>{t("analytics.overview.popover")}</p>
              </PopoverContent>
            </Popover>
          </div>
          <div>
            <p
              className="text-sm font-medium mb-1"
              style={{ opacity: textOpacity }}
            >
              {t("analytics.overview.generated_tak")}
            </p>
            <div className="">
              <span className="text-5xl font-medium text-gradient">
                €{formatNumberEuropean(Number(data.grossAmount))}
              </span>
              <span
                className="text-3xl font-normal"
                style={{ opacity: textOpacity }}
              >
                {" "}
                {formatNumberEuropean(Number(data.nftAmount), 0)} Tak
              </span>
            </div>
          </div>
          <div className="h-[40px]"></div>
          {/* {can(
          [
            COLLECTION_PERMISSIONS.create_nft,
            COLLECTION_PERMISSIONS.update_nft,
          ],
          collection
        ) && (
          <div>
            <ButtonCreateNft
              collection={collection!}
              disableReason={
                !isPaymentValid
                  ? t("collection.invalid_payment_method")
                  : undefined
              }
            />
          </div>
        )} */}
        </div>
        <div className="w-[calc(50%-0.25rem)] flex flex-col gap-3">
          <div className="flex gap-3">
            {renderBlock(
              t("analytics.overview.average_window"),
              data?.averageBookingWindow
                ? `${formatNumberEuropean(
                    Number(data.averageBookingWindow),
                    1
                  )} ${t("analytics.overview.days")}`
                : "0"
            )}
            <div style={{ width: "50%" }}>
              {isSuperAdmin && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild className="cursor-pointer">
                    <EllipsisVertical className="ml-auto" />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {groupTeam && (
                      <DropdownMenuItem
                        className="flex items-center gap-2"
                        onClick={() =>
                          showModal(
                            <GroupTeam
                              group={group}
                              onRefresh={onRefresh}
                              members={group.members}
                            />,
                            {
                              title: "",
                              description: "",
                            }
                          )
                        }
                      >
                        <Users size={18} className="text-gray-400"></Users>
                        {t("group.team")}
                      </DropdownMenuItem>
                    )}
                    {groupSettings && (
                      <DropdownMenuItem
                        className="flex items-center gap-2"
                        onClick={() =>
                          showModal(
                            <GroupSettings
                              onRefresh={onRefresh}
                              group={group}
                            />,
                            {
                              title: "",
                              description: "",
                            }
                          )
                        }
                      >
                        <Settings
                          size={18}
                          className="text-gray-400"
                        ></Settings>
                        {t("group.settings")}
                      </DropdownMenuItem>
                    )}
                    {can(GROUP_PERMISSIONS.create_collection, group) && (
                      <DropdownMenuItem>
                        <Button
                          variant={"gray"}
                          onClick={() => {
                            showModal(
                              <CreateCollectionForm
                                groupId={group._id}
                                onSuccess={onRefresh}
                              />,
                              {
                                title: t("group.create_collection"),
                                description: "",
                              }
                            );
                          }}
                        >
                          <CirclePlus size={18} className="mr-2"></CirclePlus>
                          {t("group.create_collection")}
                        </Button>
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </div>
          </div>
          <div className="flex gap-3">
            {renderBlock(
              t("analytics.overview.roomNights"),
              data?.roomNights ? `${data.roomNights}` : "0"
            )}
            {renderBlock(
              t("analytics.overview.averageLenghtOfStay"),
              data?.averageLenghtOfStay
                ? `${formatNumberEuropean(
                    Number(data.averageLenghtOfStay),
                    1
                  )} ${t("analytics.overview.days")}`
                : "0"
            )}
          </div>
          <div className="flex gap-3">
            {renderBlock(
              t("analytics.overview.averageDailyRate"),
              data?.averageDailyRate
                ? `€${formatNumberEuropean(Number(data.averageDailyRate), 0)}`
                : "0"
            )}
            {renderBlock(
              t("analytics.overview.averageBookingPrice"),
              data?.averageBookingPrice
                ? `€${formatNumberEuropean(
                    Number(data.averageBookingPrice),
                    0
                  )}`
                : "0"
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex justify-between text-white">
        <p className="text-2xl font-medium">{group.name}</p>
        {isSuperAdmin && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild className="cursor-pointer">
              <EllipsisVertical className="ml-auto" />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {groupTeam && (
                <DropdownMenuItem
                  className="flex items-center gap-2"
                  onClick={() =>
                    showModal(
                      <GroupTeam
                        group={group}
                        onRefresh={onRefresh}
                        members={group.members}
                      />,
                      {
                        title: "",
                        description: "",
                      }
                    )
                  }
                >
                  <Users size={18} className="text-gray-400"></Users>
                  {t("group.team")}
                </DropdownMenuItem>
              )}
              {groupSettings && (
                <DropdownMenuItem
                  className="flex items-center gap-2"
                  onClick={() =>
                    showModal(
                      <GroupSettings onRefresh={onRefresh} group={group} />,
                      {
                        title: "",
                        description: "",
                      }
                    )
                  }
                >
                  <Settings size={18} className="text-gray-400"></Settings>
                  {t("group.settings")}
                </DropdownMenuItem>
              )}
              {can(GROUP_PERMISSIONS.create_collection, group) && (
                <DropdownMenuItem>
                  <Button
                    variant={"gray"}
                    onClick={() => {
                      showModal(
                        <CreateCollectionForm
                          groupId={group._id}
                          onSuccess={onRefresh}
                        />,
                        {
                          title: t("group.create_collection"),
                          description: "",
                        }
                      );
                    }}
                  >
                    <CirclePlus size={18} className="mr-2"></CirclePlus>
                    {t("group.create_collection")}
                  </Button>
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    );
  }

  function renderBlock(title: string, value: string, width: string = "50%") {
    return (
      <div
        className="flex flex-col px-4 pt-3 pb-2 gap-1 rounded-md"
        style={{
          background: "rgba(255, 255, 255, 0.15)",
          width: width,
          border: "1px solid rgba(255, 255, 255, 0.15)",
        }}
      >
        <p className="text-xs">{title}</p>
        <p className="font-semibold text-2xl">{value}</p>
      </div>
    );
  }
}
