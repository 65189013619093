import { useModal } from "@/context/ModalContext";
import { Button } from "@/components/ui/button";
import FormCreateNftWrapper from "./FormCreateNftWrapper";
import {
  COLLECTION_TYPE,
  iCollection,
  COLLECTION_PERMISSIONS,
} from "@/services/collection";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { Plus } from "lucide-react";
import { useRbac } from "@/hooks/rbac";

export default function ButtonCreateNft({
  collection,
  disableReason,
  onSuccess,
}: {
  collection: iCollection;
  disableReason?: string;
  onSuccess?: () => void;
}) {
  const { showModal } = useModal();
  const { t } = useTranslation();
  const { can } = useRbac();

  const isPaymentValid = useMemo(
    () =>
      can(COLLECTION_PERMISSIONS.admin, collection) ||
      collection.isFree === true ||
      (!collection.isFree &&
        ["active", "trialing", "past_due"].includes(
          String(collection?.subscriptionStatus)
        )),
    [collection]
  );

  const havePermissions = can(
    [COLLECTION_PERMISSIONS.create_nft, COLLECTION_PERMISSIONS.update_nft],
    collection
  );

  const [isDisabled, setIsDisabled] = useState(
    disableReason !== undefined || !havePermissions || !isPaymentValid
  );

  // console.log(
  //   "isDisabled",
  //   disableReason !== undefined,
  //   havePermissions,
  //   isPaymentValid,
  //   collection.isFree
  // );

  const onClickHandler = () => {
    showModal(
      <FormCreateNftWrapper collection={collection} onSuccess={onSuccess} />,
      {
        title: `${t("nft.create")} - ${collection.name}`,
        description: t("nft.create_description"),
      }
    );
  };

  return can(
    [COLLECTION_PERMISSIONS.create_nft, COLLECTION_PERMISSIONS.update_nft],
    collection
  ) ? (
    <Button
      variant="takyon"
      className="w-full flex justify-start px-2 py-0 h-8"
      onClick={onClickHandler}
      disabled={isDisabled}
      title={disableReason || t("nft.create")}
    >
      <Plus size={18} className="mr-2" />
      {collection.type === COLLECTION_TYPE.experience
        ? t("nft.create_experience")
        : t("nft.create_new")}
    </Button>
  ) : null;
}
