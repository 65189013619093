import { iCollection } from "@/services/collection";

export enum LINK_TYPE {
  HOME = "HOME",
  COLLECTION = "COLLECTION",
  GROUP = "GROUP",
  COLLECTION_TAKS = "COLLECTION_TAKS",
  COLLECTION_ANALYTICS = "COLLECTION_ANALYTICS",
  COLLECTION_ONBOARDING = "COLLECTION_ONBOARDING",
  AUTH = "AUTH",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  PAYMENT_METHODS = "PAYMENT_METHODS",
  PAYMENT_METHOD = "PAYMENT_METHOD",
  ACCOUNT = "ACCOUNT",
  DEVELOPER = "DEVELOPER",
  LOYALTY = "LOYALTY",
  LOYALTY_BILLING = "LOYALTY_BILLING",
  TOS_DASHBOARD = "TOS_DASHBOARD",
  PRIVACY_DASHBOARD = "PRIVACY_DASHBOARD",
  COOKIE_DASHBOARD = "COOKIE_DASHBOARD",
  // ADMIN
  ADMIN_HOME = "ADMIN_HOME",
  USERS = "USERS",
  PARTNERS = "PARTNERS",
  EMAILS = "EMAILS",
  SUPPORT = "SUPPORT",
  PROMOCODES = "PROMOCODES",
  INVOICES = "INVOICES",
  DOCUMENTS = "DOCUMENTS",
  TRANSLATIONS = "TRANSLATIONS",
  NFTS = "NFTS",
  AI = "AI",
  REPORTS = "REPORTS",
  QUOTE = "QUOTE",
  COLLECTION_MANAGER = "COLLECTION_MANAGER",
  EMAILS_SERVICE = "EMAILS_SERVICE",
  HOTEL_REGISTRATION_MANAGER = "HOTEL_REGISTRATION_MANAGER",
  DATA_ANALYSIS_MASKS = "DATA_ANALYSIS_MASKS",
  LOYALTY_TERMS = "LOYALTY_TERMS",
}

const LINK_CONFIG: {
  [key in LINK_TYPE]: string;
} = {
  HOME: "/",
  COLLECTION: "/collection/__COLLECTIONID__",
  COLLECTION_TAKS: "/collection/__COLLECTIONID__/taks",
  COLLECTION_ANALYTICS: "/collection/__COLLECTIONID__/analytics",
  COLLECTION_ONBOARDING: "/collection/__COLLECTIONID__/infos/selecthotel",
  AUTH: "/auth",
  LOGIN: "/auth/login",
  REGISTER: "/auth/register",
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/reset-password",
  VERIFY_EMAIL: "/auth/verify-email",
  GROUP: "/group/__GROUPID__",
  PAYMENT_METHODS: "/paymentmethods",
  PAYMENT_METHOD: "/paymentmethods/__PAYMENTMETHODID__",
  ACCOUNT: "/account",
  DEVELOPER: "/account/developer",
  LOYALTY: "/collection/__COLLECTIONID__/loyalty",
  LOYALTY_BILLING: "/collection/__COLLECTIONID__/loyalty/billing",
  LOYALTY_TERMS: "/collection/__COLLECTIONID__/loyalty/terms",
  TOS_DASHBOARD: "/tos",
  PRIVACY_DASHBOARD: "/privacy",
  COOKIE_DASHBOARD: "/cookie",
  // ADMIN
  ADMIN_HOME: "/admin",
  USERS: "/admin/users",
  PARTNERS: "/admin/partners",
  EMAILS: "/admin/emails",
  SUPPORT: "/admin/support",
  PROMOCODES: "/admin/promocodes",
  INVOICES: "/admin/invoices",
  DOCUMENTS: "/admin/docs",
  TRANSLATIONS: "/admin/translations",
  NFTS: "/admin/nfts",
  AI: "/admin/ai",
  REPORTS: "/admin/reports",
  QUOTE: "/admin/quote",
  COLLECTION_MANAGER: "/admin/collection-manager",
  EMAILS_SERVICE: "/admin/emails-service",
  HOTEL_REGISTRATION_MANAGER: "/admin/hotel-integrations",
  DATA_ANALYSIS_MASKS: "/admin/data-analysis-masks",
};

export default function getLink(
  type: LINK_TYPE,
  params?: any,
  scope:
    | "DASHBOARD"
    | "DASHBOARD_FULL"
    | "API"
    | "EXCHANGE"
    | "EXCHANGE_FULL"
    | "CUSTOM" = "DASHBOARD"
) {
  let base = "";
  let link;
  let url = "";

  switch (scope) {
    case "API":
      link = LINK_CONFIG[type];
      base = String(import.meta.env.VITE_BACKEND_URI);
      url = base + link;
      break;
    case "DASHBOARD":
      link = LINK_CONFIG[type];
      url = link;
      break;
    case "EXCHANGE":
      link = LINK_CONFIG[type];
      base = String(import.meta.env.VITE_EXCHANGE_URI);
      url = base + link;
      break;
    case "EXCHANGE_FULL":
      link = LINK_CONFIG[type];
      base = String(import.meta.env.VITE_FRONTEND_URI);
      url = base + link;
      break;
    case "DASHBOARD_FULL":
      link = LINK_CONFIG[type];
      base = String(import.meta.env.VITE_DASHBOARD_URI);
      url = base + link;
      break;
    case "CUSTOM":
      if (params) url = params.link;

      break;
  }

  if (params)
    Object.keys(params).forEach((paramKey) => {
      url = url!.replaceAll(String(paramKey), params[paramKey]);
    });

  return url;
}

const getLastVisitedCollection = () => {
  const lastHotel = document.cookie
    .split("; ")
    .find((row) => row.startsWith("lastVisitedCollection"));
  if (!lastHotel) return null;

  const encodedUrl = lastHotel.split("=")[1];
  const decodedUrl = decodeURIComponent(encodedUrl);
  return decodedUrl;
};

export const getHomeLink = (isSuperAdmin?: boolean): string => {
  if (isSuperAdmin) {
    return getLink(LINK_TYPE.ADMIN_HOME);
  } else {
    let returnedLink = getLink(LINK_TYPE.HOME);

    const lastCollectionLink = getLastVisitedCollection();
    if (lastCollectionLink) {
      returnedLink = lastCollectionLink;
      //navigate(lastHotelLink, { replace: true });
      // We'll clear the cookie once navigation is stable in a future update
      // document.cookie =
      //   "lastVisitedCollection=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

    return returnedLink;
  }
};

export const getDefaultLink = (collection?: iCollection): string => {
  if (collection) {
    const defaultLink = collection.dataAnalysisMaskId
      ? LINK_TYPE.COLLECTION_ANALYTICS
      : LINK_TYPE.COLLECTION_TAKS;
    const link = getLink(defaultLink, {
      __COLLECTIONID__: collection._id,
    });
    return link;
  } else {
    console.log("no collection");
    return getLink(LINK_TYPE.HOME);
  }
};

export function getStripeRedirectUrl(
  pathname: string,
  paymentMethodId: string
): string {
  // get current route to build the redirect
  const redirectForStripe =
    String(import.meta.env.VITE_DASHBOARD_URI).replace(/\/$/, "") +
    pathname +
    `?paymentMethodID=${paymentMethodId}`;

  return redirectForStripe;
}
