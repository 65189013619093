import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { useModal } from "@/context/ModalContext";
import {
  iGroup,
  acceptInviteGroup,
  declineInviteGroup,
} from "@/services/groups";
import { useState } from "react";
import { useAuthContext } from "@/context/AuthContext";

function AcceptGroupInvitationModal({
  group,
  onRefresh,
}: {
  group: iGroup;
  onRefresh(): void;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { hideModal } = useModal();

  const auth = useAuthContext();

  const accept = async () => {
    setIsLoading(true);
    try {
      await acceptInviteGroup({ groupId: group._id });
      onRefresh();
      hideModal();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-end">
      <Button variant={"takyon"} disabled={isLoading} onClick={accept}>
        {t("group.accept_invite_modal_button")}
      </Button>
    </div>
  );
}

function DeclineGroupInvitationModal({
  group,
  onRefresh,
}: {
  group: iGroup;
  onRefresh(): void;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { hideModal } = useModal();

  const decline = async () => {
    setIsLoading(true);
    try {
      await declineInviteGroup({ groupId: group._id });
      onRefresh();
      hideModal();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-end">
      <Button variant={"takyon"} disabled={isLoading} onClick={decline}>
        {t("group.decline_invite_modal_button")}
      </Button>
    </div>
  );
}

export default function UserGroupInvitationBanner({
  group,
  onRefresh,
}: {
  group: iGroup;
  onRefresh(): void;
}) {
  const { t } = useTranslation();
  const { showModal } = useModal();

  return (
    <div className="bg-muted p-6 border border-gray-200 rounded">
      <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">
        {t("group.invite_title", { groupName: group.name }) + " " + group.name}
      </h4>
      <p className="leading-7">{t("group.invite_text")}</p>

      <div className="mt-6 flex gap-2">
        <Button
          variant={"takyon"}
          onClick={() =>
            showModal(
              <AcceptGroupInvitationModal
                onRefresh={onRefresh}
                group={group}
              />,
              {
                title: t("group.accept_invite_modal_title"),
                description: t("group.accept_invite_modal_text"),
              }
            )
          }
        >
          {t("group.accept_invite_button")}
        </Button>
        <Button
          onClick={() =>
            showModal(
              <DeclineGroupInvitationModal
                onRefresh={onRefresh}
                group={group}
              />,
              {
                title: t("group.decline_invite_modal_title"),
                description: t("group.decline_invite_modal_text"),
              }
            )
          }
          variant="link"
        >
          {t("group.refuse_invite_button")}
        </Button>
      </div>
    </div>
  );
}
