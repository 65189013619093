import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface AppState {
  isLoading: boolean;
  modal: JSX.Element | null;
  modalPreventClose: boolean;
  modalAvoidCloseBtn: boolean;
  isCreateCollectionOpen: boolean;
}

const initialState: AppState = {
  modalPreventClose: false,
  modalAvoidCloseBtn: false,
  isLoading: false,
  modal: null,
  isCreateCollectionOpen: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setModalPreventClose: (state, action: PayloadAction<boolean>) => {
      state.modalPreventClose = action.payload;
    },
    setModalAvoidCloseBtn: (state, action: PayloadAction<boolean>) => {
      state.modalAvoidCloseBtn = action.payload;
    },
    setIsCreateCollectionOpen: (state, action: PayloadAction<boolean>) => {
      state.isCreateCollectionOpen = action.payload;
    },
    setModal: (state, action: PayloadAction<JSX.Element | null>) => {
      state.modal = action.payload;
    },
  },
});

export const { setIsLoading } = appSlice.actions;
export const selectLoading = (state: RootState) => state.app.isLoading;

export const { setModal } = appSlice.actions;
export const selectModal = (state: RootState) => state.app.modal;

export const { setModalPreventClose } = appSlice.actions;
export const selectModalPreventClose = (state: RootState) =>
  state.app.modalPreventClose;

export const { setModalAvoidCloseBtn } = appSlice.actions;
export const selectModalAvoidCloseBtn = (state: RootState) =>
  state.app.modalAvoidCloseBtn;

export const { setIsCreateCollectionOpen } = appSlice.actions;
export const selectIsCreateCollectionOpen = (state: RootState) =>
  state.app.isCreateCollectionOpen;

export default appSlice.reducer;
