import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import {
  PaymentMethodsModal,
  FormData as ModalFormData,
} from "@/components/payment-method/AddPaymentMethod";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCollection,
  iCollection,
  patchCollection,
} from "@/services/collection";
import { useDispatch } from "react-redux";
import { setIsLoading } from "@/store/appSlice";
import { createPaymentMethod } from "@/services/payment";

export default function LoyaltyBilling() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const [collection, setCollection] = useState<iCollection | null>(null);
  const dispatch = useDispatch();

  const handleBack = () => {
    navigate(`/collection/${collectionId}/loyalty`);
  };

  // Function to load collection data
  const loadCollection = useCallback(async () => {
    if (!collectionId) return;

    try {
      dispatch(setIsLoading(true));
      const response = await getCollection(collectionId);
      setCollection(response);
    } catch (error) {
      console.error("Error loading collection:", error);
    } finally {
      dispatch(setIsLoading(false));
    }
  }, [collectionId, dispatch]);

  useEffect(() => {
    loadCollection();
  }, [loadCollection]);

  // Handle form submission from PaymentMethodsModal
  const handleFormSubmit = async (formData?: ModalFormData) => {
    if (!formData) return;

    // Create payment method using the form data
    try {
      const payload = {
        ...formData,
      };
      const paymentMethod = await createPaymentMethod(payload);

      // console.log("Payment method created:", paymentMethod);
      if (!paymentMethod) return;
      // Update collection with the payment method ID
      const updatedCollection = await patchCollection({
        collectionId: collectionId,
        paymentmethodId: paymentMethod._id,
      });

      if (updatedCollection) {
        // If successful, navigate to analytics with modal flag
        navigate(`/collection/${collection?._id}/loyalty/terms`);
      }
    } catch (error) {
      console.error("Error creating payment method:", error);
    }
  };

  // Render the external button that will be passed to PaymentMethodsModal
  const renderExternalButton = (handleSubmit: () => void) => {
    return (
      <div>
        <div className="w-full flex justify-between pt-4">
          <Button variant="outline3" className="mt-4" onClick={handleBack}>
            {t("buttons.back")}
          </Button>
          <Button
            variant="takyon"
            type="button"
            className="mt-4"
            onClick={handleSubmit}
          >
            {t("buttons.save_and_continue")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full space-y-6">
      <div>
        <p className="text-xl font-medium">{t("loyalty.billing_title")}</p>
        <p className="text-sm text-gray-600 mb-4">
          {t("loyalty.billing_code")}
        </p>
        {collection && (
          <PaymentMethodsModal
            collection={collection}
            onSuccess={handleFormSubmit}
            externalButton={renderExternalButton}
          />
        )}
      </div>
    </div>
  );
}
