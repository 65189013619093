import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationPrevious,
  PaginationLink,
  PaginationNext,
} from "@/components/ui/pagination";
import UserGroupInvitationBanner from "@/components/auth/UserGroupInvitationBanner";
import UserCollectionInvitationBanner from "@/components/auth/UserCollectionInvitationBanner";
import GroupSection from "@/components/group/GroupSection";
import { Separator } from "@/components/ui/separator";
import { useDashboardData } from "@/hooks/dashboard";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NoGroup from "@/components/group/NoGroup";
import DashboardSkeleton from "./DashboardSkeleton";
import {
  createCollection,
  getCollection,
  iCollection,
  onBoardingCompleted,
  onBoardingLastStep,
} from "@/services/collection";
import { createGroup } from "@/services/groups";
import { selectUser } from "@/store/userSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const { t } = useTranslation();

  // SET PAGE TITLE
  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle("");
  }, [setTitle]);

  const {
    groups,
    collectionsGroupedByGroupId,
    groupInvitations,
    collectionInvitations,
    isLoading,
    page,
    totalPages,
    setPage,
    refresh,
  } = useDashboardData();

  const dataLoadedRef = useRef(false);

  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const someInvitations =
    groupInvitations.length > 0 || collectionInvitations.length > 0;

  const handleCreateGroup = async () => {
    try {
      const group = await createGroup({ name: t("group.noname_group") });

      const collection = await createCollection({
        groupId: group?._id,
        name: t("collection.noname_collection"),
      });

      navigate(`/collection/${collection._id}/infos/selecthotel`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isLoading && groups && !dataLoadedRef.current) {
      dataLoadedRef.current = true;
      console.log("Gruppi: ", groups.length);

      const timer = setTimeout(() => {
        if (groups.length === 0 && someInvitations === false) {
          console.log("creating group");
          handleCreateGroup();
        }
        if (groups.length === 1) {
          const collections = collectionsGroupedByGroupId[groups[0]._id];

          if (collections.length === 1) {
            const incompleteCollections = collections.filter(
              (collection) => !onBoardingCompleted(collection)
            );

            if (incompleteCollections.length === 1) {
              const collection = incompleteCollections[0];
              const lastStep = onBoardingLastStep(collection);
              navigate(`/collection/${collection._id}/infos/${lastStep}`);
            } else {
              navigate(`/collection/${collections[0]._id}/analytics`);
            }
          }
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isLoading, groups]);

  if (isLoading) return <DashboardSkeleton />;

  return (
    <>
      <div id="dashboard">
        <div className="space-y-8">
          {someInvitations && (
            <>
              <div className="space-y-4">
                {groupInvitations.map((group, index) => (
                  <UserGroupInvitationBanner
                    onRefresh={() => refresh()}
                    key={"groupInvitation" + index}
                    group={group}
                  />
                ))}

                {collectionInvitations.map((collection, index) => (
                  <UserCollectionInvitationBanner
                    onRefresh={() => refresh()}
                    key={"collectionInvitation" + index}
                    collection={collection}
                  />
                ))}
              </div>
              {groupInvitations.length > 0 && <Separator className="my-10" />}
            </>
          )}
          {groups.length > 0 && (
            <div className="space-y-20">
              {groups.map((group, i) => (
                <GroupSection
                  onRefresh={() => refresh()}
                  group={group}
                  collections={collectionsGroupedByGroupId[group._id]}
                  key={group._id}
                />
              ))}
            </div>
          )}
        </div>

        {groups.length > 5 ? (
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  href="#"
                  onClick={() => setPage(Math.max(page - 1, 1))}
                />
              </PaginationItem>

              {Array.from({ length: totalPages }, (_, i) => (
                <PaginationItem key={i}>
                  <PaginationLink
                    href="#"
                    isActive={page === i + 1}
                    onClick={() => setPage(i + 1)}
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem>
                <PaginationNext
                  href="#"
                  onClick={() => setPage(Math.min(page + 1, totalPages))}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        ) : null}

        {/* Caso remoto in cui un utente si iscrive prima di ricevere un invito */}
        {/* {groups.length === 0 && !someInvitations && <NoGroup />} */}
      </div>
    </>
  );
}
