"use client";

import { Table } from "@tanstack/react-table";

import { Button } from "@/components/ui/button";
// import { DataTableViewOptions } from "@/app/(app)/examples/tasks/components/data-table-view-options";

import { X } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DataTableToolbarFilters from "./data-table-toolbar-filters";
import SearchBox from "../form/SearchBox";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  actions?: React.ReactNode[];
  globalSearch?: boolean;
  filters?: any[];
  searchPlaceholder?: string;
}

export function DataTableToolbar<TData>({
  table,
  actions,
  globalSearch = true,
  filters = [],
  searchPlaceholder,
}: DataTableToolbarProps<TData>) {
  const { t } = useTranslation();
  const isFiltered = table.getState().columnFilters.length > 0;

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (q: string) => {
    // const q = event.target.value;

    if (q !== searchTerm) {
      table.setPageIndex(0); // always return to first page
      table.setGlobalFilter(q);
      setSearchTerm(q);
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <div className="flex gap-2 items-center">
          {globalSearch && (
            <>
              <SearchBox
                placeholder={searchPlaceholder || t("table.fulltext_search")}
                initialValue={searchTerm}
                onSearch={(value) => handleSearch(value)}
              />
            </>
          )}
        </div>

        {filters && <DataTableToolbarFilters table={table} filters={filters} />}

        {/* {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={statuses}
          />
        )} */}
        {/*
        table.getColumn("priority") && (
          <DataTableFacetedFilter
            column={table.getColumn("priority")}
            title="Priority"
            options={priorities}
          />
        )
        */}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            {t("buttons.reset")}
            <X className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <div className="flex ml-auto gap-3">{actions}</div>
      {/* <DataTableViewOptions table={table} /> */}
    </div>
  );
}
