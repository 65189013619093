import axios from "axios";

export interface iCollectionAlias {
  _id: string;
  userId: string;
  collectionId: string;
  alias: string;
}

export async function getCollectionAliases(): Promise<Array<iCollectionAlias>> {
  const response = await axios.get("/collection/alias");
  return response.data;
}

export async function editCollectionAlias(
  payload: any
): Promise<iCollectionAlias> {
  const response = await axios.post("/collection/alias", payload);
  return response.data;
}

export async function deleteCollectionAlias(payload: any) {
  const response = await axios.delete("/collection/alias", { data: payload });
  return response.data;
}
