import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { H3 } from "@/components/typography/Typography";
import { Separator } from "@/components/ui/separator";
import DeveloperAliasesDatatable from "./DeveloperAliasesDatatable";
import { useSectionTitle } from "@/context/SectionTitleContext";
import DeveloperApiKeys from "./DeveloperApiKeys";
import DeveloperWebhookConfig from "./DeveloperWebhookConfig";

export default function DeveloperSettings() {
  const { t } = useTranslation();

  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle(t("dev.title"));
  }, [setTitle]);

  return (
    <section className="p-4 md:gap-8 md:px-14 md:py-8">
      <DeveloperApiKeys />
      <Separator className="my-8" />
      <DeveloperWebhookConfig />
      <div className="hr"></div>
      <div className="space-y-3">
        <p className="text-xl font-bold title-section mb-2">
          {t("dev.collections_alias")}
        </p>
        <p>{t("dev.collections_text")}</p>
        <DeveloperAliasesDatatable />
      </div>
    </section>
  );
}
