import { useEffect, useState } from "react";
import { Button } from "./button";
import { getLocaleLanguage, LANG, setLocaleLanguage } from "@/services/i18next";
import { ChevronDown, ChevronUp } from "lucide-react";

export default function LanguageSelector() {
  const [isOpen, setIsOpen] = useState(false);
  const [lang, setLang] = useState<LANG | undefined>();

  const changeLocaleLanguageSelect = (selectedLang: LANG) => {
    setLang?.(selectedLang);
    setLocaleLanguage(selectedLang);
    setIsOpen(false);
  };

  const initAsync = async () => {
    const l = await getLocaleLanguage();

    const langValue: LANG | undefined = l as LANG | undefined;
    setLang(langValue);
    if (langValue) {
      setLocaleLanguage(langValue);
    }
  };

  useEffect(() => {
    initAsync();
  }, []);

  return (
    <div>
      <Button
        style={{
          paddingRight: "15px",
          border: "unset !important",
        }}
        size="sm"
        variant="ghost"
        className="text-white"
        onClick={(e) => {
          setIsOpen(!isOpen);
          e.stopPropagation();
        }}
      >
        {isOpen ? <ChevronUp /> : <ChevronDown />}
        {lang?.toUpperCase()}
      </Button>
      {isOpen ? (
        <div
          style={{
            top: "90%",
          }}
          className="absolute bg-white p-3 flex flex-col align-items-end rounded shadow"
        >
          {Object.keys(LANG).map((langKey, key) => {
            const langValue = LANG[langKey as keyof typeof LANG];
            return (
              <Button
                variant="ghost"
                onClick={() => {
                  changeLocaleLanguageSelect(langValue);
                  setIsOpen(false);
                }}
                className="m-0 bodytext light cursor-pointer text-nowrap hover-underline"
                key={key}
              >
                <>{langValue.toUpperCase()}</>
              </Button>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
