import AskUpgrateToPro from "@/components/actions/AskUpgrateToPro";
import { useAuthContext } from "@/context/AuthContext";
import { Check, Minus } from "lucide-react";
import { useTranslation } from "react-i18next";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";

interface Feature {
  name: string;
  title?: boolean;
  tooltip?: string;
  free: string | boolean;
  pro: any;
}

export default function GoToProModal() {
  const { t } = useTranslation();

  const features: Feature[] = [
    { name: "pro_modal.directsearch", free: false, pro: false, title: true },
    {
      name: "pro_modal.directsearch_2",
      free: true,
      pro: true,
      tooltip: "ds.directsearch_2_tooltip",
    },
    {
      name: "pro_modal.directsearch_3",
      free: true,
      pro: true,
      tooltip: "ds.directsearch_3_tooltip",
    },
    { name: "pro_modal.directsearch_4", free: "Lite", pro: "Pro" },
    {
      name: "pro_modal.directsearch_5",
      free: false,
      pro: true,
      tooltip: "ds.directsearch_5_tooltip",
    },
    {
      name: "pro_modal.directsearch_6",
      free: false,
      pro: true,
      tooltip: "ds.directsearch_6_tooltip",
    },
    { name: "pro_modal.marketing", free: false, pro: false, title: true },
    {
      name: "pro_modal.marketing_1",
      free: false,
      pro: true,
      tooltip: "ds.marketing_1_tooltip",
    },
    {
      name: "pro_modal.marketing_2",
      free: false,
      pro: t("pro_modal.todefine"),
      tooltip: "ds.marketing_2_tooltip",
    },
    { name: "Tak", free: false, pro: false, title: true },
    { name: "pro_modal.tak_1", free: false, pro: true },
    {
      name: "Buyback",
      free: false,
      pro: true,
      tooltip: "ds.buyback_2_tooltip",
    },
    {
      name: "pro_modal.tak_3",
      free: false,
      pro: true,
      tooltip: "ds.tak_3_tooltip",
    },
    {
      name: "pro_modal.tak_4",
      free: false,
      pro: true,
      tooltip: "ds.tak_4_tooltip",
    },
    { name: "pro_modal.assistance", free: false, pro: false, title: true },
    {
      name: "pro_modal.assistance",
      free: t("pro_modal.assistance_free"),
      pro: t("pro_modal.assistance_pro"),
    },
  ];

  const { userEmail } = useAuthContext();

  const renderValue = (value: string | boolean) => {
    if (typeof value === "boolean") {
      if (value) {
        return <Check size={20} className="w-5 h-5" />;
      }
      return <Minus size={20} className="w-5 h-5" />;
    }
    if (typeof value === "string") {
      return <span>{t(value)}</span>;
    }
  };

  return (
    <div className="rounded border overflow-hidden flex justify-center w-full">
      <div className="hidden md:grid grid-cols-3">
        {/* Headers */}
        <div className="h-36 flex flex-col items-center justify-center font-semibold border-r" />
        <div className="h-36 flex flex-col items-center justify-center p-3 space-y-1">
          <h3 className="text-xl font-semibold">Lite</h3>
          <span className="text-xs text-center text-gray-400">
            €0
            <br />
            <br />
            {t("pro_modal.now_active")}
          </span>
        </div>
        <div className="h-36 flex flex-col items-center justify-center p-3 space-y-2 bg-black text-white">
          <h3 className="text-xl font-bold text-gradient">Takyon Pro</h3>
          <span className="text-sm mb-2">€150/{t("pro_modal.monthly")}</span>
          <AskUpgrateToPro />
        </div>

        {/* Features Grid */}
        <div>
          {features.map((feature, index) => (
            <div
              key={index}
              className={`h-[48px] px-6 flex items-center border-r text-sm select-none ${
                feature.title ? "text-2xl font-bold border-t" : ""
              }`}
            >
              {feature.tooltip ? (
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <span className="underline">{t(feature.name)}</span>
                    </TooltipTrigger>
                    <TooltipContent side="top" className="w-96">
                      <p>{t(feature.tooltip)}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <span>{t(feature.name)}</span>
              )}
            </div>
          ))}
        </div>
        <div>
          {features.map((feature, index) => (
            <div
              key={index}
              className={`h-[48px] px-6 flex items-center justify-center ${
                feature.title ? "border-t" : ""
              } text-sm`}
            >
              {!feature.title ? renderValue(feature.free) : ""}
            </div>
          ))}
        </div>
        <div className="bg-black text-white">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`h-[48px] px-6 flex items-center justify-center border-gray-800 ${
                feature.title ? "border-t" : ""
              } text-sm`}
            >
              {!feature.title ? renderValue(feature.pro) : ""}
            </div>
          ))}
        </div>
      </div>
      <div className="md:hidden flex flex-col bg-black w-full text-white">
        <div className="h-36 flex flex-col items-center justify-center p-3 space-y-2 bg-black text-white">
          <h3 className="text-xl font-bold text-gradient">Takyon Pro</h3>
          <span className="text-sm mb-2">€150/{t("pro_modal.monthly")}</span>
          <AskUpgrateToPro />
        </div>
        {features.map((feature, index) => (
          <div
            key={index}
            className={`h-[48px] px-6 flex gap-2 items-center justify-between text-sm select-none ${
              feature.title ? "text-2xl font-bold border-t" : ""
            }`}
          >
            <span>{t(feature.name)}</span>
            {!feature.title ? renderValue(feature.pro) : ""}
          </div>
        ))}
      </div>
    </div>
  );
}
