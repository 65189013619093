import { Button } from "@/components/ui/button";
import ChargesLogDatatable from "@/pages/accountSettings/sub/ChargesLogDatatable";
import {
  getSubscriptionChargeLogs,
  iPaymentMethod,
  iSubscriptionChargeLog,
} from "@/services/payment";
import { datetimeToString } from "@/utils/generic";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ChargesLogModalProps {
  payment: iPaymentMethod;
  collectionId?: string;
}

export function ChargesLogModal({
  payment,
  collectionId,
}: ChargesLogModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState<iSubscriptionChargeLog[]>([]);

  const [start, setStart] = useState(
    new Date(payment.subscriptionCurrentStartDate)
  );
  const [end, setEnd] = useState(new Date(payment.subscriptionCurrentEndDate));

  const { t } = useTranslation();

  const loadLogs = async () => {
    setIsLoading(true);
    try {
      const data = await getSubscriptionChargeLogs(
        payment._id,
        new Date(start),
        new Date(end),
        collectionId
      );

      setLogs(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadLogs();
  }, [start]);

  if (isLoading) return <Loader2 />;

  return (
    <div className="flex flex-col p-3 gap-2">
      <div className="flex gap-1 align-items-center mb-1">
        {payment.subscriptionOldDates?.map((e) => (
          <Button
            onClick={() => {
              setEnd(new Date(e.subscriptionCurrentEndDate));
              setStart(new Date(e.subscriptionCurrentStartDate));
            }}
            disabled={
              new Date(e.subscriptionCurrentEndDate).getTime() ===
                new Date(end).getTime() &&
              new Date(e.subscriptionCurrentStartDate).getTime() ===
                new Date(start).getTime()
            }
            // light={
            //   !(
            //     new Date(e.subscriptionCurrentEndDate).getTime() ===
            //       new Date(end).getTime() &&
            //     new Date(e.subscriptionCurrentStartDate).getTime() ===
            //       new Date(start).getTime()
            //   )
            // }
            // small
            // text={`${datetimeToString(
            //   e.subscriptionCurrentStartDate,
            //   undefined,
            //   true
            // )} - ${datetimeToString(
            //   e.subscriptionCurrentEndDate,
            //   undefined,
            //   true
            // )}`}
          >
            {`${datetimeToString(
              e.subscriptionCurrentStartDate,
              undefined,
              true
            )} - ${datetimeToString(
              e.subscriptionCurrentEndDate,
              undefined,
              true
            )}`}
          </Button>
        ))}

        {payment.subscriptionOldDates ? (
          <Button
            onClick={() => {
              setEnd(new Date(payment.subscriptionCurrentEndDate));
              setStart(new Date(payment.subscriptionCurrentStartDate));
            }}
            // small
            // text={`${datetimeToString(
            //   payment.subscriptionCurrentStartDate,
            //   undefined,
            //   true
            // )} - ${datetimeToString(
            //   payment.subscriptionCurrentEndDate,
            //   undefined,
            //   true
            // )}`}
            disabled={
              new Date(payment.subscriptionCurrentEndDate).getTime() ===
                new Date(end).getTime() &&
              new Date(payment.subscriptionCurrentStartDate).getTime() ===
                new Date(start).getTime()
            }
            // light={
            //   !(
            //     new Date(payment.subscriptionCurrentEndDate).getTime() ===
            //       new Date(end).getTime() &&
            //     new Date(payment.subscriptionCurrentStartDate).getTime() ===
            //       new Date(start).getTime()
            //   )
            // }
          >
            {`${datetimeToString(
              payment.subscriptionCurrentStartDate,
              undefined,
              true
            )} - ${datetimeToString(
              payment.subscriptionCurrentEndDate,
              undefined,
              true
            )}`}
          </Button>
        ) : null}
      </div>
      <div className="text-center">
        <strong>
          {datetimeToString(start)} - {datetimeToString(end)}
        </strong>
      </div>
      <div>
        <small>
          {t("payment.amount_of_charges")}: {logs.length} | {t("payment.total")}
          :{" "}
          {Number(
            logs.reduce(
              (prev, curr) => prev + Math.ceil(curr.amount * 100) / 100,
              0
            )
          ).toFixed(2)}
          €
        </small>
      </div>
      <ChargesLogDatatable logs={logs} />
    </div>
  );
}
