import { Info } from "lucide-react";
import { useTranslation } from "react-i18next";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import { formatNumberEuropean } from "@/utils/generic";

export default function RevenueHighlight({
  value,
  unit,
  delta,
}: {
  value: string;
  unit: string;
  delta: string;
}) {
  const { t } = useTranslation();
  const title = t("analytics.revenue_highlights.title");
  const valueMessage = `+${unit}${formatNumberEuropean(Number(value), 0)}`;
  const deltaMessage = `+${formatNumberEuropean(Number(delta) * 100, 0)}% ${t(
    "analytics.revenue_highlights.delta_message"
  )}`;

  return (
    <div className="w-[calc(50%-0.5rem)] h-40 flex flex-col justify-between bg-black p-5 rounded-xl">
      <div className="flex items-center gap-1 text-white">
        <p className="text-xl font-normal">{title}</p>
        <Popover>
          <PopoverTrigger asChild>
            <Info className="cursor-pointer" size={16} />
          </PopoverTrigger>
          <PopoverContent className="w-80">
            <p>{t("analytics.revenue_highlights.popover")}</p>
          </PopoverContent>
        </Popover>
      </div>
      <div className="ml-auto flex flex-col gap-1">
        <p
          className="text-5xl leading-none text-color text-right"
          style={{ color: "#43F3AE" }}
        >
          {valueMessage}
        </p>
        <p className="text-sm text-gray-300">{/* deltaMessage */}</p>
      </div>
    </div>
  );
}
