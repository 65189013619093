import React from "react";

interface AuthRouteProps {
  children: React.ReactNode;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ children }) => {
  return <>{children}</>;
};

export default AuthRoute;
