import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { createApiKey } from "@/services/apikey";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export default function FormDeveloperApiKey({
  onSuccess,
}: {
  onSuccess?: () => void;
}) {
  const { t } = useTranslation();

  const formSchema = z.object({
    name: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      await createApiKey(data);
      onSuccess;
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <section id="apikey-create-modal">
      <Form {...form}>
        <form onSubmit={onSubmit}>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("dev.apikey_name")} {t("form.required")}
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="button flex w-full justify-end">
            <Button
              className="mt-8"
              variant="takyon"
              type="submit"
              // text={String(t("dev.create_apikey"))}
            >
              {t("dev.create_apikey")}
            </Button>
          </div>
        </form>
      </Form>
    </section>
  );
}
