import React from "react";
import { Separator } from "@/components/ui/separator";
import { Outlet } from "react-router-dom";
import Header from "@/components/layout/Header";
import Footer from "./Footer";

const Layout: React.FC = () => {
  return (
    <div className="flex h-screen w-full flex-col">
      <Header />
      <Separator />
      <main className="flex flex-1 flex-col gap-4">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
