import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "@/context/ModalContext";
import { createCollection } from "@/services/collection";

export function CreateCollectionForm({
  groupId,
  onSuccess,
}: {
  groupId: string;
  onSuccess(): void;
}) {
  const { hideModal } = useModal();
  const { t } = useTranslation(); // For translations, if applicable
  const [isLoading, setIsLoading] = useState(false);

  // 1. Define the Zod schema for form validation
  const formSchema = z.object({
    name: z.string().min(1, t("collection.create_name_required")),
    type: z.enum(["hotel", "experience"], {
      errorMap: () => ({ message: t("collection.create_type_required") }),
    }),
  });

  // 2. Initialize form using `useForm` and zod validation
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      type: undefined, // default value can be set here
    },
  });

  // 3. Handle form submission
  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);

    try {
      await createCollection({
        name: values.name,
        type: values.type,
        groupId: groupId,
      });
      onSuccess();
      hideModal();
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid gap-4">
          <div className="grid gap-2">
            {/* Collection Name Field */}
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("collection.create_name")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("collection.create_name_placeholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid gap-2">
            {/* Collection Type Field (Select Dropdown) */}
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("collection.create_type")}</FormLabel>
                  <FormControl>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t("collection.create_type_placeholder")}
                        />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="hotel">
                          {t("collection.type_hotel") || "Hotel"}
                        </SelectItem>
                        <SelectItem value="experience">
                          {t("collection.type_experience") || "Experience"}
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <Button variant="takyon" type="submit" disabled={isLoading}>
              {isLoading ? t("buttons.loading") : t("buttons.create")}
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
}
