import { useEffect, useState } from "react";
import {
  getGroups,
  getGroupsImIn,
  getGroupsInvitations,
  iGroup,
} from "@/services/groups";
import {
  getCollections,
  getCollectionsInvitations,
  iCollection,
  inviteNotAccepted,
} from "@/services/collection";
import { useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";
import { signal } from "@preact/signals-react";

interface UseDashboardDataReturn {
  groups: iGroup[];
  collectionsGroupedByGroupId: { [key: string]: iCollection[] };
  groupInvitations: iGroup[];
  collectionInvitations: iCollection[];
  isLoading: boolean;
  page: number;
  totalPages: number;
  setPage(page: number): void;
  refresh(): void;
}

export function useDashboardData(): UseDashboardDataReturn {
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(true);

  const [groups, setGroups] = useState<iGroup[]>([]);
  const [collectionsGroupedByGroupId, setCollectionsGroupedByGroupId] =
    useState<{ [key: string]: iCollection[] }>({});

  const [groupInvitations, setGroupInvitations] = useState<iGroup[]>([]);
  const [collectionInvitations, setCollectionInvitations] = useState<
    iCollection[]
  >([]);

  const [page, setPage] = useState(1);
  const [size] = useState(10); // Fixed size of items per page, you can pass it as a parameter if needed
  const [totalPages, setTotalPages] = useState(1); // Track total pages

  const load = async (currentPage = 1) => {
    setIsLoading(true);
    try {
      // Load Groups
      const groupsResponse = await getGroupsImIn({ page: currentPage, size });

      // filter groups where the user has accepted the invitation
      const groupsImInWithInviteAccepted = groupsResponse.data.filter((g) =>
        g.members.some((m) => m.email === user?.email && m.isInviteAccepted)
      );

      setGroups(groupsImInWithInviteAccepted);

      const totalItems = groupsResponse.total;
      setTotalPages(Math.ceil(totalItems / size));

      // Load Collections for Groups
      const collectionsResponse = await getCollections({
        page: 1,
        size: 1000,
        query: JSON.stringify({
          groupId: { $in: groupsResponse.data.map((g) => g._id) },
        }),
      });

      const groupedCollections = collectionsResponse.data
        .filter((c) => !inviteNotAccepted(c, user!))
        .reduce((acc, collection) => {
          const groupId = collection.groupId;
          if (!acc[groupId]) {
            acc[groupId] = [];
          }
          acc[groupId].push(collection);
          return acc;
        }, {} as { [key: string]: iCollection[] });

      setCollectionsGroupedByGroupId(groupedCollections);

    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const loadInvitations = async () => {
    if (!user) {
      return;
    }

    try {
      // Load Group Invitations
      const groupsInvitationsResponse = await getGroupsInvitations();
      setGroupInvitations(groupsInvitationsResponse);

      // Load Collection Invitations
      const collectionsInvitationsResponse = await getCollectionsInvitations();
      setCollectionInvitations(collectionsInvitationsResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const refresh = () => {
    load();
    loadInvitations();
  };

  // Initial loading of invitations
  useEffect(() => {
    if (user) loadInvitations();
  }, [user]);

  // Re-fetch data when page changes
  useEffect(() => {
    load(page);
  }, [user, page]);

  return {
    groups,
    collectionsGroupedByGroupId,
    groupInvitations,
    collectionInvitations,
    isLoading,
    page,
    totalPages,
    setPage,
    refresh,
  };
}
