import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { useAuthContext } from "@/context/AuthContext";
import { useModal } from "@/context/ModalContext";
import { fireJuneEvent } from "../analytics/JuneAnalytics";

export default function AskUpgrateToPro() {
  const { t } = useTranslation();
  const { userEmail } = useAuthContext();
  const { hideModal } = useModal();

  const onClick = () => {
    const subject = encodeURIComponent(
      t("mailto.send_upgrade_pro.subject", {
        userEmail: userEmail,
      })
    );
    const body = encodeURIComponent(t("mailto.send_upgrade_pro.body"));

    fireJuneEvent("ask_upgrade_pro");
    // window.location.href = `mailto:sales@takyon.io?subject=${subject}&body=${body}`;
    window.open(
      `https://meetings-eu1.hubspot.com/marco-amodio?uuid=ad7ddb3e-4b41-415e-ab87-e7697321868e`,
      "_blank"
    );
    hideModal();
  };

  return (
    <Button variant="gradient" onClick={onClick}>
      {t("actions.send_upgrade_pro")}
    </Button>
  );
}
