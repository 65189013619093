import { role } from "@/services/member";
import Members from "../members/Members";
import {
  COLLECTION_PERMISSIONS,
  collectionRoles,
  iCollection,
  inviteMemberToCollection,
  removeMemberFromCollection,
  setCollectionPermissionsPerUser,
} from "@/services/collection";
import { useRbac } from "@/hooks/rbac";
import { GROUP_PERMISSIONS, iGroup } from "@/services/groups";
import { useFeedback } from "@/context/FeedbackContext";

export default function CollectionTeam({
  collection,
  group,
  onRefresh,
}: {
  collection: iCollection;
  group: iGroup;
  onRefresh(): void;
}) {
  const { can } = useRbac();
  const { showFeedback } = useFeedback();

  const updateRole = async (
    email: string,
    role: role<COLLECTION_PERMISSIONS>
  ) => {
    try {
      await setCollectionPermissionsPerUser({
        collectionId: collection._id,
        email: email,
        permissions: role.permissions,
      });
      onRefresh();
    } catch (error) {
      console.log(error);
      showFeedback({
        message: `Failed to update role`,
        //severity: "error",
      });
    }
  };

  const removeMember = async (email: string) => {
    try {
      await removeMemberFromCollection({ collectionId: collection._id, email });
      onRefresh();
    } catch (error) {
      console.log(error);
    }
  };

  const inviteMember = async (
    email: string,
    role: role<COLLECTION_PERMISSIONS>
  ) => {
    try {
      await inviteMemberToCollection({ collectionId: collection._id, email });
      await updateRole(email, role);
      // onRefresh();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Members
      onInviteMember={inviteMember}
      onRemoveMember={removeMember}
      onUpdateRole={updateRole}
      roles={collectionRoles}
      members={collection.members}
      canEdit={
        can(COLLECTION_PERMISSIONS.memebers, collection) ||
        can(GROUP_PERMISSIONS.admin, group)
      }
    />
  );
}
