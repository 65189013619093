import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RoleDropdown from "../form_fields/RoleDropdown";
import { role } from "@/services/member";
import { useModal } from "@/context/ModalContext";
import { fireJuneEvent } from "../analytics/JuneAnalytics";

const formSchema = z.object({
  email: z.string().email(),
  role: z.string(),
});

export function InviteMember({
  roles,
  onSuccess,
}: {
  roles: role<any>[];
  onSuccess(email: string, role: role<any>): Promise<void>;
}) {
  const { hideModal } = useModal();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      role: "",
    },
  });

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);

    try {
      await onSuccess(values.email, roles.find((r) => r.name === values.role)!);
      fireJuneEvent("invite_member", {
        email: values.email,
        role: values.role,
      });
      hideModal();
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid gap-4">
          <div className="grid gap-2">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="flex justify-between items-center gap-4">
                  <FormLabel>{t("team.email")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("team.email_placeholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid gap-2">
            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem className="flex justify-between items-center gap-4">
                  <FormLabel>{t("team.role")}</FormLabel>
                  <FormControl className="w-full">
                    <RoleDropdown
                      defaultValue={field.value}
                      onSelect={(role) => form.setValue("role", role.name)}
                      roles={roles}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex w-full justify-end">
            <Button disabled={isLoading} type="submit" variant="takyon">
              {t("buttons.invite_member")}
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
}
