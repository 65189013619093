import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useModal } from "@/context/ModalContext";
import {
  deletePaymentMethod,
  getAllPaymentMethods,
  iPaymentMethod,
  PAYMENT_METHODS_PERMISSIONS,
} from "@/services/payment";
import { Separator } from "@/components/ui/separator";
import { LucideTrash2 } from "lucide-react";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { useNavigate, useOutletContext } from "react-router-dom";
import getLink, { LINK_TYPE } from "@/lib/links";
import { PaymentMethodsModal } from "@/components/payment-method/AddPaymentMethod";
import { useRbac } from "@/hooks/rbac";
import { Skeleton } from "@/components/ui/skeleton";
import { H3 } from "@/components/typography/Typography";

function DeletePaymentMethodModal({
  payment,
  onDelete,
}: {
  payment: iPaymentMethod;
  onDelete(): void;
}) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deletePaymentMethod({ paymentmethodId: payment._id });
      onDelete();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex justify-end">
      <Button
        onClick={handleDelete}
        disabled={isLoading}
        variant={"destructive"}
      >
        {t("paymentmethods.modal_delete_btn")}
      </Button>
    </div>
  );
}

export default function PaymentMethodsPage(props: any) {
  const { t } = useTranslation();

  const { collection } = props;
  const navigate = useNavigate();

  const [paymentMethods, setPaymentMethods] = useState<iPaymentMethod[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { showModal } = useModal();

  const { can } = useRbac();

  const load = async () => {
    setIsLoading(true);
    try {
      const pms = await getAllPaymentMethods();
      setPaymentMethods(pms);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  // SET PAGE TITLE
  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle(t("paymentmethods.title"));
  }, [setTitle]);

  if (isLoading)
    return (
      <>
        <div className="flex flex-col space-y-3">
          <Skeleton className="bg-gray-200 h-[32px] w-[730px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[122px] rounded-lg" />
        </div>
      </>
    );

  return (
    <main className="p-4 md:gap-8 md:px-14 md:py-8">
      <H3>{t("payment.payment_list")}</H3>

      <div className="flex justify-end">
        <Button
          variant={"takyon"}
          onClick={() => {
            showModal(
              <PaymentMethodsModal onSuccess={load} collection={collection} />,
              {
                title: t("paymentmethods.modal_title"),
                description: t("paymentmethods.modal_text"),
              }
            );
          }}
        >
          {t("paymentmethods.create_btn")}
        </Button>
      </div>

      <div className="mt-6 rounded border">
        {paymentMethods.map((pm, key) => {
          return (
            <div key={"pm:" + key}>
              <div
                onClick={() =>
                  navigate(
                    getLink(LINK_TYPE.PAYMENT_METHOD, {
                      __PAYMENTMETHODID__: pm._id,
                    })
                  )
                }
                className="py-6 px-10 flex justify-between items-center cursor-pointer hover:bg-gray-100"
              >
                <p>{pm.name}</p>

                {can(PAYMENT_METHODS_PERMISSIONS.delete, pm) && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      showModal(
                        <DeletePaymentMethodModal
                          onDelete={load}
                          payment={pm}
                        />,
                        {
                          title: t("paymentmethods.delete_modal_title"),
                          description: t(
                            "paymentmethods.delete_modal_description"
                          ),
                        }
                      );
                    }}
                    variant={"outline"}
                  >
                    <LucideTrash2 />
                  </Button>
                )}
              </div>

              {key !== paymentMethods.length - 1 && <Separator className="" />}
            </div>
          );
        })}
      </div>
    </main>
  );
}
