import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";

// Define environment constant using the standard Vite MODE variable
const ENVIRONMENT = import.meta.env.PROD ? "production" : "development";

// Flag to track if June is initialized
let isJuneInitialized = false;

declare global {
  interface Window {
    june: any;
    juneify: any;
    analytics: any;
  }
}

interface JuneAnalyticsProps {
  writeKey: string;
}

export const fireJuneEvent = (
  eventName: string,
  properties?: Record<string, any>
) => {
  if (window.analytics && window.analytics.track && isJuneInitialized) {
    window.analytics.track(eventName, {
      event: eventName,
      domain: window.location.hostname,
      environment: ENVIRONMENT,
      timestamp: new Date().toISOString(),
      app: "takyon-dashboard",
      version: "3.0",
      ...properties,
    });
    console.log("June trackEvent", eventName, properties);
  } else {
    console.log("June not initialized yet, event not tracked:", eventName);
  }
};

const JuneAnalytics: React.FC<JuneAnalyticsProps> = ({ writeKey }) => {
  const user = useSelector(selectUser);

  // Reference to track if June has been initialized in this session
  const [hasInitialized, setHasInitialized] = useState(false);

  // Initialize June only once when the component mounts
  useEffect(() => {
    // Skip initialization if already done in this session
    if (hasInitialized) {
      console.log(
        "June already initialized in this session, skipping initialization"
      );
      return;
    }

    window.analytics = {};
    const juneify = (writeKey: string) => {
      // console.log("Initializing June Analytics...");
      window.analytics._writeKey = writeKey;
      const script = document.createElement("script");
      script.type = "application/javascript";
      script.onload = function () {
        console.log("June script loaded successfully");
        window.analytics.debug(false);

        // Mark June as initialized
        isJuneInitialized = true;
        setHasInitialized(true);

        /*
        // Track page view con dettagli aggiuntivi
        window.analytics.page({
          domain: window.location.hostname,
          path: window.location.pathname,
          url: window.location.href,
          title: document.title,
          environment: "development",
          app: "takyon-dashboard",
        });
        */

        window.analytics.track("june_initialized", {
          domain: window.location.hostname,
          environment: ENVIRONMENT,
          timestamp: new Date().toISOString(),
          app: "takyon-dashboard",
          version: "3.0",
        });

        console.log("June Analytics initialized with key:", writeKey);
      };
      script.onerror = function (error) {
        console.error("Error loading June script:", error);
      };
      script.src =
        "https://unpkg.com/@june-so/analytics-next/dist/umd/standalone.js";
      const first = document.getElementsByTagName("script")[0];
      if (first && first.parentNode) {
        first.parentNode.insertBefore(script, first);
      } else {
        document.head.appendChild(script);
      }
    };
    juneify(writeKey);
  }, [writeKey, hasInitialized]);

  useEffect(() => {
    if (isJuneInitialized && user?._id) {
      console.log("Identifying user in June:", user._id);
      window.analytics.identify(user._id, {
        email: user.email,
        name: user.firstName,
      });
    }
  }, [user]);

  return null;
};

export default JuneAnalytics;
