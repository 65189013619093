// Header.tsx
import {
  CircleUser,
  Cpu,
  CreditCard,
  Group,
  Laptop,
  LifeBuoy,
  LogOut,
  Mail,
  MessageCircleQuestion,
  User,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "@/context/AuthContext";
import Logo from "../layout/Logo";
import { useModal } from "@/context/ModalContext";
import { CreateGroupForm } from "../form/CreateGroupForm";
import getLink, { LINK_TYPE } from "@/lib/links";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";
import { SearchAll } from "../SearchAll/SearchAll";
import LanguageSelector from "../ui/language-selector";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { useCallback, useEffect } from "react";
import { useRbac } from "@/hooks/rbac";
import { Badge } from "../ui/badge";
import { useLocation } from "react-router-dom";
import GoToProModal from "@/pages/collection/analytics/GoToProModal";

interface HeaderProps {
  showNavMenu?: boolean;
}

export default function Header({ showNavMenu = false }: HeaderProps) {
  const { logout } = useAuthContext();
  const { showModal, hideModal } = useModal();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { isAuthenticated, isVerified } = useAuthContext();
  const { isSuperAdmin } = useRbac();
  const location = useLocation();
  const isCollectionPage = location.pathname.includes("/collection/");

  // SET PAGE TITLE
  const { setTitle, homeLink, isFree } = useSectionTitle();
  useEffect(() => {
    setTitle("");
  }, [setTitle]);

  const goToTheCorrectHome = useCallback(() => {
    if (isSuperAdmin) {
      if (homeLink) {
        navigate(homeLink);
      } else {
        navigate(getLink(LINK_TYPE.ADMIN_HOME));
      }
    } else {
      navigate(getLink(LINK_TYPE.HOME));
    }
  }, [homeLink]);

  const contactUsModal = () => {
    return (
      <div className="flex w-full gap-4 justify-between py-2">
        <div
          className="flex flex-col gap-2 items-center p-4 w-[250px] items-center cursor-pointer border rounded-xl shadow-md"
          onClick={() => window.open("mailto:partner.support@takyon.io")}
        >
          <Mail size={30} />
          <p className="text-base text-center">
            {t("collection.contact.email")}
          </p>
          <p className="text-base font-medium underline text-center">
            partner.support@takyon.io
          </p>
        </div>
        <div
          className="flex flex-col gap-2 items-center p-4 flex-1 w-[250px] justify-center items-center cursor-pointer border rounded-xl shadow-md"
          onClick={() => {
            window.open(
              `https://meetings-eu1.hubspot.com/marco-amodio?uuid=ad7ddb3e-4b41-415e-ab87-e7697321868e`,
              "_blank"
            );
            hideModal();
          }}
        >
          <Laptop size={30} />
          <p className="text-base text-center">
            {t("collection.contact.demo")}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-black px-4 md:px-6 z-[21]">
        <nav
          className={`flex items-center content-between w-full gap-6 text-lg font-medium md:flex md:flex-row md:gap-5 md:text-sm lg:gap-6`}
        >
          <Logo onClick={goToTheCorrectHome} />

          {isSuperAdmin && (
            <Badge
              variant="secondary"
              className="hidden md:block cursor-pointer"
              onClick={() => navigate(getLink(LINK_TYPE.ADMIN_HOME))}
            >
              superadmin
            </Badge>
          )}

          <div className="flex items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
            {isAuthenticated && (
              <Button
                variant="ghost"
                size="icon"
                className="rounded-full text-white"
                onClick={() => {
                  showModal(contactUsModal(), {
                    title: t("collection.contact_us"),
                    description: "",
                  });
                }}
              >
                <MessageCircleQuestion />
              </Button>
            )}
            {showNavMenu && (
              <div className="relative">
                <SearchAll />
              </div>
            )}
            <LanguageSelector></LanguageSelector>
            {isAuthenticated && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="rounded-full text-white"
                  >
                    <CircleUser className="h-5 w-5" />
                    <span className="sr-only">{t("nav.toggle")}</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuLabel>{user?.email}</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() => navigate(getLink(LINK_TYPE.ACCOUNT))}
                    className="cursor-pointer flex gap-2"
                  >
                    <User size={20} /> {t("nav.account")}
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => navigate(getLink(LINK_TYPE.PAYMENT_METHODS))}
                    className="cursor-pointer flex gap-2"
                  >
                    <CreditCard size={20} />
                    {t("nav.payment_methods")}
                  </DropdownMenuItem>

                  {isSuperAdmin && (
                    <DropdownMenuItem
                      className="cursor-pointer flex gap-2"
                      onClick={() => {
                        showModal(
                          <CreateGroupForm
                            onSuccess={() => {
                              navigate(getLink(LINK_TYPE.HOME));
                              window.location.reload(); // TODO find another way
                            }}
                          />,
                          {
                            title: t("group.create"),
                            description: "",
                          }
                        );
                      }}
                    >
                      <Group size={20} />
                      {t("nav.new_group")}
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuItem
                    className="cursor-pointer flex gap-2"
                    onClick={() => navigate(getLink(LINK_TYPE.DEVELOPER))}
                  >
                    <Cpu size={20} /> {t("nav.developer")}
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    className="cursor-pointer flex gap-2"
                    onClick={() => logout()}
                  >
                    <LogOut size={20} />
                    {t("nav.logout")}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </nav>
      </header>
      {/* {isFree && isCollectionPage && (
        <div className="sticky top-[64px] bg-[#ffa600] z-20 py-1 text-center">
          <p className="text-[12px] font-medium">
            {t("nav.free")}{" "}
            <span
              className="underline cursor-pointer"
              onClick={() =>
                showModal(<GoToProModal />, {
                  title: t("pro_modal.title"),
                  description: "",
                  className: "max-w-max",
                })
              }
            >
              {t("nav.free_link")}
            </span>
          </p>
        </div>
      )} */}
    </>
  );
}
