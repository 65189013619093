import { getDocumentsByName, iDocument } from "@/services/documents";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Skeleton } from "../ui/skeleton";
import ReactQuill from "react-quill";

interface DocProps {
  name: string;
}

export function RenderDocument({ name }: DocProps) {
  const { i18n } = useTranslation();

  const [documents, setDocuments] = useState<iDocument[]>();
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();

  const load = async () => {
    setIsLoading(true);
    try {
      const docs = await getDocumentsByName(name);
      setDocuments(docs);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, [pathname]);

  const document = documents?.find((doc) => doc.lang === i18n.language);

  return (
    <div>
      {isLoading ? (
        <div className="flex flex-col gap-2 p-3">
          <Skeleton className="bg-gray-300 h-[30px]" />
          <Skeleton className="bg-gray-300 h-[30px]" />
          <Skeleton className="bg-gray-300 h-[30px]" />
          <Skeleton className="bg-gray-300 h-[30px]" />
          <Skeleton className="bg-gray-300 w-16 h-[30px]" />
        </div>
      ) : null}

      {/* <div className="text-xl font-medium flex justify-center mb-4">
        {document?.name}
      </div> */}
      <section className="w-full bg-white h-max overflow-auto my-12">
        <div className="container mx-auto px-4">
          <div key={"tos_" + Math.random()}>
            <div className="whitespace-pre-line">
              {document ? (
                <>
                  <ReactQuill
                    modules={{ toolbar: false }}
                    readOnly
                    value={document?.text}
                  />
                </>
              ) : (
                <div className="w-full text-center">
                  <span>Not found</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
