import { useCallback, useEffect, useMemo, useState } from "react";
import { getColumns } from "./pending-payment-columns";
import { useTranslation } from "react-i18next";
import { DataTableComponent } from "@/components/datatable/data-table";
import { useParams } from "react-router-dom";
import { getPendingPayments, iPendingPayment } from "@/services/payment";
import { LargeText } from "../typography/Typography";

export default function PendingPaymentDatatable({
  paymentMethodId,
}: {
  paymentMethodId: string;
}) {
  const { collectionId } = useParams<{ collectionId: string }>();

  const { t } = useTranslation();

  const columns = useMemo(() => getColumns(t), [collectionId]);

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState({});
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const [data, setData] = useState<iPendingPayment[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      // console.log("getBidsPaginated", payload);
      // const result = await getBidsPaginated(payload);
      const data = await getPendingPayments(
        paymentMethodId,
        page,
        collectionId
      );
      const pageCount = Math.ceil(data.length / pageSize);
      setData(data);
      setPageCount(pageCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [collectionId, page, pageSize, filters, sorting, searchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <LargeText>{t("payment.pendingPayments")}</LargeText>
      <DataTableComponent
        isLoading={loading}
        data={data}
        columns={columns}
        pageCount={pageCount}
        globalSearch={false}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        onSortingChange={setSorting}
        onFiltersChange={setFilters}
      />
    </div>
  );
}
