import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { useModal } from "@/context/ModalContext";
import { PaymentMethodsModal } from "@/components/payment-method/AddPaymentMethod";
import {
  iPaymentMethod,
  getAllPaymentMethods,
  getPaymentMethod,
} from "@/services/payment";
import { useEffect, useState } from "react";
import {
  COLLECTION_PERMISSIONS,
  iCollection,
  patchCollection,
} from "@/services/collection";
import { Loader2 } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { Separator } from "@/components/ui/separator";
import { useRbac } from "@/hooks/rbac";
import { PaymentBillingForm } from "@/pages/accountSettings/forms/PaymentBillingForm";
import getLink, { LINK_TYPE } from "@/lib/links";

export default function PaymentMethods({
  onRefresh,
  collection,
  nextPage,
  loading,
}: {
  onRefresh(): void;
  collection: iCollection;
  nextPage?: string;
  loading?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // check and get id from query string
  const { qsPaymentMethodId } = useParams();

  const { can } = useRbac();
  const formSchema = z.object({
    paymentMethodId: z.string(),
  });

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      paymentMethodId: qsPaymentMethodId || collection?.paymentMethodId,
    },
  });

  const canEditPayments = can(
    COLLECTION_PERMISSIONS.set_payment_method,
    collection
  );

  // load current payment method associate to the collection if exists
  const [collectionPaymentMethod, setCollectionPaymentMethod] =
    useState<iPaymentMethod | null>(null);
  const loadCollectionPaymentMethod = async () => {
    try {
      const currentPaymentMethod = await getPaymentMethod(
        collection.paymentMethodId
      );
      setCollectionPaymentMethod(currentPaymentMethod);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (collection?.paymentMethodId) {
      loadCollectionPaymentMethod();
    }
  }, [collection]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setIsLoading(true);
    try {
      await patchCollection({
        collectionId: collection._id!,
        paymentmethodId: data.paymentMethodId,
      });
      onRefresh();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    if (nextPage) {
      navigate(nextPage);
    }
  };

  const [paymentMethods, setPaymentMethods] = useState<iPaymentMethod[]>([]);

  const { showModal } = useModal();
  const load = async () => {
    setIsLoading(true);
    try {
      // current user payment methods
      const pms = await getAllPaymentMethods();
      setPaymentMethods(pms);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      {canEditPayments &&
        can(COLLECTION_PERMISSIONS.set_payment_method, collection) && (
          <div className="flex items-center justify-between flex-row-reverse mb-3">
            {isLoading ? (
              <Button type="button" disabled className="min-w-40">
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                {t("buttons.loading")}
              </Button>
            ) : (
              <Button
                type="button"
                variant={"black"}
                onClick={() => {
                  showModal(
                    <PaymentMethodsModal
                      onSuccess={() => {}}
                      collection={collection}
                    />,
                    {
                      title: t("paymentmethods.modal_title"),
                      description: t("paymentmethods.modal_text"),
                    }
                  );
                }}
              >
                {t("paymentmethods.create_btn")}
              </Button>
            )}
          </div>
        )}

      {canEditPayments && paymentMethods.length > 0 ? (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="paymentMethodId"
              render={({ field }) => (
                <FormItem className="pb-6">
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t("collection.pm_select_placeholder")}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {paymentMethods.map((pm, key) => {
                        return (
                          <SelectItem key={"pm:" + key} value={pm._id}>
                            {pm.name}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator id="separator" className="my-8 bg-gray-200 h-0.5" />
            {/*  loading */}
            <div className="flex flex-row gap-4 justify-between mt-6">
              {nextPage ? (
                <Button
                  className=""
                  type="button"
                  onClick={() =>
                    navigate(`/collection/${collection._id}/infos/team`)
                  }
                >
                  {t("auth.back")}
                </Button>
              ) : (
                <div></div>
              )}
              {isLoading ? (
                <Button disabled className="min-w-40">
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  {t("buttons.loading")}
                </Button>
              ) : (
                <Button
                  variant={"takyon"}
                  onClick={() => form.handleSubmit(onSubmit)}
                >
                  {t("buttons.save_and_continue")}
                </Button>
              )}
            </div>
            <div className="mb-8" />
          </form>
        </Form>
      ) : (
        <div>{t("payment.no_payment_method")}</div>
      )}

      {!canEditPayments && (
        <>
          <div>{t("payment.admin_will_set_payment_method")}</div>
          <Separator id="separator" className="my-8 bg-gray-200 h-0.5" />
          {/*  loading */}
          <div className="flex flex-row gap-4 justify-between mt-6">
            <Button
              variant="takyon"
              onClick={() => navigate(getLink(LINK_TYPE.HOME))}
            >
              {t("buttons.close")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
