import { Loader2 } from "lucide-react";
import { Button } from "../ui/button";

import { ButtonProps } from "../ui/button";

export function LoadingSubmitButton({
  isLoading,
  children,
  ...props
}: {
  isLoading: boolean;
  children: React.ReactNode;
} & ButtonProps) {
  return (
    <Button disabled={isLoading} {...props}>
      {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
      {children}
    </Button>
  );
}
