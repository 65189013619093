import axios from "axios";

import { Pagination } from "../utils/Pagination";

export interface Webhook {
  userId: string;
  url: string;
  key: string;
  unsafe: boolean;

  amountFailed?: number;
}

export interface iWebhookLog {
  _id: string;
  webhookId: string;

  request: { event: string; payload: object };
  responses: object[];
  date: Date;
  success: boolean;
  attempts: number;
}

export async function getWebhookLogsPaginated(
  page = 1
): Promise<Pagination<iWebhookLog>> {
  const response = await axios.get(`/webhook/log?page=${page}`);
  return response.data;
}

export async function setWebhookLogAsSuccess(logId: string) {
  const response = await axios.post("/webhook/log/mark-success", {
    webhookLogId: logId,
  });

  return response.data;
}

export async function sendAgainWebhookLog(logId: string) {
  const response = await axios.post("/webhook/log/send-again", {
    webhookLogId: logId,
  });

  return response.data;
}

export async function getWebhook(): Promise<Webhook> {
  const response = await axios.get("/webhook");
  return response.data;
}

export async function editWebhook(payload: any): Promise<Webhook> {
  const response = await axios.post("/webhook", payload);
  return response.data;
}
