import { iCollection } from "@/services/collection";
import { GROUP_PERMISSIONS, iGroup } from "@/services/groups";
import { iPaymentMethod } from "@/services/payment";
import { ROLEABLE_ADMIN_PERMISSION } from "@/services/roleable";
import { selectUser } from "@/store/userSlice";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

export const useRbac = () => {
  const user = useSelector(selectUser);

  const isSuperAdmin = useMemo(
    () => (user && user.isAdmin === true ? true : false),
    [user]
  );

  const can = useCallback(
    (
      requiredPermission: string | string[],
      resource: iCollection | iGroup | iPaymentMethod
    ) => {
      if (isSuperAdmin) {
        return true;
      }
      if (!user) return false;

      const userResourcePermission: string[] | undefined =
        resource.members.find(
          (member) => member.email === user.email
        )?.permissions;

      if (!userResourcePermission) return false;

      if (userResourcePermission.includes(ROLEABLE_ADMIN_PERMISSION))
        return true;

      return userResourcePermission.some((permission) => {
        if (typeof requiredPermission === "string") {
          return permission === requiredPermission;
        } else {
          return requiredPermission.includes(permission);
        }
      });
    },
    [user]
  );

  return {
    isSuperAdmin,
    can,
  };
};
