import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCollection,
  iCollection,
  patchCollection,
} from "@/services/collection";
import { useEffect, useState, useCallback } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import ClipboardButton from "@/components/buttons/ClipboardButton";

export default function LoyaltyHowWorks({ onRefresh }: { onRefresh(): any }) {
  const CODE = "TAKYON";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const [collection, setCollection] = useState<iCollection | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Define the form schema with Zod
  const formSchema = z.object({
    percentageCode: z
      .string()
      .min(1, { message: t("loyalty.validation.percentage_required") })
      .refine(
        (val) => {
          const num = Number(val);
          return !isNaN(num) && num >= 1 && num <= 100;
        },
        { message: t("loyalty.validation.percentage_range") }
      ),
    cpaValue: z
      .string()
      .min(1, { message: t("loyalty.validation.cpa_required") })
      .refine(
        (val) => {
          if (collection?.isFree) {
            const num = Number(val);
            return !isNaN(num) && num >= 5;
          }
          return true;
        },
        { message: t("loyalty.validation.cpa_min_value") }
      )
      .optional(),
  });

  // Initialize the form
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      percentageCode: "",
      cpaValue: collection?.isFree ? "" : "0",
    },
  });

  // Handle form submission
  async function onSubmit(data: z.infer<typeof formSchema>) {
    setIsLoading(true);
    try {
      await patchCollection({
        loyatyDiscountPercentage: Number(data.percentageCode),
        loyatyCPAPerNight: Number(data.cpaValue),
        collectionId,
      });

      if (!collection?.paymentMethodId) {
        navigate(`/collection/${collectionId}/loyalty/billing`);
      } else {
        navigate(`/collection/${collection?._id}/loyalty/terms`, {
          state: { openModalLoyalty: true },
        });
      }
    } catch (error) {
      console.error("Error saving loyalty data:", error);
    } finally {
      setIsLoading(false);
    }

    onRefresh();
  }

  // Function to load collection data
  const loadCollection = useCallback(async () => {
    if (!collectionId) return;

    try {
      setIsLoading(true);
      const response = await getCollection(collectionId);
      setCollection(response);

      // Prefill form with existing loyalty values if they exist
      if (response.loyatyDiscountPercentage) {
        form.setValue(
          "percentageCode",
          response.loyatyDiscountPercentage.toString()
        );
      }
      if (response.loyatyCPAPerNight) {
        form.setValue("cpaValue", response.loyatyCPAPerNight.toString());
      }
    } catch (error) {
      console.error("Error loading collection:", error);
    } finally {
      setIsLoading(false);
    }
  }, [collectionId, form]);

  useEffect(() => {
    loadCollection();
  }, [loadCollection]);

  if (isLoading) {
    return (
      <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col space-y-3">
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        <p className="text-xl font-medium">{t("loyalty.how_it_works.title")}</p>
        {collection && (
          <p className="text-base text-gray-600 font-medium">
            {t("loyalty.how_it_works.description")}
          </p>
        )}
        {!collection && !isLoading && (
          <p className="text-base text-gray-600 font-medium">
            {t("loyalty.how_it_works.description")}
          </p>
        )}
        {isLoading && (
          <p className="text-base text-gray-600 font-medium">Loading...</p>
        )}
        <div className="p-2 border border-gray-200 rounded-lg mt-3 w-max">
          <p className="text-base text-gray-600 w-max">
            <span className="font-medium">
              {t("loyalty.cpa_code_desc_remember")}
            </span>
            {t("loyalty.cpa_code_desc")}
          </p>
        </div>
        <div className="mt-3">
          <ClipboardButton code={CODE} />
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-6"
            id="loyaltyForm"
          >
            <FormField
              control={form.control}
              name="percentageCode"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <div className="flex flex-col mb-1">
                    <p className="text-sm font-medium">
                      {t("loyalty.percentage_code")}
                    </p>
                    <p className="text-sm text-gray-600">
                      {t("loyalty.percentage_code_sub")}
                    </p>
                  </div>
                  <div
                    className="relative flex items-center gap-1"
                    style={{ marginTop: "0px" }}
                  >
                    <FormControl>
                      <Input
                        {...field}
                        type="text"
                        inputMode="numeric"
                        placeholder={t(
                          "loyalty.how_it_works.percentage_placeholder"
                        )}
                        className="pr-8 pt-2 w-96"
                        onBlur={(e) => {
                          field.onBlur();
                          // Validate the value on blur
                          const value = e.target.value;
                          if (value !== "") {
                            const numValue = parseInt(value, 10);
                            if (numValue > 100) {
                              field.onChange("100");
                            } else if (numValue < 5) {
                              field.onChange("5");
                            }
                          }
                        }}
                        onChange={(e) => {
                          // Remove any non-numeric characters
                          const value = e.target.value.replace(/[^\d]/g, "");
                          field.onChange(value);
                        }}
                        onKeyDown={(e) => {
                          // Allow only numbers, backspace, delete, tab, arrows, home, end
                          if (
                            !/^\d$/.test(e.key) &&
                            ![
                              "Backspace",
                              "Delete",
                              "Tab",
                              "ArrowLeft",
                              "ArrowRight",
                              "Home",
                              "End",
                            ].includes(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </FormControl>
                    <span>%</span>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            {collection?.isFree ? (
              <FormField
                control={form.control}
                name="cpaValue"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex flex-col mb-1">
                      <p className="text-sm font-medium">{t("loyalty.cpa")}</p>
                      <p className="text-sm text-gray-600">
                        {t("loyalty.cpa_sub")}
                      </p>
                      <p className="text-xs text-gray-600 italic">
                        {t("loyalty.cpa_desc")}
                      </p>
                    </div>
                    <div
                      className="relative flex items-center gap-1"
                      style={{ marginTop: "0px" }}
                    >
                      <FormControl>
                        <Input
                          {...field}
                          type="text"
                          inputMode="numeric"
                          placeholder={t(
                            "loyalty.how_it_works.cpa_placeholder"
                          )}
                          className="pr-8 w-96"
                          onBlur={(e) => {
                            field.onBlur();
                            // Validate the value on blur
                            const value = e.target.value;
                            if (value !== "") {
                              const numValue = parseInt(value, 10);
                              if (numValue < 5) {
                                field.onChange("5");
                              }
                            }
                          }}
                          onChange={(e) => {
                            // Remove any non-numeric characters
                            const value = e.target.value.replace(/[^\d]/g, "");
                            field.onChange(value);
                          }}
                          onKeyDown={(e) => {
                            // Allow only numbers
                            if (
                              !/^\d$/.test(e.key) &&
                              ![
                                "Backspace",
                                "Delete",
                                "Tab",
                                "ArrowLeft",
                                "ArrowRight",
                                "Home",
                                "End",
                              ].includes(e.key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </FormControl>
                      €
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : null}
          </form>
        </Form>
      </div>
      <div className="w-full flex justify-end">
        <Button
          variant={"takyon"}
          type="submit"
          className="mt-4"
          form="loyaltyForm"
        >
          {t("buttons.save_and_continue")}
        </Button>
      </div>
    </div>
  );
}
