import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { H4 } from "../typography/Typography";
import { useModal } from "@/context/ModalContext";
import {
  acceptInviteCollection,
  declineInviteCollection,
  iCollection,
} from "@/services/collection";
import { useState } from "react";
import { useAuthContext } from "@/context/AuthContext";

function AcceptInvitationModal({
  collection,
  onRefresh,
}: {
  collection: iCollection;
  onRefresh(): void;
}) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const { hideModal } = useModal();

  const auth = useAuthContext();

  const accept = async () => {
    setIsLoading(true);
    try {
      await acceptInviteCollection({ collectionId: collection._id });
      onRefresh();
      hideModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(true);
  };

  return (
    <div className="flex justify-end">
      <Button variant={"takyon"} disabled={isLoading} onClick={accept}>
        {t("collection.accept_invite_modal_button")}
      </Button>
    </div>
  );
}

function DeclineInvitationModal({ collection }: { collection: iCollection }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const { hideModal } = useModal();

  const accept = async () => {
    setIsLoading(true);
    try {
      await declineInviteCollection({ collectionId: collection._id });
      hideModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(true);
  };

  return (
    <div className="flex justify-end">
      <Button variant={"takyon"} disabled={isLoading} onClick={accept}>
        {t("collection.decline_invite_modal_button")}
      </Button>
    </div>
  );
}

export default function UserCollectionInvitationBanner({
  collection,
  onRefresh,
}: {
  collection: iCollection;
  onRefresh(): void;
}) {
  const { t } = useTranslation();

  const { showModal } = useModal();

  return (
    <div className="bg-muted p-6 border border-gray-200 rounded">
      <H4>
        {t("collection.accept_invite_title")} {collection.name}
      </H4>
      <p className="leading-7">{t("collection.accept_invite_text")}</p>

      <div className="mt-6 flex gap-2">
        <Button
          variant={"takyon"}
          onClick={() =>
            showModal(
              <AcceptInvitationModal
                onRefresh={onRefresh}
                collection={collection}
              />,
              {
                title: t("collection.accept_invite_modal_title"),
                description: t("collection.accept_invite_modal_text"),
              }
            )
          }
        >
          {t("collection.accept_invite_button")}
        </Button>
        <Button
          onClick={() => {
            showModal(<DeclineInvitationModal collection={collection} />, {
              title: t("collection.decline_invite_modal_title"),
              description: t("collection.decline_invite_modal_text"),
            });
          }}
          variant={"link"}
        >
          {t("collection.refuse_invite_button")}
        </Button>
      </div>
    </div>
  );
}
