import { Progress } from "@/components/ui/progress";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { ArrowLeft, X } from "lucide-react";
import { useTranslation } from "react-i18next";
import getLink, { LINK_TYPE } from "@/lib/links";
import { fireJuneEvent } from "@/components/analytics/JuneAnalytics";
import { iCollection } from "@/services/collection";

export default function InfoStepper({
  loading,
  collection,
}: {
  loading: boolean;
  collection?: iCollection;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);

  const [backBtn, setBackBtn] = useState(false);
  const [infoTip, setInfoTip] = useState("info.info_tip");
  const infoSteps = [
    {
      title: "SelectHotel",
      url: "/selecthotel",
    },
    {
      title: "HotelData",
      url: "/hoteldata",
    },
    {
      title: "HotelPrice",
      url: "/hotelprice",
    },
    {
      title: "HotelBenefits",
      url: "/hotelbenefits",
    },
    {
      title: "HotelChain",
      url: "/hotelchain",
    },
  ];

  const onBack = () => {
    const currentStep = infoSteps.findIndex(
      (step) => step.url === window.location.pathname
    );
    const previousStep = infoSteps[currentStep - 1];
    if (previousStep) {
      navigate(previousStep.url);
    }
  };

  const formRef = useRef();

  const onContinueHandler = () => {
    console.log("onContinueHandler...");
    const currentStep = infoSteps.findIndex(
      (step) => step.url === window.location.pathname
    );
    const nextStep = infoSteps[currentStep + 1];
    if (nextStep) {
      navigate(nextStep.url);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="flex flex-col gap-4 px-6 py-10 md:gap-8 md:px-14 md:py-8 min-h-[calc(100dvh-theme(spacing.16)-theme(spacing.16))]">
      <div className="flex items-center justify-between">
        <p className="text-2xl">{t("onboarding.title")}</p>
        <X
          className="cursor-pointer"
          onClick={() => {
            fireJuneEvent("onboarding_cancel", {
              collectionName: collection?.name,
              collectionId: collection?._id,
            });
            navigate(getLink(LINK_TYPE.HOME));
          }}
        />
      </div>
      <p className="text-base text-gray-600">{t("onboarding.subtitle")}</p>
      <div className="relative">
        <Progress value={progress} className="h-2" />
        <div className="absolute w-full top-0 left-0 h-4 flex items-center justify-between">
          <div className="h-4 w-1"></div>
          <div className="h-4 bg-white w-1"></div>
          <div className="h-4 bg-white w-1"></div>
          <div className="h-4 bg-white w-1"></div>
          <div className="h-4 bg-white w-1"></div>
          <div className="h-4 w-1"></div>
        </div>
      </div>
      <Outlet
        context={{
          onContinueHandler,
          formRef,
          setBackBtn,
          setProgress,
          setInfoTip,
        }}
      />
      {/* <div className="flex">
        <Button onClick={onBack}>Indietro</Button>
        <Button onClick={handleNextClick}>Salva e continua</Button>
      </div> */}
    </div>
  );
}
