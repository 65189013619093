import { z } from "zod";
import i18n from "i18next";

export const createFormHotelSchema = () => {
  const t = i18n.t.bind(i18n);

  return z.object({
    _createdAt: z.date(),
    owner: z.string().optional(), // required for new records inside the form with extend
    lang: z.string().min(1, {
      message: t("validation.required"),
    }),
    reference: z.string().min(1, {
      message: t("validation.required"),
    }),
    checkin: z.object({
      from: z.date({
        required_error: t("validation.required"),
      }),
      to: z.date({
        required_error: t("validation.required"),
      }),
    }),
    lockDate: z.date({
      required_error: t("validation.required"),
    }),
    board: z.string().min(1, {
      message: t("validation.required"),
    }),
    extra: z.string().optional(),
    gold: z.boolean().optional(),
    rooms: z.array(
      z.object({
        name: z.string().min(1, t("validation.required")),
        guestsAdults: z
          .preprocess(
            (val) => (typeof val === "number" ? String(val) : val),
            z.string().optional()
          )
          .transform((val) => Number(val))
          .refine((val) => !isNaN(val), {
            message: t("validation.mustBeNumber"),
          })
          .refine((val) => val >= 1, {
            message: t("validation.mustBeGreaterThan", {
              value: 0,
            }),
          }),
        guestsKids: z
          .preprocess(
            (val) => (typeof val === "number" ? String(val) : val),
            z.string().optional()
          )
          .transform((val) => Number(val))
          .refine((val) => !isNaN(val), {
            message: t("validation.mustBeNumber"),
          })
          .refine((val) => val >= 0, {
            message: t("validation.mustBeNonNegative"),
          }),
      })
    ),
    price: z
      .preprocess(
        (val) => (typeof val === "number" ? String(val) : val),
        z.string()
      )
      .transform((val) => Number(val))
      .refine((val) => !isNaN(val), {
        message: t("validation.mustBeNumber"),
      })
      .refine((val) => val >= 0, {
        message: t("validation.mustBeNonNegative"),
      }),
    images: z.array(z.string()).optional(),
    fakeOwnerName: z.string().optional(),
    // initially only one guest is allowed
    firstName: z.string().min(1, {
      message: t("validation.required"),
    }),
    lastName: z.string().min(1, {
      message: t("validation.required"),
    }),
  });
};

export const createFormExperienceSchema = () => {
  const t = i18n.t.bind(i18n);

  return z.object({
    _createdAt: z.date(),
    owner: z.string().optional(), // required for new records inside the form with extend
    lang: z.string().min(1, {
      message: t("validation.required"),
    }),
    reference: z.string().min(1, {
      message: t("validation.required"),
    }),
    start_date: z.date({
      required_error: t("validation.required"),
    }),
    gold: z.boolean().optional(),
    originalPrice: z
      .preprocess(
        (val) => (typeof val === "number" ? String(val) : val),
        z.string()
      )
      .transform((val) => Number(val))
      .refine((val) => !isNaN(val), {
        message: t("validation.mustBeNumber"),
      })
      .refine((val) => val >= 0, {
        message: t("validation.mustBeNonNegative"),
      }),
    lockDate: z.date({
      required_error: t("validation.required"),
    }),
    extra: z.string().optional(),
    partecipants: z
      .preprocess(
        (val) => (typeof val === "number" ? String(val) : val),
        z.string().optional()
      )
      .transform((val) => Number(val))
      .refine((val) => !isNaN(val), {
        message: t("validation.mustBeNumber"),
      })
      .refine((val) => val >= 0, {
        message: t("validation.mustBeNonNegative"),
      }),
    duration: z
      .preprocess(
        (val) => (typeof val === "number" ? String(val) : val),
        z.string().optional()
      )
      .transform((val) => Number(val))
      .refine((val) => !isNaN(val), {
        message: t("validation.mustBeNumber"),
      })
      .refine((val) => val >= 0, {
        message: t("validation.mustBeNonNegative"),
      }),
    type: z.string().optional(),
    category: z.string().optional(),
    images: z.array(z.string()).optional(),
    fakeOwnerName: z.string().optional(),
  });
};

export type FormSchemaHotel = z.infer<ReturnType<typeof createFormHotelSchema>>;
export type FormSchemaExperience = z.infer<
  ReturnType<typeof createFormExperienceSchema>
>;
