import { iNft, removeNftFromSale } from "@/services/nft";
import { useTranslation } from "react-i18next";
import { iCollection } from "@/services/collection";
import { Button } from "../ui/button";
import { useState } from "react";
import { useModal } from "@/context/ModalContext";

export default function RemoveFromSaleForm({
  nft,
  collection,
  onSuccess,
}: {
  nft: iNft;
  collection: iCollection;
  onSuccess(): void;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { hideModal } = useModal();

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await removeNftFromSale({
        nftId: nft._id!,
      });
      onSuccess();
      hideModal();
    } catch (error) {
      console.error("Error putting NFT on sale", error);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex justify-end">
      <Button
        onClick={onSubmit}
        disabled={isLoading}
        variant={"destructive"}
        type="submit"
      >
        {t("nft.remove_sale_confirm")}
      </Button>
    </div>
  );
}
