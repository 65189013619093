import { iCollection } from "@/services/collection";
import BuybacksDatatable from "./BuybacksDatatable";
import SaleEventsDatatable from "./SaleEventsDatatable";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LargeText } from "@/components/typography/Typography";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import { Info } from "lucide-react";

export default function BuybacksTab({
  collection,
}: {
  collection: iCollection;
}) {
  // set page title
  const { setTitle } = useSectionTitle();
  const { t } = useTranslation();

  useEffect(() => {
    setTitle(`${t("collection.tab_buybacks")} - ${collection.name}`);
  }, [setTitle]);

  return (
    <div className="border rounded-lg p-4">
      <BuybacksDatatable collection={collection} />
    </div>
  );
}
