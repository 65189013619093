import MapsAutocomplete from "../mapsautocomplete/MapsAutocomplete";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import {
  Control,
  UseFormSetValue,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { Input } from "../ui/input";
import { useTranslation } from "react-i18next";
import { Label } from "../ui/label";

interface FormValues {
  address: string;
  city: string;
  province: string;
  zipCode: string;
}

interface FullAddressProps<T extends FieldValues> {
  control: Control<T>;
  setValue: UseFormSetValue<T>;
}

const FulladdressWithAutocomplete = <T extends FieldValues>({
  control,
  setValue,
}: FullAddressProps<T>) => {
  const { t } = useTranslation();

  const handlePlaceSelect = (place: google.maps.places.PlaceResult) => {
    const addressComponents = place?.address_components
      ? Object.values(place?.address_components)
      : [];
    console.log("handlePlaceSelect", addressComponents);
    let address =
      addressComponents?.find((component) => component.types.includes("route"))
        ?.long_name || "";

    const street_number =
      addressComponents?.find((component) =>
        component.types.includes("street_number")
      )?.short_name || "";

    if (street_number) {
      address = `${address} , ${street_number}`;
    }

    const city =
      addressComponents?.find((component) =>
        component.types.includes("administrative_area_level_3")
      )?.long_name || "";

    const province =
      addressComponents?.find((component) =>
        component.types.includes("administrative_area_level_2")
      )?.short_name || "";

    const zipCode =
      addressComponents?.find((component) =>
        component.types.includes("postal_code")
      )?.long_name || "";

    // Correctly cast field values as PathValue<T, Path<T>>
    setValue("address" as Path<T>, address as PathValue<T, Path<T>>);
    setValue("city" as Path<T>, city as PathValue<T, Path<T>>);
    setValue("province" as Path<T>, province as PathValue<T, Path<T>>);
    setValue("zipCode" as Path<T>, zipCode as PathValue<T, Path<T>>);
  };

  return (
    <>
      <div className="mb-4">
        <Label className="mb-2">{t("form.search_address")}</Label>
        <MapsAutocomplete onSelect={handlePlaceSelect} />
      </div>

      <div>
        <FormField
          control={control}
          name={"address" as Path<T>}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("invoices.address")} *</FormLabel>
              <FormControl>
                <Input placeholder={t("invoices.address")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex gap-2">
          <FormField
            control={control}
            name={"city" as Path<T>}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("invoices.city")} *</FormLabel>
                <FormControl>
                  <Input placeholder={t("invoices.city")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name={"province" as Path<T>}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("invoices.province")} *</FormLabel>
                <FormControl>
                  <Input placeholder={t("invoices.province")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name={"zipCode" as Path<T>}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("invoices.zipCode")} *</FormLabel>
                <FormControl>
                  <Input placeholder={t("invoices.zipCode")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default FulladdressWithAutocomplete;
