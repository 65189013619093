import { iCollection } from "@/services/collection";
import { ColumnDef } from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Copy, MoreVertical } from "lucide-react";
import { useModal } from "@/context/ModalContext";
import { TFunction } from "i18next";
import { iPendingPayment } from "@/services/payment";

export const getColumns = (
  t: TFunction,
  collection?: iCollection
): ColumnDef<iPendingPayment>[] => {
  return [
    {
      header: t("payment.nft"),
      accessorKey: "nftId",
    },
    {
      header: t("payment.collection"),
      accessorKey: "collectionId",
    },
    {
      header: `${t("payment.amount")} (€)`,
      accessorKey: "amount",
    },
    {
      header: `${t("payment.fulfilled")} (€)`,
      accessorKey: "fullfilled",
      accessorFn: (payment) => `${payment.fulfilled ? "OK" : "-"}`,
    },
    {
      id: "actions",
      accessorKey: "id",
      header: t("offer.actions"),
      cell: function render({ getValue, row }) {
        const { showModal } = useModal();

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button aria-haspopup="true" size="icon" variant="ghost">
                <MoreVertical className="h-4 w-4" />
                <span className="sr-only">Toggle menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {/* <DropdownMenuItem
                onClick={() =>
                  showModal(<BuybackActionForm nft={row.original} />, {
                    title: t("nft.buyback"),
                    description: t("nft.buyback_text"),
                  })
                }
              >
                {t("nft.buyback")}
              </DropdownMenuItem> */}
              <DropdownMenuItem>{t("form.action_delete")}</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
};
