import { DatePicker } from "@/components/form_fields/DatePicker";
import FulladdressWithAutocomplete from "@/components/form_fields/FulladdressWithAutocomplete";
import { LoadingSubmitButton } from "@/components/form_fields/LoadingSubmitButton";
import { LargeText } from "@/components/typography/Typography";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { useModal } from "@/context/ModalContext";
import {
  createInvoice,
  getLastInvoiceId,
  iInvoice,
  iInvoiceOutput,
  iInvoiceSend,
  iLastUpdateInvoice,
  readInvoiceStatus,
  sendInvoiceMethod,
  validateInvoiceNumber,
} from "@/services/payment";
import { datetimeToString } from "@/utils/generic";
import { zodResolver } from "@hookform/resolvers/zod";
import { use } from "i18next";
import { set } from "lodash";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import { Control, FieldValues, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

interface SendInvoiceProps {
  invoice: iInvoice;
  apikey: string;
  onSuccess: Function;
}

const errorStatusStyle = {
  color: "white",
  backgroundColor: "red",
  fontWeight: "bold",
  padding: "5px 10px",
  borderRadius: "5px",
  fontSize: "16px",
  display: "inline-block",
};

const formSchema = z.object({
  documentType: z.string().min(1),
  number: z.string().trim().min(1),
  invoiceDate: z.date(),

  name: z.string().min(1),
  contact_name: z.string().min(1),
  address: z.string().min(1),
  city: z.string().min(1),
  province: z.string().min(1),
  zipCode: z.string().min(1),
  pec: z.string().min(1),
  phone: z.string().min(1),
  recipient_code: z.string().min(1),
  vat: z
    .string()
    .refine((val) => val.length === 11 && !val.toUpperCase().startsWith("IT"), {
      message: "VAT must be 11 characters long and should not start with 'IT'",
    }),
  fiscalCode: z.string().min(1),

  invoiceLines: z.array(
    z.object({
      lineNumber: z.string().min(1),
      description: z.string().min(1),
      quantity: z
        .preprocess(
          (val) => (typeof val === "number" ? String(val) : val),
          z.string().optional()
        )
        .transform((val) => Number(val))
        .refine((val) => !isNaN(val), {
          message: "quantity must be a number",
        })
        .refine((val) => val >= 1, {
          message: "quantity must be greater than 0",
        }),
      tax: z
        .preprocess(
          (val) => (typeof val === "number" ? String(val) : val),
          z.string().optional()
        )
        .transform((val) => Number(val))
        .refine((val) => !isNaN(val), {
          message: "vat must be a number",
        })
        .refine((val) => val >= 1, {
          message: "vat must be greater than 0",
        }),
      subTotal: z
        .preprocess(
          (val) => (typeof val === "number" ? String(val) : val),
          z.string().optional()
        )
        .transform((val) => Number(val))
        .refine((val) => !isNaN(val), {
          message: "subTotal must be a number",
        })
        .refine((val) => val >= 1, {
          message: "subTotal must be greater than 0",
        }),
    })
  ),

  paymentCondition: z.string().min(1),
  paymentMode: z.string().min(1),
  paymentDate: z.date(),
  total: z
    .preprocess(
      (val) => (typeof val === "number" ? String(val) : val),
      z.string()
    )
    .transform((val) => Number(val)) // Convert string input to number
    .refine((val) => !isNaN(val), { message: "total must be a number" }) // Check if it's a valid number
    .refine((val) => val >= 0, { message: "total must be non-negative" }), // Additional validation,
  totalTax: z
    .preprocess(
      (val) => (typeof val === "number" ? String(val) : val),
      z.string()
    )
    .transform((val) => Number(val)) // Convert string input to number
    .refine((val) => !isNaN(val), { message: "totalTax must be a number" }) // Check if it's a valid number
    .refine((val) => val >= 0, { message: "totalTax must be non-negative" }), // Additional validation,
  invoiceCurrency: z.string().min(1),
});

export function SendInvoice({ invoice, apikey, onSuccess }: SendInvoiceProps) {
  const { t } = useTranslation();
  const cleanString = (str: string) => {
    // /[^a-zA-Z0-9À-ÖØ-öø-ÿ ]/g
    return str.replace(/[&"'-]/g, "");
  };
  const { hideModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceOut, setInvoiceOut] = useState<iInvoiceOutput>({
    InvoiceNum: "",
    billingDetails: {
      ...invoice.billingDetails,
      vat: invoice.billingDetails.vat.trim() || "",
      fiscalCode: invoice.billingDetails.fiscalCode || "",
      name: cleanString(invoice.billingDetails.name || ""),
      zip: invoice.billingDetails.zip || "",
      city: invoice.billingDetails.city || "",
      address: invoice.billingDetails.address || "",
      province: invoice.billingDetails.province || "",
      contactName: invoice.billingDetails.contactName || "",
      contactPhone: invoice.billingDetails.contactPhone || "",
      recipientCode: invoice.billingDetails.recipientCode.toUpperCase() || "",
      pec: invoice.billingDetails.pec || "",
    },
    invoiceDocumentType: "TD01",
    InvoiceCurrency: "EUR",
    InvoiceDate: new Date(invoice._createdAt).toISOString().slice(0, 10),
    totalPrice: (Number(invoice.payload?.total) / 100).toString() || "",
    tax: "0.22",
    lineNumber: "1",
    amount: "1",
    description: `Takyon - Fee di creazione  ${datetimeToString(
      invoice.payload?.period_end * 1000
    )} - ${datetimeToString(invoice.payload?.period_start * 1000)}`,
    paymentCondition: "TP02",
    paymentMode: "MP19",
    paymentDate: new Date(Number(invoice.payload?.effective_at) * 1000)
      .toISOString()
      .slice(0, 10),
    invoiceServiceId: invoice.invoiceSeviceId,
  });
  const [labelError, setLabelError] = useState();

  const [lastInvoiceInternalId, setLastInvoiceInternalId] = useState<string>();

  useEffect(() => {
    console.log("SendInvoice...", invoice);
    const fetchLastInvoiceInternalId = async () => {
      const lastInvoiceInternalId = await getLastInvoiceId();
      console.log("lastInvoiceInternalId", lastInvoiceInternalId);
      setLastInvoiceInternalId(
        lastInvoiceInternalId.data.lastInvoiceInternalId
      );
    };

    fetchLastInvoiceInternalId();
  }, []);

  // const flattenObjectValues = (obj: any): any[] => {
  //   return Object.entries(obj).flatMap(([key, value]) => {
  //     if (
  //       [
  //         "fiscalCode",
  //         "contactName",
  //         "contactPhone",
  //         "recipientCode",
  //         "pec",
  //       ].includes(key)
  //     ) {
  //       return [];
  //     }
  //     return typeof value === "object"
  //       ? flattenObjectValues(value)
  //       : String(value);
  //   });
  // };

  const errorInvoice = async (invoiceId: string) => {
    try {
      const response: any = await readInvoiceStatus(invoiceId, apikey);
      setLabelError(response.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      invoice.invoiceServiceStatus === "ERROR" ||
      invoice.invoiceServiceStatus === "NS"
    ) {
      errorInvoice(invoice.invoiceSeviceId);
    }
  }, [invoice.invoiceServiceStatus]);

  // TODO
  // const sendInvoiceClick = async (onSuccess: Function) => {
  //   const payload = { ...invoiceOut };

  //   // Set the tax to 22%
  //   payload.tax = (Number(invoiceOut.tax) * 100).toString();
  //   payload.InvoiceCurrency = invoiceOut.InvoiceCurrency.toUpperCase();
  //   payload.billingDetails.province =
  //     payload.billingDetails.province?.toUpperCase();

  //   // if (flattenObjectValues(payload).some((value) => value === "")) {
  //   //   alert("Please fill all required fields");
  //   //   return;
  //   // }

  //   setIsLoading(true);

  //   try {
  //     const response: any = await checkInvoiceMethod(payload, invoice._id);
  //     //console.log(response._id);
  //     const responseSend: any = await sendInvoiceMethod(
  //       [response._id],
  //       invoice._id
  //     );

  //     setIsLoading(false);
  //     hideModal();
  //     alert(responseSend.message);
  //     onSuccess();
  //     //console.log(response.xml);
  //     //showModal(<ViewXMLModal xmlData={String(response.xml)} />);
  //   } catch (error: any) {
  //     setIsLoading(false);
  //     alert(error.message);
  //   }
  // };

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    console.log("Preparing data to be submitted to the provider service", data);
    // run extra remote validation
    const { valid, message } = await validateInvoiceNumber(data.number);
    if (!valid) {
      // Set error manually for the 'number' field
      form.setError("number", {
        type: "custom",
        message: message || t("invoces.number_not_valid"),
      });

      return; // Prevent form submission
    } else {
      // reset form remote errors
      form.clearErrors();
    }

    setIsLoading(true);

    try {
      console.log(
        "Preparing data to be submitted to the provider service",
        data
      );

      const payload: iInvoiceSend = {
        billingDetails: {
          vat: data.vat,
          fiscalCode: data.fiscalCode,
          name: data.name,
          zip: data.zipCode,
          city: data.city,
          address: data.address,
          province: data.province,
          recipientCode: data.recipient_code,
          contactName: data.contact_name,
          contactPhone: data.phone,
          pec: data.pec,
        },
        invoiceDocumentType: data.documentType,
        InvoiceCurrency: data.invoiceCurrency,
        InvoiceDate: data.invoiceDate.toISOString().slice(0, 10),
        InvoiceNum: data.number,
        totalPrice: data.total,
        tax: String(data.totalTax), // what do I need to pass?
        lineNumber: Number(data.invoiceLines[0].lineNumber),
        amount: data.total,
        description: data.invoiceLines[0].description,
        paymentCondition: data.paymentCondition,
        paymentMode: data.paymentMode,
        paymentDate: data.paymentDate.toISOString().slice(0, 10),
        invoiceId: invoice.invoiceSeviceId,
      };

      console.log("payload that will be used in the api call", payload);
      alert("restore the api calls");

      /*
      const response = createInvoice(payload, invoice._id);

      const responseSend: any = await sendInvoiceMethod(
        [response._id],
        invoice._id
      );
      */

      onSuccess();

      // //console.log(response.xml);
      // //showModal(<ViewXMLModal xmlData={String(response.xml)} />);
    } catch (error: any) {
      setIsLoading(false);
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  // prefill form with invoice data
  useEffect(() => {
    // initially only one line
    const invoiceLines = [
      {
        lineNumber: "1",
        description: `${t("invoices.fee")}  ${datetimeToString(
          invoice.payload?.period_end * 1000
        )} - ${datetimeToString(invoice.payload?.period_start * 1000)}`,
        quantity: 1,
        tax: 22,
        subTotal: invoice.payload?.total,
      },
    ];

    const total = invoiceLines.reduce((acc, line) => {
      return acc + Number(line.subTotal);
    }, 0);
    const totalTax = invoiceLines.reduce((acc, line) => {
      return acc + Number(line.tax);
    }, 0);

    form.reset({
      documentType: invoiceOut.invoiceDocumentType,
      number: invoiceOut.InvoiceNum,
      invoiceDate: invoiceOut.InvoiceDate
        ? new Date(invoiceOut.InvoiceDate)
        : undefined,
      name: invoiceOut.billingDetails.name,
      contact_name: invoiceOut.billingDetails.contactName,
      address: invoiceOut.billingDetails.address,
      city: invoiceOut.billingDetails.city,
      province: invoiceOut.billingDetails.province,
      zipCode: invoiceOut.billingDetails.zip,
      pec: invoiceOut.billingDetails.pec,
      phone: invoiceOut.billingDetails.contactPhone,
      recipient_code: invoiceOut.billingDetails.recipientCode,
      vat: invoiceOut.billingDetails.vat,
      fiscalCode: invoiceOut.billingDetails.fiscalCode,
      // email: invoiceOut.billingDetails.email,
      // website: invoiceOut.billingDetails.website,
      // payment: invoiceOut.billingDetails.payment,
      invoiceLines: invoiceLines,
      paymentCondition: invoiceOut.paymentCondition,
      paymentMode: invoiceOut.paymentMode,
      paymentDate: invoiceOut.paymentDate
        ? new Date(invoiceOut.paymentDate)
        : undefined,
      total: total,
      totalTax: totalTax,
      invoiceCurrency: invoiceOut.InvoiceCurrency,
    });
  }, [invoice]);

  return (
    <div>
      {invoice.invoiceServiceStatus === "ERROR" && (
        <div className="flex gap-2">
          <div className="flex flex-col w-full">
            <label style={errorStatusStyle}>{labelError}</label>
          </div>
        </div>
      )}
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="p-3">
          <div>
            <FormField
              control={form.control}
              name="documentType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("invoices.documentType")} * TODO readonly
                  </FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={field.onChange}
                      value={field.value}
                      disabled
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={t("form.select")} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {/* initially admin can't choose different code */}
                        {[{ code: "TD01", label: "Fattura" }].map((docType) => (
                          <SelectItem key={docType.code} value={docType.code}>
                            {docType.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex">
              <FormField
                control={form.control}
                name="number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("invoices.number")} *</FormLabel>
                    <FormControl>
                      <Input placeholder={t("invoices.number")} {...field} />
                    </FormControl>
                    <FormDescription>
                      {t("invoices.last_invoice_id")}:{" "}
                      {lastInvoiceInternalId || "N/A"}
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="invoiceDate"
                render={({ field }) => (
                  <FormItem className="ml-auto">
                    <FormLabel>{t("invoices.invoiceDate")} *</FormLabel>
                    <FormControl>
                      <div>
                        <DatePicker className="w-full" {...field} />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <Separator className="my-3" />

            <div>
              <LargeText className="mb-3">{t("invoices.rows")}</LargeText>

              <InvoiceLineTabularForm
                control={form.control}
                invoice={invoice}
              />
            </div>

            <Separator className="my-3" />

            <LargeText className="mb-3">{t("invoices.sendTo")}</LargeText>
            <div>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>{t("invoices.name")} *</FormLabel>
                    <FormControl>
                      <Input placeholder={t("invoices.name")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="space-y-3">
                <FormField
                  control={form.control}
                  name="pec"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("invoices.pec")} *</FormLabel>
                      <FormControl>
                        <Input placeholder={t("invoices.pec")} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="recipient_code"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("invoices.recipient_code")} *</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("invoices.recipient_code")}
                          {...field}
                          onChange={(e) =>
                            field.onChange(e.target.value.toUpperCase())
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex gap-2">
                  <FormField
                    control={form.control}
                    name="vat"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("invoices.vat")} *</FormLabel>
                        <FormControl>
                          <Input placeholder={t("invoices.vat")} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="fiscalCode"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("invoices.fiscalCode")} *</FormLabel>
                        <FormControl>
                          <Input
                            placeholder={t("invoices.fiscalCode")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mt-3">
                <FulladdressWithAutocomplete
                  control={form.control}
                  setValue={form.setValue}
                />

                <div className="flex gap-2">
                  <FormField
                    control={form.control}
                    name="contact_name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("invoices.contact_name")} *</FormLabel>
                        <FormControl>
                          <Input
                            placeholder={t("invoices.contact_name")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="phone"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t("invoices.phone")} *</FormLabel>
                        <FormControl>
                          <Input placeholder={t("invoices.phone")} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>

            <Separator className="my-3" />

            <div className="flex justify-between">
              <div>
                <LargeText className="mb-3">
                  {t("invoices.paymentData")}
                </LargeText>
                <FormField
                  control={form.control}
                  name="paymentCondition"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("invoices.paymentCondition")} *</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("invoices.paymentCondition")}
                          readOnly
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="paymentMode"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("invoices.paymentMode")} *</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("invoices.paymentMode")}
                          readOnly
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="paymentDate"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("invoices.paymentDate")} *</FormLabel>
                      <FormControl>
                        <div>
                          <DatePicker className="w-full" {...field} />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <LargeText className="pb-3">{t("invoices.resume")}</LargeText>
                <FormField
                  control={form.control}
                  name="total"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("invoices.total")} *</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("invoices.total")}
                          type="number"
                          step="0.01"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* <label htmlFor="">Amount*</label>
                <input
                  type="text"
                  value={invoiceOut.totalPrice}
                  onChange={(e) =>
                    setInvoiceOut({
                      ...invoiceOut,
                      totalPrice: e.target.value,
                    })
                  }
                /> */}

                <FormField
                  control={form.control}
                  name="totalTax"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("invoices.totalTax")} *</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("invoices.totalTax")}
                          type="number"
                          step="0.01"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="invoiceCurrency"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("invoices.currency")} *</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("invoices.currency")}
                          {...field}
                          type="text"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="flex mt-10 flex-row-reverse">
            <LoadingSubmitButton
              variant={"takyon"}
              isLoading={isLoading}
              // onClick={() => {
              //   sendInvoiceClick(onSuccess);
              // }}
              // disabled={invoiceOut.InvoiceNum === ""}
            >
              {t("invoices.sendInvoice")}
            </LoadingSubmitButton>
          </div>
        </form>
      </Form>
    </div>
  );
}

const InvoiceLineTabularForm = ({
  control,
  invoice,
}: {
  control: Control<z.infer<typeof formSchema>>;
  invoice?: iInvoice;
}) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "invoiceLines",
  });

  // prepare invoice lines from invoice
  useEffect(() => {
    if (invoice) {
    }
  }, [invoice]);

  return (
    <>
      {fields.map((field, index) => (
        <div className="flex items-end space-x-2">
          <FormField
            control={control}
            name={`invoiceLines.${index}.lineNumber`}
            render={({ field }) => (
              <FormItem className="w-16">
                <FormLabel>{t("invoices.lineNumber")} *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("invoices.lineNumber")}
                    readOnly
                    {...field}
                    type="text"
                    // value={invoiceOut.lineNumber}
                    // onChange={(e) => {
                    //   setInvoiceOut({
                    //     ...invoiceOut,
                    //     lineNumber: e.target.value,
                    //   });
                    //   field.onChange;
                    // }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* <label htmlFor="">Line Number*</label>
                <input
                  type="text"
                  value={invoiceOut.lineNumber}
                  onChange={(e) =>
                    setInvoiceOut({
                      ...invoiceOut,
                      lineNumber: e.target.value,
                    })
                  }
                /> */}

          <FormField
            control={control}
            name={`invoiceLines.${index}.description`}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>{t("invoices.description")} *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("invoices.description")}
                    {...field}
                    // value={invoiceOut.description}
                    // onChange={(e) => {
                    //   setInvoiceOut({
                    //     ...invoiceOut,
                    //     description: e.target.value,
                    //   });
                    //   field.onChange;
                    // }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name={`invoiceLines.${index}.quantity`}
            render={({ field }) => (
              <FormItem className="">
                <FormLabel>{t("invoices.quantity")} *</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Inserisci la quantità"
                    type="number"
                    step="1"
                    {...field}

                    // value={invoiceOut.amount}
                    // onChange={(e) => {
                    //   setInvoiceOut({
                    //     ...invoiceOut,
                    //     amount: e.target.value,
                    //   });
                    //   field.onChange;
                    // }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name={`invoiceLines.${index}.tax`}
            render={({ field }) => (
              <FormItem className="">
                <FormLabel>{t("invoices.vat")} *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("invoices.vat")}
                    type="number"
                    step="0.01"
                    {...field}
                    // value={invoiceOut.amount}
                    // onChange={(e) => {
                    //   setInvoiceOut({
                    //     ...invoiceOut,
                    //     amount: e.target.value,
                    //   });
                    //   field.onChange;
                    // }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name={`invoiceLines.${index}.subTotal`}
            render={({ field }) => (
              <FormItem className="flex-shrink">
                <FormLabel>{t("invoices.subTotal")} *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("invoices.subTotal")}
                    type="number"
                    step="0.01"
                    {...field}
                    // value={invoiceOut.amount}
                    // onChange={(e) => {
                    //   setInvoiceOut({
                    //     ...invoiceOut,
                    //     amount: e.target.value,
                    //   });
                    //   field.onChange;
                    // }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            className="ml-auto"
            variant="ghost"
            size="icon"
            type="button"
            onClick={() => {
              if (confirm(t("alerts.are_you_sure"))) {
                remove(index);
              }
            }}
          >
            <X size={16} />
          </Button>
        </div>
      ))}
      <div className="mt-5 flex">
        <Button
          variant="gray"
          className="ml-auto"
          size="sm"
          type="button"
          onClick={() =>
            append({
              lineNumber: String(fields.length + 1),
              description: "",
              quantity: 1,
              tax: 22,
              subTotal: 0,
            })
          }
        >
          {t("invoices.add_invoiceLine")}
        </Button>
      </div>
    </>
  );
};
