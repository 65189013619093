import axios from "axios";
import { logout } from "./auth";
import { getLocaleLanguage } from "./i18next";

export function initializeAxios() {
  axios.defaults.headers.common["Accept-Language"] = getLocaleLanguage();
  axios.defaults.baseURL = import.meta.env.VITE_BACKEND_URI;
}

axios.interceptors.response.use(
  (r) => r,
  (error) => {
    if (String(error?.response?.data?.message).includes("expired")) logout();
    // if (Number(error?.response?.status) === 401) logout();
    return Promise.reject(error);
  }
);
