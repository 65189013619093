import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NftsDatatable from "./NftsDatatable";
import { useSectionTitle } from "@/context/SectionTitleContext";

export default function Nfts() {
  const { t } = useTranslation();

  // set page title
  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle(`${t("nfts.page_title")}`);
  }, [setTitle]);

  return (
    <section id="nfts-page">
      <NftsDatatable />
    </section>
  );
}
