import { useAuthContext } from "@/context/AuthContext";
import getLink, { LINK_TYPE } from "@/lib/links";
import { selectUser } from "@/store/userSlice";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Skeleton } from "../ui/skeleton";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();
  const user = useSelector(selectUser);

  // console.log("ProtectedRoute check...", isAuthenticated, Date.now());

  // Check for loading state
  if (isAuthenticated == null) {
    return (
      <div>
        <div className="flex flex-col space-y-3">
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
        </div>
      </div>
    );
  }

  // Redirect if not authenticated or email not verified
  if (!isAuthenticated || (user && !user.emailVerified)) {
    // console.log("ProtectedRoute - Redirecting to /auth");
    return <Navigate to={getLink(LINK_TYPE.AUTH)} />;
  }

  // If authenticated and email is verified, render the children components
  return children;
};

export default ProtectedRoute;
