import { member, role } from "@/services/member";
import { H3 } from "../typography/Typography";
import { useModal } from "@/context/ModalContext";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { InviteMember } from "../form/InviteMember";
import { Separator } from "@radix-ui/react-dropdown-menu";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import RoleDropdown from "../form_fields/RoleDropdown";
import { LucideTrash2 } from "lucide-react";
import { useMemo, useState } from "react";
import { ROLEABLE_ADMIN_PERMISSION } from "@/services/roleable";
import { useRbac } from "@/hooks/rbac";
import { Badge } from "../ui/badge";
import { useAuthContext } from "@/context/AuthContext";

function RemoveMemberModal({ onRemove }: { onRemove(): Promise<void> }) {
  const { hideModal } = useModal();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const remove = async () => {
    setIsLoading(true);
    try {
      await onRemove();
      hideModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex justify-end">
      <div className="flex gap-4">
        <Button disabled={isLoading} onClick={hideModal} type="button">
          {t("members.cancel")}
        </Button>
        <Button variant="destructive" disabled={isLoading} onClick={remove}>
          {t("members.remove")}
        </Button>
      </div>
    </div>
  );
}

/*
Initially only roles with a lower position in roles array can change role of members with a higher position in roles array.
*/
function checkIfUserRoleIsHigherThanTheMemberRole(
  roles: role<any>[],
  role: role<any>,
  checkRole: role<any>
) {
  const roleIndex = roles.findIndex((r) => r.name === role.name);
  const checkRoleIndex = roles.findIndex((r) => r.name === checkRole.name);

  return roleIndex <= checkRoleIndex;
}

export default function Members({
  members,
  roles,
  onUpdateRole,
  onRemoveMember,
  onInviteMember,
  canEdit,
}: {
  members: member<any>[];
  roles: role<any>[];
  onUpdateRole(email: string, role: role<any>): Promise<void>;
  onRemoveMember(email: string): Promise<void>;
  onInviteMember(email: string, role: role<any>): Promise<void>;
  canEdit: boolean;
}) {
  const { showModal } = useModal();
  const { t } = useTranslation();
  const { isSuperAdmin } = useRbac();
  const { userEmail } = useAuthContext();

  const currentUserMember = useMemo(() => {
    return members.find((m) => m.email === userEmail);
  }, [members, userEmail]);

  const canRenderMember = (member: member<any>): boolean => {
    let visible = true;

    if (member.email === userEmail) {
      return true;
    }

    const isStealth =
      member.permissions.find((p) => p.includes("stealth")) !== undefined;

    if (!isSuperAdmin && isStealth) {
      visible = false;
    }

    // skip superadmin
    if (!isSuperAdmin && member.permissions.includes("admin")) {
      visible = false;
    }

    // skip takyon.io emails in production
    let isTakyon;
    if (import.meta.env.VITE_ENV === "production") {
      isTakyon = member.email.includes("@takyon.io");
      if (!isSuperAdmin && isTakyon) {
        visible = false;
      }
    }

    // console.log("DEBUG canRenderMember", {
    //   member,
    //   roles,
    //   isSuperAdmin,
    //   isStealth,
    //   isTakyon,
    //   visible,
    // });

    return visible;
  };

  const getRole = (roles: role<any>[], member: member<any>) => {
    if (member.permissions.includes("admin"))
      return { name: "admin", onlyAdmin: false, permissions: [] };

    let perms = [...member.permissions];

    const role = roles.find(
      (e) => e.permissions.sort().join(",") === perms.sort().join(",")
    );

    return role;
  };

  const currentUserRole = useMemo(() => {
    if (!currentUserMember) return;
    return getRole(roles, currentUserMember);
  }, [getRole, roles, currentUserMember]);

  const assignablesRoles = useMemo(() => {
    return roles.filter((role) => {
      if (!currentUserRole) return [];
      return (
        checkIfUserRoleIsHigherThanTheMemberRole(
          roles,
          currentUserRole,
          role
        ) && role.name !== "admin"
      );
    });
  }, [roles, currentUserRole]);

  return (
    <div className="py-6">
      <div className="flex">
        <H3>{t("members.members")}</H3>
        {canEdit && (
          <Button
            variant={"black"}
            onClick={() =>
              showModal(
                <InviteMember onSuccess={onInviteMember} roles={roles} />,
                {
                  title: t("members.invite_user"),
                  description: t("members.invite_user_text"),
                }
              )
            }
            className="ml-auto min-w-52"
          >
            {t("buttons.invite_member")}
          </Button>
        )}
      </div>
      <Separator className="my-4" />
      {members.length > 0
        ? members.map((member, i) => {
            const memberItsMe = member.email === userEmail;
            const memberRole = getRole(roles, member);
            const canEditMemberRole =
              memberRole &&
              currentUserRole &&
              checkIfUserRoleIsHigherThanTheMemberRole(
                roles,
                currentUserRole,
                memberRole
              );

            // console.log("DEBUG member", {
            //   member,
            //   currentUserMember,
            //   memberRole,
            //   currentUserRole,
            //   canEditMemberRole,
            // });

            return canRenderMember(member) ? (
              <div key={"member" + i}>
                <div className="flex items-center gap-4">
                  <Avatar className="hidden h-9 w-9 sm:flex">
                    <AvatarFallback>
                      {member.email
                        .replaceAll(".", "")
                        .replaceAll("_", "")
                        .substring(0, 2)
                        .toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                  <div className="grid gap-2">
                    {userEmail && userEmail === member.email ? (
                      <p className="text-sm text-bold">{member.email}</p>
                    ) : (
                      <p className="text-sm text-muted-foreground">
                        {member.email}
                      </p>
                    )}
                    {!member.isInviteAccepted && (
                      <p className="text-xs text-destructive">
                        {t("collection.invite_pending")}
                      </p>
                    )}
                  </div>
                  {!memberItsMe && (canEdit || isSuperAdmin) ? (
                    <div className="ml-auto flex gap-2 items-center">
                      {(memberRole?.name == "admin" || canEdit) && (
                        <>
                          <div className="w-36">
                            <RoleDropdown
                              disabled={!canEditMemberRole}
                              defaultValue={memberRole?.name}
                              onSelect={(role) => {
                                onUpdateRole(member.email, role);
                              }}
                              roles={assignablesRoles}
                            />
                          </div>
                          <Button
                            disabled={
                              !canEditMemberRole ||
                              member.permissions.includes(
                                ROLEABLE_ADMIN_PERMISSION
                              )
                            }
                            variant={"ghost"}
                            onClick={() =>
                              showModal(
                                <RemoveMemberModal
                                  onRemove={() => onRemoveMember(member.email)}
                                />,
                                {
                                  title: t("members.remove_modal"),
                                  description: member.email,
                                }
                              )
                            }
                          >
                            <LucideTrash2 size={16} />
                          </Button>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="ml-auto flex gap-2">
                      <Badge variant="secondary">
                        {t(`role.${memberRole?.name}`)}
                      </Badge>
                    </div>
                  )}
                </div>

                <Separator className="my-4" />
              </div>
            ) : null;
          })
        : t("members.no_members")}
    </div>
  );
}
