"use client";

import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  PaginationState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { DataTablePagination } from "./data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";
import { useTranslation } from "react-i18next";
import { Skeleton } from "../ui/skeleton";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  pageCount: number;
  // onPaginationChange: OnChangeFn<PaginationState>;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onSortingChange: (sorting: SortingState) => void;
  onFiltersChange: (filters: ColumnFiltersState) => void;
  actions?: React.ReactNode[];
  globalSearch?: boolean;
  onGlobalSearch?: (filterValue: string) => void;
  isLoading?: boolean;
  searchPlaceholder?: string;

  filters?: any[];
}

export function DataTableComponent<TData, TValue>({
  columns,
  data,
  pageCount,
  onGlobalSearch,
  onPageChange,
  onPageSizeChange,
  actions = [],
  globalSearch = true,
  onFiltersChange,
  isLoading = false,
  filters,
  searchPlaceholder,
}: DataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { t } = useTranslation();

  React.useEffect(() => {
    onPageChange(pagination.pageIndex);
  }, [pagination.pageIndex]);

  React.useEffect(() => {
    onPageSizeChange(pagination.pageSize);
  }, [pagination.pageSize]);

  React.useEffect(() => {
    onFiltersChange(columnFilters);
  }, [columnFilters]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: onGlobalSearch,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // *** client side pagination
    // getPaginationRowModel: getPaginationRowModel(),
    // *** server side pagination
    onPaginationChange: setPagination,
    manualPagination: true,
    pageCount: pageCount, // total number of pages need to to arrive from server
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <div className="space-y-4">
      {isLoading ? (
        <div className="flex flex-col space-y-3">
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg w-1/5" />
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg w-5/6" />
        </div>
      ) : (
        <>
          <DataTableToolbar
            table={table}
            globalSearch={globalSearch}
            actions={actions}
            filters={filters}
            searchPlaceholder={searchPlaceholder}
          />
          <div className="rounded-md border">
            <Table>
              <TableHeader className="bg-gray-100">
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          className="text-black"
                          key={header.id}
                          colSpan={header.colSpan}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => {
                    // console.log("row", row);
                    return (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      {t("table.no_results")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          {table.getRowModel().rows?.length > 0 && (
            <DataTablePagination table={table} />
          )}
        </>
      )}
    </div>
  );
}
