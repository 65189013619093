import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Separator } from "../ui/separator";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

interface iBuybackModal {
  nft: iNft;
  callback?: Function;
  amount?: number;
  defaultMode?: "buy" | "offer";
}

export default function BuybackActionForm({
  nft,
  callback,
  amount,
  defaultMode,
}: iBuybackModal) {
  const { t } = useTranslation();

  return (
    <div>
      <Tabs defaultValue="purchase" className="">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="purchase">{t("nft.buyback_buy")}</TabsTrigger>
          <TabsTrigger value="offer">{t("nft.buyback_offer")}</TabsTrigger>
        </TabsList>

        <Separator className="my-3" />

        <TabsContent value="purchase">
          <PurchaseForm nft={nft} />
        </TabsContent>
        <TabsContent value="offer">
          <OfferForm nft={nft} />
        </TabsContent>
      </Tabs>
    </div>
  );
}

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { DatePicker } from "../form_fields/DatePicker";
import { DEFAULT_FEE_SPLIT, DEFAULT_OVERPRICE } from "@/services/fees";
import { Checkbox } from "../ui/checkbox";
import { buyback, buybackOffer, iNft } from "@/services/nft";
import { useFeedback } from "@/context/FeedbackContext";
import helpers from "../helpers/helpers";
import { useNavigate } from "react-router-dom";
import { LoadingSubmitButton } from "../form_fields/LoadingSubmitButton";

const purchaseFormSchema = z.object({
  email: z.string(),
  delete: z.boolean(),
});

function PurchaseForm({ nft }: { nft: iNft }) {
  const form = useForm<z.infer<typeof purchaseFormSchema>>({
    resolver: zodResolver(purchaseFormSchema),
    defaultValues: {
      email: "",
      delete: false,
    },
  });

  const navigate = useNavigate();

  const { showFeedback } = useFeedback();

  const { t } = useTranslation();

  const onSubmit = async (values: z.infer<typeof purchaseFormSchema>) => {
    if (!nft?._id) {
      return;
    }

    try {
      await buyback({ nftId: nft._id, delete: values.delete });
      showFeedback({
        title: t("nft.buyback_purchase_feedback_title"),
        message: t("nft.buyback_purchase_feedback_message"),
        onClose: () => {
          navigate(`/collection/${nft.collectionId}/buybacks`);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const stripeFees = (nft.currentPrice ?? 0) * DEFAULT_OVERPRICE;
  const price = nft.currentPrice ?? 0;
  const paymentAmount = price + stripeFees;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid gap-3">
          <div className="font-semibold">{t("nft.resume")}</div>
          <ul className="grid gap-3">
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">
                {t("nft.buyback_price")}
              </span>
              <span>{nft.currentPrice?.toFixed(2)} €</span>
            </li>
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">
                {t("nft.buyback_fees")}
              </span>
              <span>{stripeFees?.toFixed(2)} €</span>
            </li>
            <li className="flex items-center justify-between font-semibold">
              <span className="text-muted-foreground">
                {t("nft.buyback_payment_amount")}
              </span>
              <span>{paymentAmount?.toFixed(2)} €</span>
            </li>
          </ul>

          <FormField
            control={form.control}
            name="delete"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl className="mt-1">
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>{t("nft.buyback_delete_label")}</FormLabel>
                  <FormDescription>
                    {t("nft.buyback_delete_description")}
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />

          <div className="flex justify-end mt-2 gap-2">
            <Button onClick={() => console.log("close modal")}>
              {t("buttons.cancel")}
            </Button>
            <LoadingSubmitButton
              variant={"takyon"}
              isLoading={form.formState.isSubmitting}
            >
              {`${t("nft.buyback_confirm")}`}
            </LoadingSubmitButton>
          </div>
          <div className="flex justify-end mt-2">
            <small>*{t("nft.buyback_tip")}</small>
          </div>
        </div>
      </form>
    </Form>
  );
}

const offerFormSchema = z.object({
  amount: z
    .string()
    .transform((val) => Number(val)) // Convert string input to number
    .refine((val) => !isNaN(val), { message: "Price must be a number" }) // Check if it's a valid number
    .refine((val) => val >= 0, { message: "Price must be non-negative" }), // Additional validation
  expireDate: z.date().optional(),
  delete: z.boolean().optional(),
});

function OfferForm({ nft }: { nft: iNft }) {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof offerFormSchema>>({
    resolver: zodResolver(offerFormSchema),
    defaultValues: {
      delete: false,
    },
  });

  const { showFeedback } = useFeedback();

  const navigate = useNavigate();

  const offerValue = form.watch("amount");

  const onSubmit = async (values: z.infer<typeof offerFormSchema>) => {
    if (!nft?._id) {
      return;
    }

    const expireDate = values.expireDate ?? new Date(nft.lockDate);

    try {
      // logic here
      await buybackOffer({
        nftId: nft._id,
        amount: values.amount,
        expireDate: expireDate,
        delete: values.delete,
      });

      showFeedback({
        title: t("nft.buyback_offer_feedback_title"),
        message: t("nft.buyback_offer_feedback_message"),
        onClose: () => {
          console.log("onClosed... from buyback offer action form");
          navigate(`/collection/${nft.collectionId}/buybacks`);
        },
      });
    } catch (error: any) {
      const e = String(error?.response?.data?.message);

      console.log("Buyback offers", error);

      if (e.includes("already exists"))
        form.setError("amount", {
          message: t("nft.buyback_offer_already_exists"),
        });
      else if (e) form.setError("amount", { message: e.toString() });
      else
        form.setError("amount", {
          message: String(t("nft.buyback_generic_error")),
        });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex items-center justify-between">
          <span className="text-muted-foreground">
            {t("nft.buyback_price")}
          </span>
          <span>{nft.currentPrice?.toFixed(2)} €</span>
        </div>

        <Separator className="my-3" />

        <FormField
          control={form.control}
          name="amount"
          render={({ field }) => (
            <FormItem className="flex items-center justify-between">
              <FormLabel className="">{t("nft.buyback_offer_value")}</FormLabel>
              <div className="w-1/3">
                <FormControl>
                  <Input
                    className="text-right"
                    placeholder={t("nft.buyback_offer_value")}
                    {...field}
                  />
                </FormControl>
                <FormDescription></FormDescription>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="expireDate"
          render={({ field }) => (
            <FormItem className="flex items-center justify-between">
              <FormLabel className="">
                {t("nft.buyback_offer_date")} {t("form.optional")}
              </FormLabel>
              <div className="w-1/3">
                <FormControl>
                  <div>
                    <DatePicker
                      className="w-full text-right"
                      placeholder={t("nft.buyback_offer_date_valid_until")}
                      {...field}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <div className="flex items-center justify-between mt-3">
          <span>
            {t("nft.buyback_fees")} {helpers.feeToString(DEFAULT_OVERPRICE)}
          </span>
          {offerValue && (
            <span>{((offerValue ?? 0) * DEFAULT_OVERPRICE).toFixed(2)} €</span>
          )}
        </div>

        <Separator className="my-3" />

        <div className="flex items-center justify-between font-semibold">
          <span className="text-muted-foreground">
            {t("nft.buyback_payment_amount")}
          </span>
          <span>
            {((offerValue ?? 0) * (1 + DEFAULT_OVERPRICE)).toFixed(2)} €
          </span>
        </div>

        <div className="mt-3">
          <FormField
            control={form.control}
            name="delete"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>{t("nft.buyback_delete_label")}</FormLabel>
                  <FormDescription>
                    {t("nft.buyback_delete_description")}
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
        </div>

        <div className="flex justify-end mt-2 gap-2">
          <Button onClick={() => console.log("close modal")}>
            {t("nft.buyback_cancel")}
          </Button>
          <LoadingSubmitButton
            variant={"takyon"}
            isLoading={form.formState.isSubmitting}
          >
            {`${t("nft.buyback_offer_confirm")}`}
          </LoadingSubmitButton>
        </div>
        <div className="flex justify-end mt-2">
          <small>*{t("nft.buyback_tip")}</small>
        </div>
      </form>
    </Form>
  );
}
