import React from "react";
import { H4 } from "../typography/Typography";

interface DLItemProps {
  children: React.ReactNode;
  title: string;
}

const DLItem: React.FC<DLItemProps> = ({ title, children }) => (
  <div className="flex items-center justify-between">
    <dt className="text-muted-foreground">{title}</dt>
    <dd>{children}</dd>
  </div>
);

interface DLProps {
  children: React.ReactNode;
}

interface DLProps {
  children: React.ReactNode;
  title?: string;
}

const DL: React.FC<DLProps> = ({ children, title }) => (
  <div>
    {title && (
      <div className="mb-3">
        <H4>{title}</H4>
      </div>
    )}
    <dl className="grid gap-1 text-sm">{children}</dl>
  </div>
);

export { DL, DLItem };
