import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import React from "react"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 * A React component that renders parts of a translated text with styling and event handlers.
 * 
 * @example
 * <Render
 *   text={t("loyalty.accept_terms")}
 *   props={{
 *     className: "font-medium text-takyon-600 hover:underline",
 *     onClick: () => window.open('https://takyon.io/terms', '_blank')
 *   }}
 * />
 */
export function Render({
  text,
  props
}: {
  text: string;
  props: React.HTMLAttributes<HTMLSpanElement>;
}): JSX.Element {
  if (!text) return React.createElement("span", null);
  
  // Split the text by {{ and }}
  const parts = text.split(/{{|}}/);
  
  // Create elements array
  const elements: React.ReactNode[] = [];
  
  // Process each part
  parts.forEach((part, index) => {
    // Even indices are regular text, odd indices are highlighted parts
    if (index % 2 === 0) {
      if (part) elements.push(part);
    } else {
      // This is a highlighted part, apply the props
      elements.push(
        React.createElement("span", { key: `highlight-${index}`, ...props }, part)
      );
    }
  });
  
  return React.createElement(React.Fragment, null, ...elements);
}
