import { H3 } from "@/components/typography/Typography";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Separator } from "@/components/ui/separator";
import { performanceResponse } from "@/services/dataanalysismask";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import { Globe, Info, MousePointerClick, PiggyBank } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, XAxis, YAxis } from "recharts";
import { formatNumberEuropean } from "@/utils/generic";

export default function PerformanceSection({
  data,
}: {
  data: performanceResponse;
}) {
  const { t } = useTranslation();

  const {
    clickOnOtherRate,
    clickOnTakyonRate,
    conversionRatePostTakyon,
    conversionRatePreTakyon,
    otaSavings,
    traffic,
  } = data;

  const chartData = [
    {
      era: "post_takyon",
      conversionRate: Number(conversionRatePostTakyon) * 100,
      fill: "var(--color-post_takyon)",
    },
    {
      era: "pre_takyon",
      conversionRate: Number(conversionRatePreTakyon) * 100,
      fill: "var(--color-pre_takyon)",
    },
  ];

  const takyonColor = "#43F3AE";
  const otherColor = "#E8E8E8";
  const conversionRatePreTakyonNumber = Number(conversionRatePreTakyon);
  const conversionRatePostTakyonNumber = Number(conversionRatePostTakyon);

  const increaseConversionRate =
    ((conversionRatePostTakyonNumber - conversionRatePreTakyonNumber) /
      conversionRatePreTakyonNumber) *
    100;

  const chartConfig = {
    conversionRate: {
      label: t("analytics.performance.conversion_rate") + "\u00A0",
      color: takyonColor,
    },
    post_takyon: {
      label: t("analytics.performance.pre") + " Takyon",
      color: takyonColor,
    },
    pre_takyon: {
      label: t("analytics.performance.post") + " Takyon",
      color: otherColor,
    },
  } satisfies ChartConfig;

  return (
    <div className="border w-full p-5 rounded-xl">
      <p className="text-2xl font-medium pb-3">
        {t("analytics.performance.section_title")}
      </p>
      <div className="flex gap-4">
        <div className="w-2/3 border rounded-md p-4">
          <div className="inline-flex gap-2 items-center w-full">
            <span className="text-xl font-medium">
              {t("analytics.performance.conversionrate_title")}
            </span>
            <Popover>
              <PopoverTrigger asChild>
                <Info className="cursor-pointer" size={16} />
              </PopoverTrigger>
              <PopoverContent className="w-80">
                <p>{t("analytics.performance.conversionrate_description")}</p>
              </PopoverContent>
            </Popover>
          </div>

          <div className="py-3">
            <ChartContainer config={chartConfig} className="h-32 w-full">
              <BarChart
                accessibilityLayer
                data={chartData}
                layout="vertical"
                margin={{
                  left: 0,
                }}
              >
                <YAxis
                  dataKey="era"
                  type="category"
                  tickLine={false}
                  tickMargin={10}
                  axisLine={false}
                  tickFormatter={(value) =>
                    chartConfig[value as keyof typeof chartConfig]?.label
                  }
                />
                <XAxis dataKey="conversionRate" type="number" hide />
                <ChartTooltip
                  cursor={false}
                  content={
                    <ChartTooltipContent
                      formatter={(value) => (value as number).toFixed(2) + "%"}
                    />
                  }
                />
                <Bar dataKey="conversionRate" layout="vertical" radius={5} />
              </BarChart>
            </ChartContainer>
          </div>
          <Separator className="my-3" />
          <div>
            <p className="pb-4">
              {t("analytics.performance.conversion_increment_title")}
            </p>
            {increaseConversionRate && (
              <p>
                <span className="text-3xl" style={{ color: "#3BDA9C" }}>
                  +{formatNumberEuropean(Number(increaseConversionRate), 1)}%
                </span>{" "}
                <span className="text-gray-400 text-sm">
                  {t("analytics.performance.conversion_increment_description")}
                </span>
              </p>
            )}
          </div>
        </div>
        <div className="w-1/3 flex flex-col gap-2 justify-between">
          <div className="gap-5 border rounded-md p-5">
            <div className="flex justify-between">
              <div className="flex items-center justify-start gap-1 w-full">
                <span className="text-base font-medium">
                  {t("analytics.performance.traffic_title")}
                </span>
                <Popover>
                  <PopoverTrigger asChild>
                    <Info className="cursor-pointer" size={16} />
                  </PopoverTrigger>
                  <PopoverContent className="w-80">
                    <p>{t("analytics.performance.traffic_session_popover")}</p>
                  </PopoverContent>
                </Popover>
              </div>
              <Globe size={24} className="text-gray-400" />
            </div>
            <div className="text-xl font-medium">
              {traffic} {t("analytics.performance.traffic_session")}
            </div>
          </div>
          <div className="gap-5 border rounded-md p-5">
            <div className="flex justify-between">
              <div className="flex items-center justify-start gap-1 w-full">
                <span className="text-base font-medium">
                  {t("analytics.performance.clickOn_title")}
                </span>
                <Popover>
                  <PopoverTrigger asChild>
                    <Info className="cursor-pointer" size={16} />
                  </PopoverTrigger>
                  <PopoverContent className="w-80">
                    <p>{t("analytics.performance.clickOn_title_popover")}</p>
                  </PopoverContent>
                </Popover>
              </div>
              <MousePointerClick size={24} className="text-gray-400" />
            </div>
            <div className="flex">
              <div className="flex items-center">
                <span className="text-base text-gray-400 font-medium">
                  {t("analytics.performance.clickOn_tak")}
                </span>
                &nbsp;
                <span className="text-xl font-medium">{clickOnTakyonRate}</span>
              </div>
              <Separator orientation="vertical" className="mx-2 h-6 w-[2px]" />
              <div className="flex items-center">
                <span className="text-base text-gray-400 font-medium">
                  {t("analytics.performance.clickOn_others")}
                </span>
                &nbsp;
                <span className="text-xl font-medium">{clickOnOtherRate}</span>
              </div>
            </div>
          </div>
          <div className="gap-5 border rounded-md p-5">
            <div className="flex justify-between">
              <div className="flex items-center justify-start gap-1 w-full">
                <span className="text-base font-medium">
                  {t("analytics.performance.otaSavings_title")}
                </span>
                <Popover>
                  <PopoverTrigger asChild>
                    <Info className="cursor-pointer" size={16} />
                  </PopoverTrigger>
                  <PopoverContent className="w-80">
                    <p>{t("analytics.performance.otaSavings_title_popover")}</p>
                  </PopoverContent>
                </Popover>
              </div>
              <PiggyBank size={24} className="text-gray-400 font-medium" />
            </div>
            <div className="text-xl font-medium">
              €{formatNumberEuropean(Number(otaSavings), 0)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
