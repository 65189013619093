// import { openModal } from "../../components/modal/Modal";
import { useTranslation } from "react-i18next";

import { useEffect, useMemo, useRef, useState } from "react";
import { PAGE_DIM, scrollToTopList } from "../../../utils/generic";
import {
  deleteCategory,
  getCategoriesPaginated,
  iSupportFaq,
} from "../../../services/supportfaqs";
// import Loader from "../../components/dom/Loader";
import { Loader2, LucideEdit, Trash2 } from "lucide-react";

// import Button from "../../components/dom/Button";
import { Button } from "@/components/ui/button";
import Faqs from "./forms/Faqs";
import { useModal } from "@/context/ModalContext";
import { Separator } from "@/components/ui/separator";
import { H3 } from "@/components/typography/Typography";
import CategoryForm from "./forms/CategoryForm";
import DeleteModal from "@/components/ui/deleteModal";
import { useSectionTitle } from "@/context/SectionTitleContext";

export default function SupportFaqs() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  // set page title
  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle(`${t("support.page_title")}`);
  }, [setTitle]);

  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState<number>(PAGE_DIM);
  const elementRef = useRef<HTMLDivElement>(null);

  const [faqsCategories, setfaqsCategories] = useState<iSupportFaq[]>([]);
  const [isDetailsShowed, setIsDetailsShowed] = useState<
    iSupportFaq | undefined
  >();

  const { showModal, hideModal } = useModal();
  const loadSupportFaqs = async () => {
    setIsLoading(true);
    try {
      const data = await getCategoriesPaginated({ page, size });
      setfaqsCategories(data.data.reverse());

      setTotal(data.total || 0);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    scrollToTopList(elementRef);
  };

  useEffect(() => {
    loadSupportFaqs();
    // eslint-disable-next-line
  }, []);

  const catsByLanguage = useMemo(() => {
    const groupedCats: { [key: string]: iSupportFaq[] } = {};
    faqsCategories.forEach((cat) => {
      const language = cat.lang;
      if (!groupedCats[language]) {
        groupedCats[language] = [];
      }
      groupedCats[language].push(cat);
    });

    // Object.keys(groupedCats).forEach((language) => {
    //   groupedCats[language].reverse();
    // });

    return groupedCats;
  }, [faqsCategories]);

  if (isLoading) return <Loader2 />;

  if (isDetailsShowed)
    return (
      <Faqs
        // onSuccess={loadFaqs}
        onExit={() => setIsDetailsShowed(undefined)}
        category={isDetailsShowed}
      />
    );

  return (
    <div className="content">
      <section id="support-section">
        <br />
        <Button
          variant="takyon"
          onClick={() =>
            showModal(<CategoryForm onSuccess={() => loadSupportFaqs()} />, {
              title: t("support.category.modal_title"),
              description: " ",
            })
          }
          // text={String(t("support.create_category"))}
          className="create-btn"
        >
          {t("support.create_category")}
        </Button>
        <Separator className="mt-6 mb-8" />
        <span className="text-lg font-normal">
          {String(t("support.category_title"))}
        </span>

        {Object.keys(catsByLanguage).map((language) => (
          <div key={Math.random()} className="my-6">
            <H3 className="mb-2 w-full">
              <p className="text-center">{language}</p>
            </H3>
            <div className="flex flex-col gap-1">
              {catsByLanguage[language].map((category: iSupportFaq) => (
                <div className="flex items-center justify-between border rounded-md p-2 cursor-pointer hover:bg-slate-100">
                  <div
                    key={Math.random()}
                    onClick={() => {
                      setIsDetailsShowed(category);
                    }}
                    className="flex gap-3 items-center justify-between p-2 cursor-pointer hover:bg-slate-100"
                  >
                    <img
                      src={category.icons[0]}
                      alt="icon"
                      className="w-14 h-14"
                    />

                    <div className="cat-name">{category.name}</div>
                  </div>
                  <div className="flex gap-2">
                    <Button
                      variant="ghost"
                      title={t("support.edit_category")}
                      onClick={(e: any) => {
                        e.preventDefault();
                        showModal(
                          <CategoryForm
                            category={category}
                            onSuccess={loadSupportFaqs}
                          />,
                          {
                            title: t("support.category.modal_title"),
                            description: " ",
                          }
                        );
                      }}
                    >
                      <LucideEdit size={16} />
                    </Button>

                    <Button
                      variant="ghost"
                      title={t("support.delete_category")}
                      onClick={(e: any) => {
                        e.preventDefault();
                        showModal(
                          <DeleteModal
                            onRemove={async () => {
                              await deleteCategory({ _id: category._id });
                              hideModal();
                              loadSupportFaqs();
                            }}
                          />,
                          {
                            title: t("modals.remove_modal"),
                            description: t("modals.remove_modal_text"),
                          }
                        );
                      }}
                    >
                      <Trash2 size={16} />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        {faqsCategories.length === 0 && (
          <div>{String(t("support.no_cats"))}</div>
        )}
      </section>
    </div>
  );
}
