import { useForm, SubmitHandler } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useNavigate } from "react-router-dom";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { resetPassword } from "@/services/auth";
import { H2 } from "../typography/Typography";
import getLink, { LINK_TYPE } from "@/lib/links";
import { ArrowLeft } from "lucide-react";
import { useAuthContext } from "@/context/AuthContext";

export function ResetPasswordForm() {
  const { t } = useTranslation();

  const formSchema = z.object({
    new_password: z.string().min(6, {
      message: t("auth.password_too_short"),
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      new_password: "",
    },
  });

  const { login } = useAuthContext();

  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenParam = queryParams.get("token");
    setToken(tokenParam);
  }, [location.search]);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);

    try {
      const payload = {
        password: values.new_password,
        redirect: getLink(LINK_TYPE.HOME, {}, "DASHBOARD_FULL"),
        token: token,
      };
      const user = await resetPassword(payload);

      await login({ email: user.email, password: payload.password });
      // redirect to login - TODO better if auto login ...
      navigate(getLink(LINK_TYPE.HOME));
      // something that show success -> setElement("reset_password_success");
    } catch (error: any) {
      const errorMessage = t("auth.reset_password_error");
      console.log(error);
      // per comodità setto errore al campo che c'è nel form
      form.setError("new_password", {
        type: "manual",
        message: errorMessage,
      });
    }

    setIsLoading(false);
  }

  return (
    <div className="mx-auto max-w-sm w-full">
      <div className="flex items-center justify-center h-full">
        <ArrowLeft
          className="mr-2 cursor-pointer"
          onClick={() => navigate(getLink(LINK_TYPE.LOGIN))}
        />
        <H2 className="flex-1 text-center">{t("auth.reset_password")}</H2>
      </div>
      <div className="border-b border-gray-300 mt-8 mb-8"></div>
      <div className="mt-5">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <FormField
                  control={form.control}
                  name="new_password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("auth.password")}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("auth.insert_new_password")}
                          type="password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Button type="submit" className="w-full" variant={"takyon"}>
                {String(t("auth.reset"))}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
