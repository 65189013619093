import { Button } from "../ui/button";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { LucideTrash2 } from "lucide-react";
import RoleDropdown from "../form_fields/RoleDropdown";
import { Separator } from "../ui/separator";
import { useModal } from "@/context/ModalContext";
import { InviteMember } from "../form/InviteMember";
import { useTranslation } from "react-i18next";
import {
  GROUP_PERMISSIONS,
  groupRoles,
  iGroup,
  inviteMemberToGroup,
  removeMemberFromGroup,
  setGroupPermissionsPerUser,
} from "@/services/groups";
import { member, role } from "@/services/member";
import Members from "../members/Members";
import { useRbac } from "@/hooks/rbac";

export default function GroupTeam({
  group,
  members,
  onRefresh,
}: {
  group: iGroup;
  members: member<GROUP_PERMISSIONS>[];
  onRefresh(): void;
}) {
  const { can } = useRbac();

  const updateRole = async (email: string, role: role<GROUP_PERMISSIONS>) => {
    try {
      await setGroupPermissionsPerUser({
        groupId: group._id,
        email,
        permissions: role.permissions,
      });
      onRefresh();
    } catch (error) {
      console.log(error);
    }
  };

  const removeMember = async (email: string) => {
    try {
      await removeMemberFromGroup({ groupId: group._id, email });
      onRefresh();
    } catch (error) {
      console.log(error);
    }
  };

  const inviteMember = async (email: string, role: role<GROUP_PERMISSIONS>) => {
    try {
      await inviteMemberToGroup({ groupId: group._id, email });
      await updateRole(email, role);
      // onRefresh();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      <Members
        onInviteMember={inviteMember}
        onRemoveMember={removeMember}
        onUpdateRole={updateRole}
        roles={groupRoles}
        members={members}
        canEdit={can(GROUP_PERMISSIONS.members, group)}
      />
    </div>
  );
}
