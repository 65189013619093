import {
  registerNewHotelBooking,
  updateHotelBooking,
} from "@/services/hotelregistration";
import { FormEvent, useState } from "react";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { Input } from "@/components/ui/input";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { LoadingSubmitButton } from "@/components/form_fields/LoadingSubmitButton";
import { Textarea } from "@/components/ui/textarea";
import { Separator } from "@/components/ui/separator";
import { useFeedback } from "@/context/FeedbackContext";

interface CrudModalProps {
  onSuccess: Function;
  data?: any;
}

enum BOOKING_ENGINES {
  MIRAI_BOOKING = "Mirai",
  SLOPE_BOOKING = "Slope",
  ROIBACK_BOOKING = "Roiback",
  VERTICAL_BOOKING = "VerticalBooking",
  SIMPLE_BOOKING = "SimpleBooking",
  MEWS_BOOKING = "Mews",
}

const formSchema = z.object({
  hotelName: z.string(),
  bookingEngine: z.string(),
  bookingEngineHotelId: z.string(),
  structureToken: z.string().optional(),
  structureUser: z.string().optional(),
  structurePassword: z.string().optional(),
  enable: z.boolean(),
});

const bookingEngine = Object.entries(BOOKING_ENGINES).map(([key, value]) => {
  return {
    value: value,
    text: value,
    key: key,
  };
});

export default function HotelIntegrationForm({
  onSuccess,
  data,
}: CrudModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { showFeedback } = useFeedback();

  const { _id, ...formData } = data || {};
  const isEditMode = Boolean(_id);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { ...formData },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);

    try {
      if (isEditMode) {
        // update
        // console.log("updateHotelBooking", _id, values);
        await updateHotelBooking(_id, values);
      } else {
        // create
        // console.log("registerNewHotelBooking", values);
        await registerNewHotelBooking(values);
      }

      onSuccess();
    } catch (error) {
      // console.log(JSON.stringify(error));
      showFeedback({
        title: t("feedbacks.fails"),
        message: t("feedbacks.error_occurred"),
      });
    }
    setIsLoading(false);
  }

  // if (isLoading) return "Loading...";

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="space-y-4">
          <FormField
            control={form.control}
            name="hotelName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("hotelIntegration.name")} {t("form.required")}
                </FormLabel>
                <FormControl>
                  <Input placeholder="TODO" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="bookingEngine"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("hotelIntegration.bookingEngine")} {t("form.required")}
                </FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t("form.select")} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {bookingEngine.map((s, index) => (
                      <SelectItem key={"item" + index} value={s.value}>
                        {s.text}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="bookingEngineHotelId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("hotelIntegration.bookingEngineHotelId")}{" "}
                  {t("form.required")}
                </FormLabel>
                <FormControl>
                  <Input placeholder="TODO" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Separator />

          <FormField
            control={form.control}
            name="structureToken"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("hotelIntegration.structureToken")} {t("form.required")}
                </FormLabel>
                <FormControl>
                  <Textarea
                    placeholder={t("hotelIntegration.structureToken")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="structureUser"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("hotelIntegration.structureUser")} {t("form.required")}
                </FormLabel>
                <FormControl>
                  <Input placeholder="Inserire l'utente" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="structurePassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("hotelIntegration.structurePassword")} {t("form.required")}
                </FormLabel>
                <FormControl>
                  <Input placeholder="Inserire la password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="enable"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormLabel>{t("hotelIntegration.enable")}</FormLabel>
              </FormItem>
            )}
          />
        </div>

        <div className="flex justify-end mt-2 gap-2">
          <Button onClick={() => console.log("close modal")}>
            {t("buttons.cancel")}
          </Button>
          <LoadingSubmitButton
            variant={"takyon"}
            type="submit"
            isLoading={isLoading}
          >{`${t("buttons.save")}`}</LoadingSubmitButton>
        </div>
      </form>
    </Form>
  );
}
