import { iCollection } from "@/services/collection";
import {
  iBid,
  iNft,
  NFT_OWNER_TYPE,
  putNftForSalePending,
} from "@/services/nft";
import { datetimeToString } from "@/utils/generic";
import { ColumnDef } from "@tanstack/react-table";

import { TFunction } from "i18next";
import helpers from "@/components/helpers/helpers";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { Button } from "@/components/ui/button";
import { LucideEye, MoreVertical } from "lucide-react";
import DetailHotel from "@/components/collection/DetailHotel";
import CopyId from "@/components/helpers/CopyId";
import { useModal } from "@/context/ModalContext";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { DataTableColumnHeader } from "@/components/datatable/data-table-column-header";
import { LoadingSubmitButton } from "@/components/form_fields/LoadingSubmitButton";

export const getColumns = (
  collection: iCollection,
  t: TFunction
): ColumnDef<iNft>[] => {
  return [
    {
      header: "#",
      accessorFn: (nft) => nft?.shortId,
    },
    {
      id: "creationDate",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("nft.taks_creation_date")}
        />
      ),
      accessorFn: (nft) => datetimeToString(nft._createdAt, undefined, true),
    },
    {
      id: "reference",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("nft.taks_reference")}
        />
      ),
      accessorKey: "payload.reference",
    },
    // {
    //   header: t("nft.taks_collection"),
    //   accessorKey: "_collectionCensus.name",
    // },
    {
      id: "owner",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("nft.taks_owner")} />
      ),
      accessorFn: (nft) => {
        const ownerType = nft.ownerType;
        const owner = nft.owner;
        return `<a href="mailto:${owner}" class="block w-56 text-ellipsis overflow-hidden">${
          ownerType === NFT_OWNER_TYPE.RESOLD ? "🟡" : "&nbsp &nbsp &nbsp"
        } ${owner}</a>`;
      },
      cell: ({ getValue }) => (
        <span dangerouslySetInnerHTML={{ __html: getValue() as string }} />
      ),
    },
    {
      header: t("nft.taks_pruchase_price"),
      accessorFn: (nft) => `${Number(nft.originalPrice).toFixed(2)}€`,
    },
    {
      header: t("nft.taks_resell_price"),
      accessorFn: (nft) =>
        nft.currentPrice ? `${Number(nft.currentPrice).toFixed(2)}€` : null,
    },
    {
      id: "actions",
      accessorKey: "id",
      header: t("nft.taks_actions"),
      cell: function render({ getValue, row }) {
        const { showModal, hideModal } = useModal();

        return (
          <div className="flex flex-row flex-nowrap gap-0">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                showModal(
                  <DetailHotel
                    nft={row.original}
                    collectionCensus={row.original._collectionCensus}
                  />,
                  {
                    title: (
                      <>
                        {t("nft.modal_detail_title")}{" "}
                        <CopyId resourceId={row.original._id!} />
                      </>
                    ),
                    description: null,
                  }
                );
              }}
            >
              <LucideEye size={16} />
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button aria-haspopup="true" size="icon" variant="ghost">
                  <MoreVertical className="h-4 w-4" />
                  <span className="sr-only">Toggle menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={() => {
                    showModal(
                      <NftSaleCheckModal
                        onSuccess={() => {
                          // TODO find a way to refresh the data.
                          window.location.reload();
                        }}
                        nft={row.original}
                      />,
                      {
                        title: t("nft.nft_check_put_for_sale_modal_title"),
                        description: t(
                          "nft.nft_check_put_for_sale_modal_description"
                        ),
                      }
                    );
                  }}
                >
                  {t("collection.nft_check_put_for_sale")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];
};

function NftSaleCheckModal({
  nft,
  onSuccess,
}: {
  nft: iNft;
  onSuccess(): void;
}) {
  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();

  const [isLoading, setIsLoading] = useState(false);

  const confirm = async () => {
    setIsLoading(true);
    try {
      await putNftForSalePending(nft._id!);

      hideModal();
      onSuccess();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex justify-end">
      <LoadingSubmitButton
        isLoading={isLoading}
        variant={"takyon"}
        onClick={confirm}
      >
        {" "}
        {t("collection.nft_check_put_for_sale_btn")}
      </LoadingSubmitButton>
    </div>
  );
}
