import { Search } from "lucide-react";
import { Input } from "@/components/ui/input";
import React from "react";
import { cn } from "@/lib/utils";

interface ISearchBoxProps {
  initialValue?: string;
  placeholder: string;
  onSearch: (value: string) => void;
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string;
  onChange: (value: string) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <>
      <Input
        {...props}
        type="search"
        placeholder={props.placeholder}
        className={cn("h-9 w-[150px] lg:w-[350px]", props.className)}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </>
  );
}

export default function SearchBox(props: ISearchBoxProps) {
  return (
    <div className="relative">
      <Search className="absolute left-2.5 top-2   h-4 w-4 text-muted-foreground" />
      <DebouncedInput
        type="text"
        value={(props.initialValue ?? "") as string}
        onChange={props.onSearch}
        placeholder={props.placeholder}
        className="pl-8 w-36"
      />
    </div>
  );
}
