// src/context/AuthContext.tsx

import { fireJuneEvent } from "@/components/analytics/JuneAnalytics";
import { Skeleton } from "@/components/ui/skeleton";
import getLink, { getHomeLink, LINK_TYPE } from "@/lib/links";
import {
  isLoggedIn,
  login as authLogin,
  register as authRegister,
  logout as authLogout,
  sendVerificationEmailByCode as authSendVerificationEmail,
  verifyEmailByCode as authVerifyEmail,
  authByToken,
} from "@/services/auth";
import { setUser } from "@/store/userSlice";
import React, { createContext, useContext, useState, ReactNode } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";

interface AuthContextProps {
  isAuthenticated: boolean | null;
  userId: string | null;
  userEmail: string | null;
  isVerified: boolean | null;
  login: (values: any) => void;
  logout: () => void;
  initializeLoggedUser: () => void;
  register: (values: any) => void;
  verifyEmail: (values: any) => void;
  sendVerificationEmail: (values: any) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const loggedIn = isLoggedIn();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(
    loggedIn
  );
  const [userId, setUserId] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [isVerified, setIsVerified] = useState<boolean | null>(null);
  const navigate = useNavigate();
  const store = useStore();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const prepareLoggedUser = async () => {
      if (loggedIn) {
        await initializeLoggedUser();
        // console.log("AuthContext - initializeLoggedUser finish...", Date.now());
      }
    };

    prepareLoggedUser();
  }, []);

  const loadAndStoreUserData = async (userData: any) => {
    dispatch(setUser(userData));
  };

  const login = async (values: any) => {
    const userData = await authLogin(values);
    await loadAndStoreUserData(userData);
    // console.log("login userData", userData);
    setIsAuthenticated(true);
    setIsVerified(userData.emailVerified);
    setUserEmail(userData.email);

    // Track login success event
    fireJuneEvent("login_success", {
      userId: userData._id,
      email: userData.email,
      isAdmin: userData.isAdmin,
    });

    navigate(getHomeLink(userData.isAdmin));
  };

  const register = async (values: any) => {
    await authRegister({ ...values, b2bRegistration: true });
    await login(values);
    setIsAuthenticated(true);
    setIsVerified(false);
  };

  const initializeLoggedUser = async () => {
    try {
      const userData = await authByToken();
      await loadAndStoreUserData(userData);
      setIsAuthenticated(true);
      setIsVerified(userData.emailVerified);
      setUserId(userData._id);
      setUserEmail(userData.email);
    } catch (error) {
      console.error("initializeLoggedUser error", error);
      // logout();
    }
  };

  const logout = async () => {
    await authLogout();
    dispatch(setUser(undefined));
    setIsAuthenticated(false);
    setIsVerified(null);
    navigate(getLink(LINK_TYPE.LOGIN));
  };

  const verifyEmail = async (values: any) => {
    await authVerifyEmail(values);
    await initializeLoggedUser();
  };

  const sendVerificationEmail = async (values: any) => {
    await authSendVerificationEmail(values);
  };

  if (isAuthenticated == null)
    return (
      <div>
        <span>
          <Skeleton className="bg-gray-200 h-fit w-fit rounded-lg" />
        </span>
      </div>
    );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userId,
        userEmail,
        isVerified,
        login,
        register,
        logout,
        initializeLoggedUser,
        verifyEmail,
        sendVerificationEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};
