import axios from "axios";
import { Pagination } from "../utils/Pagination";

export interface iFaq {
  _faqId?: string;
  question: string;
  answer: string;
  language: string;
}

export interface iSupportFaq {
  _id: string;
  name: string;
  icons: string[];
  lang: string;

  faqs: iFaq[];
}

export async function createCategory(payload?: any) {
  const response = await axios.post("/supportfaqs", payload);
  return response.data;
}

export async function deleteCategory(payload: any) {
  const response = await axios.delete("/supportfaqs", { data: payload });
  return response.data;
}

export async function getCategoriesPaginated({
  page = 1,
  size = 25,
}): Promise<Pagination<any>> {
  const url = "/supportfaqs/categories";

  const response = await axios.get(url, {
    params: {
      page,
      size,
    },
  });
  return response.data;
}

export async function createFaqInCategory(payload?: any) {
  const response = await axios.post("/supportfaqs/create-faq", payload);
  return response.data;
}

export async function editFaqInCategory(payload: any) {
  const response = await axios.post("/supportfaqs/edit-faq", payload);
  return response.data;
}

export async function deleteFaqInCategory(payload: any) {
  const response = await axios.delete("/supportfaqs/delete-faq", {
    data: payload,
  });
  return response.data;
}

export async function getFaqsInCategory(categoryId: string) {
  const response = await axios.get(`/supportfaqs/faqs?_id=${categoryId}`);
  return response.data;
}
