import {
  deleteCollectionAlias,
  editCollectionAlias,
  iCollectionAlias,
} from "@/services/alias";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { LoadingSubmitButton } from "@/components/form_fields/LoadingSubmitButton";

export default function FormDeveloperAlias({
  collectionId,
  alias,
  onSuccess,
}: {
  collectionId: string;
  alias?: iCollectionAlias;
  onSuccess?: () => void;
}) {
  const formSchema = z.object({
    collectionId: z.string(),
    alias: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      collectionId: collectionId,
      alias: alias?.alias,
    },
  });

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const onDelete = async () => {
    if (alias) {
      await deleteCollectionAlias({ aliasId: alias._id });
      if (onSuccess) onSuccess();
    }
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);

    try {
      // const data = formToObject(e.target);
      if (data.alias) await editCollectionAlias(data);

      if (onSuccess) onSuccess();

      // if (alias && !data.alias) {
      //   await deleteCollectionAlias({ aliasId: alias?._id });
      // }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("FormDeveloperAlias", form.getValues());

  return (
    <section id="collection-alias-modal">
      {/* <span className="title">
        {t("dev.collection_alias_modal_text")} {collection.name}
      </span> */}
      <Form {...form}>
        <form
          className="flex flex-col gap-4"
          // onSubmit={(e) => editCollectionAliasSubmit(e, alias?._id)}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <FormField
            control={form.control}
            name="alias"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("dev.collection_alias")}</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Alias"
                    value={alias?.alias}
                    // value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      if (!alias) {
                        alias = {
                          _id: "",
                          userId: "",
                          collectionId: "",
                          alias: "",
                        };
                      }
                      alias.alias = e.target.value;
                    }}
                    // {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="collectionId"
            render={({ field }) => (
              <FormItem>
                {/* <FormLabel>{t("reports.collection_id")}</FormLabel> */}
                <FormControl>
                  <Input type="hidden" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-between flex-row-reverse gap-4">
            <LoadingSubmitButton
              type="submit"
              isLoading={isLoading}
              variant="takyon"
            >
              {t("buttons.save")}
            </LoadingSubmitButton>

            <Button
              className="self-start "
              variant="destructive"
              type="button"
              onClick={onDelete}
            >
              {t("dev.delete_alias")}
            </Button>
          </div>
        </form>
      </Form>
    </section>
  );
}
