import { useTranslation } from "react-i18next";
import { Separator } from "../ui/separator";
import { GroupSettingsForm } from "../form/GroupSettingsForm";
import { Button } from "../ui/button";
import { LucideEllipsisVertical } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  deleteGroup,
  GROUP_PERMISSIONS,
  iGroup,
  leaveGroup,
  transferGroup,
  verifyGroup,
} from "@/services/groups";
import { useModal } from "@/context/ModalContext";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";

import { useForm } from "react-hook-form";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { H3 } from "../typography/Typography";
import { useRbac } from "@/hooks/rbac";
import { GroupSettingsFormAdmin } from "../form/GroupSettingsFormAdmin";

const transferSchema = z.object({
  email: z.string().email(),
});

function TransferOwnershipModal({
  group,
  onSuccess,
}: {
  group: iGroup;
  onSuccess(): void;
}) {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof transferSchema>>({
    resolver: zodResolver(transferSchema),
  });

  const { hideModal } = useModal();

  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(values: z.infer<typeof transferSchema>) {
    setIsLoading(true);
    try {
      await transferGroup({
        groupId: group._id,
        email: values.email,
      });
      hideModal();
      onSuccess();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid gap-4">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("group.new_owner_email")}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("group.new_owner_email_placeholder")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button disabled={isLoading} type="submit">
            {t("group.transfer_ownership")}
          </Button>
        </div>
      </form>
    </Form>
  );
}

function LeaveOrganizationModal({
  group,
  onSuccess,
}: {
  group: iGroup;
  onSuccess(): void;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { hideModal } = useModal();

  const submit = async () => {
    setIsLoading(true);
    try {
      await leaveGroup({ groupId: group._id });
      onSuccess();
      hideModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex justify-end">
      <Button disabled={isLoading} onClick={submit} variant={"destructive"}>
        {t("group.leave_confirm")}
      </Button>
    </div>
  );
}

function DeleteGroupModal({
  group,
  onSuccess,
}: {
  group: iGroup;
  onSuccess(): void;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { hideModal } = useModal();

  const submit = async () => {
    setIsLoading(true);
    try {
      await deleteGroup({ groupId: group._id });
      hideModal();
      onSuccess();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex justify-end">
      <Button disabled={isLoading} onClick={submit} variant={"destructive"}>
        {t("group.delete_confirm")}
      </Button>
    </div>
  );
}

export default function GroupSettings({
  group,
  onRefresh,
}: {
  group: iGroup;
  onRefresh(): void;
}) {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const { isSuperAdmin, can } = useRbac();

  const { showModal } = useModal();

  const isOwnerOfGroup = () => {
    return can(GROUP_PERMISSIONS.admin, group);
  };

  return (
    <div className="">
      <div className="flex items-center">
        <H3>{t("group.settings")}</H3>
        <div className="ml-auto">
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button variant="ghost">
                <LucideEllipsisVertical size={16} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                disabled={isOwnerOfGroup()}
                className="cursor-pointer"
                onSelect={() =>
                  showModal(
                    <LeaveOrganizationModal
                      group={group}
                      onSuccess={onRefresh}
                    />,
                    {
                      title: t("group.leave_title"),
                      description: t("group.leave_text"),
                    }
                  )
                }
              >
                {t("group.leave")}
              </DropdownMenuItem>

              {isOwnerOfGroup() && (
                <DropdownMenuItem
                  className="cursor-pointer"
                  onSelect={() =>
                    showModal(
                      <TransferOwnershipModal
                        group={group}
                        onSuccess={onRefresh}
                      />,
                      {
                        title: t("group.transfer_ownership"),
                        description: t("group.transfer_ownership_text"),
                      }
                    )
                  }
                >
                  {t("group.transfer_ownership")}
                </DropdownMenuItem>
              )}

              {(user?.isAdmin || isOwnerOfGroup()) && (
                <DropdownMenuItem
                  className="cursor-pointer"
                  onSelect={() =>
                    showModal(
                      <DeleteGroupModal group={group} onSuccess={onRefresh} />,
                      {
                        title: t("group.delete_modal_title"),
                        description: t("group.delete_modal_text"),
                      }
                    )
                  }
                >
                  {t("group.delete")}
                </DropdownMenuItem>
              )}

              {user?.isAdmin && !group.verified && (
                <DropdownMenuItem
                  className="cursor-pointer"
                  onSelect={() =>
                    verifyGroup({ groupId: group._id }).then(onRefresh)
                  }
                >
                  {t("group.verify")}
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <Separator className="my-4" />
      {isSuperAdmin ? (
        <GroupSettingsFormAdmin onSuccess={onRefresh} group={group} />
      ) : (
        <GroupSettingsForm onSuccess={onRefresh} group={group} />
      )}
    </div>
  );
}
