import { Copy } from "lucide-react";
import { Button } from "../ui/button";

export default function CopyId({ resourceId }: { resourceId: string }) {
  return (
    <Button
      variant="ghost"
      size="icon"
      onClick={() => {
        navigator.clipboard.writeText(resourceId).then(
          () => {
            console.log("Copied to clipboard successfully!");
          },
          (err) => {
            console.error("Could not copy text: ", err);
          }
        );
      }}
      title={resourceId}
    >
      <Copy size={12} />
    </Button>
  );
}
