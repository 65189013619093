import { useTranslation } from "react-i18next";
import { LargeText } from "../typography/Typography";

export default function KbdSearch() {
  const { t } = useTranslation();
  // Detect if the user is on Windows
  const isWindows = navigator.userAgent.includes("Windows");

  // Set the modifier key text based on the OS
  const modifierKey = isWindows ? "Ctrl" : "⌘";

  return (
    <div className="text-sm text-muted-foreground flex flex-col justify-center items-center flex-1 space-y-3">
      <LargeText>{t("admin.dashboard_start_text")}</LargeText>
      <div>
        Press{" "}
        <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
          <span className="text-xs">{modifierKey}</span>S
        </kbd>
      </div>
    </div>
  );
}
